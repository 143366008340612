import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppConstants } from '../../../../SGRE-shared/constants/app-constant';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { HomepageService } from '../../services/homepage.service';
import { Store } from '@ngrx/store';
import * as StorageActions from '../../../../SGRE-shared/services/storage.actions';

@Component({
  selector: 'app-homepage-category-section3',
  templateUrl: './homepage-category-section3.component.html',
  styleUrl: './homepage-category-section3.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomepageCategorySection3Component {

  public categoryList$: Observable<any>;
  public imageUrl: any = environment.siteUrls.getBackofficeUrl.slice(0, -1);
  plpUrl = AppConstants.routeUrls.plp;
  categoriesName: string = ':name-asc:allCategories:';

  constructor(
    private homepageService: HomepageService,
    private router: Router,
    private storageService: StorageService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.getCategoriesList();
  }

  public getCategoriesList() {
    this.homepageService.getCategoriesAPI();
    this.categoryList$ = this.homepageService.dataCat$;
  }

  getImageUrl(imgArr: any[]) {
    return imgArr && imgArr.length > 0 && environment.siteUrls.getBackofficeUrl + imgArr[0].url;
  }

  categoryClick(categoryName?: string) {
    if (categoryName?.length > 0) {
      let str = this.categoriesName + `"${categoryName}"`;
      this.store.dispatch(StorageActions.setQuery({ query: str }));
    } else {
      this.store.dispatch(StorageActions.setQuery({ query: '' }));
      this.storageService.resetQuery();
    }
    this.router.navigate([AppConstants.routeUrls.plp]);
  }
}
