import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { BulkOrderService } from '../../services/bulk-order-service';
import { Observable } from 'rxjs';
import { CmsComponentData } from '@spartacus/storefront';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { Store,select } from '@ngrx/store';
import { selectUserRoles } from '../../../../SGRE-shared/services/storage.state';
@Component({
  selector: 'app-view-cart-name',
  templateUrl: './view-cart-name.component.html',
  styleUrl: './view-cart-name.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewCartNameComponent {
  currentpage$: Observable<number>;
  public componentData: any;
  public form: FormGroup;
  public userRoles: any[];
  role: string;
  submitted: boolean = false;
  foundProductLength: boolean;
  constructor(
    public component: CmsComponentData<any>,
    public bulkservice: BulkOrderService,
    public storageService: StorageService,
    public changeRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private globalService: GlobalService,
    private store: Store
  ) {}
  ngOnInit(): void {
    this.currentpage$ = this.bulkservice.isNext$;
    this.component.data$.subscribe((data) => {
      this.componentData = data;
    });
    this.form = this.fb.group({
      cartName: new FormControl('', [Validators.required]),
    });
    this.form.get('cartName').valueChanges.subscribe((cartName) => {
      this.bulkservice.cartNameSubject.next(cartName);
    });
    this.bulkservice.error$.subscribe((value) => {
      if (value) {
        this.submitted = value;
        this.changeRef.markForCheck();
      }
    });
    this.getUserRole();
    this.bulkservice.tableDataSubject.subscribe((req) => {
      const data = req;
      if (Object.keys(data).length) {
        this.foundProductLength = data?.foundProductCodes?.length != 0 ? true : false;
      }
      this.changeRef.markForCheck();
    });
  }
  getUserRole() {
    this.store.pipe(select(selectUserRoles)).subscribe((userRoles) => {
      this.userRoles = userRoles;
    });
    let userRole: any = '';
    userRole = this.userRoles;

    if (userRole?.includes('Requester') && !userRole?.includes('Purchaser')) {
      this.role = 'Requester';
    } else if (
      userRole?.includes('Purchaser') &&
      !userRole?.includes('Requester')
    ) {
      this.role = 'Purchaser';
    } else if (
      userRole?.includes('Purchaser') &&
      userRole?.includes('Requester')
    ) {
      this.role = 'Purchaser&Requester';
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  
  ngOnDestroy()
  {
    this.bulkservice.cartNameSubject.next('');
    this.globalService.clearMessagesOnDestroy();
  }
}
