<ng-container *ngIf="currentpage$ | async as page">
  <div *ngIf="page === 3 && foundProductLength" class="container-fluid m-1">
    <div class="c-head">
      <h3>{{"bulkOrder.productsFound"|cxTranslate}}</h3>
    </div>
    <form class="form-group" [formGroup]="form">
      <label for="new-cart">
        {{"bulkOrder.cartName"|cxTranslate}}
        <span class="validationErrorText">*</span>
      </label>
      <input type="text" formControlName="cartName" name="new-cart" class="form-control" style="width: 50%"
        [ngClass]="{ 'is-invalid': submitted && f['cartName'].errors }" />
    </form>
  </div>
</ng-container>