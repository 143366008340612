import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface StorageState {
  msalInfo: any;
  accessToken: any;
  redirectUrl: any;
  currency: any;
  userLegalEntities: any;
  defaultLegalEntity: any;
  userRoles: any;
  dateFormat: any;
  selectedLanguage: any;
  query: any;
  cartId: any;
  carts: any;
  activeCart: any;
  paramsCartGroupId: any;
  checkoutCartGroupId: any;
  cartCode: any;
  secondaryCartCode: any;
  userProfileData: any;
  cartSelectedData: any;
}

export const initialState: StorageState = {
  msalInfo: null,
  accessToken: null,
  redirectUrl: null,
  currency: null,
  userLegalEntities: null,
  defaultLegalEntity: null,
  userRoles: null,
  dateFormat: null,
  selectedLanguage: null,
  query: null,
  cartId: null,
  carts: null,
  activeCart: null,
  paramsCartGroupId: null,
  checkoutCartGroupId: null,
  cartCode: null,
  secondaryCartCode: null,
  userProfileData: null,
  cartSelectedData: null,
};

export const selectStorageState = createFeatureSelector<StorageState>('storage');

export const selectMsalInfo = createSelector(selectStorageState, (state: StorageState) => state.msalInfo);
export const selectAccessToken = createSelector(selectStorageState, (state: StorageState) => state.accessToken);
export const selectRedirectUrl = createSelector(selectStorageState, (state: StorageState) => state.redirectUrl);
export const selectCurrency = createSelector(selectStorageState, (state: StorageState) => state.currency);
export const selectUserLegalEntities = createSelector(selectStorageState, (state: StorageState) => state.userLegalEntities);
export const selectDefaultLegalEntity = createSelector(selectStorageState, (state: StorageState) => state.defaultLegalEntity);
export const selectUserRoles = createSelector(selectStorageState, (state: StorageState) => state.userRoles);
export const selectDateFormat = createSelector(selectStorageState, (state: StorageState) => state.dateFormat);
export const selectSelectedLanguage = createSelector(selectStorageState, (state: StorageState) => state.selectedLanguage);
export const selectQuery = createSelector(selectStorageState, (state: StorageState) => state.query);
export const selectCartId = createSelector(selectStorageState, (state: StorageState) => state.cartId);
export const selectCarts = createSelector(selectStorageState, (state: StorageState) => state.carts);
export const selectActiveCart = createSelector(selectStorageState, (state: StorageState) => state.activeCart);
export const selectParamsCartGroupId = createSelector(selectStorageState, (state: StorageState) => state.paramsCartGroupId);
export const selectCheckoutCartGroupId = createSelector(selectStorageState, (state: StorageState) => state.checkoutCartGroupId);
export const selectCartCode = createSelector(selectStorageState, (state: StorageState) => state.cartCode);
export const selectSecondaryCartCode = createSelector(selectStorageState, (state: StorageState) => state.secondaryCartCode);
export const selectUserProfileData = createSelector(selectStorageState, (state: StorageState) => state.userProfileData);
export const selectCartUserData = createSelector(selectStorageState, (state: StorageState) => state.cartSelectedData);

