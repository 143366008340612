import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SGRESharedModule } from '../../SGRE-shared/SGRE-shared.module';
import { ConfigModule } from '@spartacus/core';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SGRESharedModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CMSTabParagraphComponent: {
          component: () => import('./Components/privacy-policy-container1/privacy-policy-container1.component').then(m => m.PrivacyPolicyContainer1Component),
        },
        PrivacyPolicySection3Component: {
          component: () => import('./Components/privacy-policy-buttons-section3/privacy-policy-buttons-section3.component').then(m => m.PrivacyPolicyButtonsSection3Component),
        },
      }
    })
  ],
})
export class PrivacyPolicyModule { }
