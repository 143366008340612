import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { BulkOrderService } from '../../services/bulk-order-service';
import {
  Column,
  ColumnType,
} from '../../../../SGRE-shared/models/tableConfiguration';
import { environment } from '../../../../../environments/environment';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { Router } from '@angular/router';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { Store,select } from '@ngrx/store';
import { selectUserRoles } from '../../../../SGRE-shared/services/storage.state';
@Component({
  selector: 'app-view-product-table',
  templateUrl: './view-product-table.component.html',
  styleUrl: './view-product-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewProductTableComponent implements OnInit {
  albums: any = [];
  public columns: Column[];
  public columnType: any[];
  public cartsList: any[] = [];
  cols: cols[] = [];
  schema: AlbumSchema[] = [];
  bulkDetails$: Observable<any>;
  productcode: string;
  productdesc: string;
  currentpage$: Observable<number>;
  public imageUrl: any = environment.siteUrls.getBackofficeUrl.slice(0, -1);
  public userRoles: any[];
  role: string;
  qtyUpdatedFlag: boolean;
  private unsubscribe$ = new Subject<void>();
  
  constructor(
    public bulkservice: BulkOrderService,
    public changeRef: ChangeDetectorRef,
    public storageService: StorageService,
    public globalService: GlobalService,
    private router: Router,
    private store: Store
  ) { }

  ngOnInit() {
    this.getData();
    this.currentpage$ = this.bulkservice.isNext$;
    this.createTableConfiguration();
    this.getUserRole();
  }

  getUserRole() {
    this.store.pipe(select(selectUserRoles)).subscribe((userRoles) => {
      this.userRoles = userRoles;
    });
    let userRole: any = '';
    userRole = this.userRoles;

    if (userRole?.includes('Requester') && !userRole?.includes('Purchaser')) {
      this.role = 'Requester';
    } else if (
      userRole?.includes('Purchaser') &&
      !userRole?.includes('Requester')
    ) {
      this.role = 'Purchaser';
    } else if (
      userRole?.includes('Purchaser') &&
      userRole?.includes('Requester')
    ) {
      this.role = 'Purchaser&Requester';
    }
  }

  createTableConfiguration() {
    let columnData = [
      { label: 'Image', name: 'imageUrl', type: ColumnType.image },
      { label: 'Product Name', name: 'productName', type: ColumnType.link2 },
      { label: 'Part Number', name: 'partNumber', type: ColumnType.link2 },
      {
        label: 'Refurbished ',
        name: 'refurbished',
        type: ColumnType.checkbox2,
      },
      { label: 'Quantity ', name: 'quantity', type: ColumnType.quantity },
    ];
    this.columns = columnData.map((item) => new Column(item));
  }

  updateCheckBtnClick(data) {
    this.bulkservice.isPartNumber.next(data);
    for (let i = 0; i < this.cartsList.length; i++) {
      if (data.index === i) {
        this.cartsList[i].refurbishedUpdate = !this.cartsList[i].refurbishedUpdate;
      }
    }
  }

  getData() {
    this.bulkservice.tableDataSubject
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((req) => {
        const data = req;

        if (Object.keys(data).length > 0) {
          // Remove duplicates from foundProductCodes efficiently using Set
          const foundProductCodes = new Set(
            (data?.foundProductCodes?.length >= 25
              ? data.foundProductCodes.slice(0, 25)
              : data.foundProductCodes
            ).map((item) => item.code)
          );

          // Create cartsList with unique entries based on partNumber
          this.cartsList = [];
          for (const code of foundProductCodes) {
            let quantity = 0;
            for (const row of this.bulkservice.excelDataSubject.getValue()) {
              if (code === row.partNumber) {
                quantity = row.quantity;
                // break;
              }
            }

            this.cartsList.push({
              imageUrl: data?.foundProductCodes.find((item) => item.code === code)
                ?.images?.length
                ? this.imageUrl +
                data?.foundProductCodes.find((item) => item.code === code)
                  ?.images[0]?.url
                : '../../../../assets/images_and_icons/image1.jpg',
              partNumber: code,
              productName: data.foundProductCodes.find(
                (item) => item.code === code
              ).name,
              refurbished: data.foundProductCodes
                .find((item) => item.code === code)
                .variantOptions.some((option) => {
                  return option?.refurbished === true;
                })
                ? false
                : true,
              quantity: Number(
                this.checkQuantity(
                  quantity,
                  data.foundProductCodes.find((item) => item.code === code)?.minQuantity ? data.foundProductCodes.find((item) => item.code === code)?.minQuantity : data.foundProductCodes.find((item) => item.code === code)?.interval ? data.foundProductCodes.find((item) => item.code === code)?.interval : 1,
                  data.foundProductCodes.find((item) => item.code === code)?.variantOptions[0]?.maxQuantity ? data.foundProductCodes.find((item) => item.code === code)?.variantOptions[0]?.maxQuantity : 1000,
                  data.foundProductCodes.find((item) => item.code === code)?.interval ? data.foundProductCodes.find((item) => item.code === code)?.interval : 1
                )
              ),
              qtyUpdatedFlag: this.qtyUpdatedFlag,
              url: data.foundProductCodes.find((item) => item.code === code).url,
              navigateUrl: data.foundProductCodes.find((item) => item.code === code).url,
              isObsolete: data.foundProductCodes.find(
                (item) => item.code === code
              ).variantOptions[0].obsoleteFlag,
              step: Number(
                data.foundProductCodes.find((item) => item.code === code)
                  .interval > 0
                  ? data.foundProductCodes.find((item) => item.code === code)
                    .interval
                  : 1
              ),
              maxQuantity: Number(data.foundProductCodes.find((item) => item.code === code)?.variantOptions[0]?.maxQuantity ? data.foundProductCodes.find((item) => item.code === code)?.variantOptions[0]?.maxQuantity : 1000),
              minQuantity: this.getUpdatedMinQty(data.foundProductCodes.find((item) => item.code === code)?.minQuantity, data.foundProductCodes.find((item) => item.code === code).interval),
              refurbishedUpdate: Boolean(false)
            });
          }
          this.changeRef.markForCheck();
        }
        this.bulkservice.tableDataSubjectUpdate.next(this.cartsList);

      });
  }

  getUpdatedMinQty(minQuantity, interval) {
    return this.globalService.getUpdatedMinQty(minQuantity, interval);
  }

  checkQuantity(quantity, minQuantity?, maxQuantity?, interval?) {
    this.qtyUpdatedFlag = false;
    if (quantity < minQuantity) {
      this.qtyUpdatedFlag = true;
      return minQuantity
    } else if (quantity > maxQuantity) {
      this.qtyUpdatedFlag = true;
      return maxQuantity;
    } else if (quantity < interval) {
      this.qtyUpdatedFlag = true;
      return interval;
    } else if (quantity % interval != 0) {
      this.qtyUpdatedFlag = true;
      return Math.round(quantity / interval) * interval;
    }
    else {
      return quantity;
    }
  }

  public updateQty(data) {
    let entryNumber = data.index;
    let qty = data.formArray[entryNumber]?.quantity;
    this.cartsList[entryNumber].quantity = +qty;
    let arrayvalue = this.bulkservice.excelDataSubject.getValue();
    arrayvalue.map((item) => {
      if (item.partNumber === data.formArray[entryNumber].partNumber) {
        item.quantity = qty;
      }
    });
    this.bulkservice.excelDataSubject.next(arrayvalue);
  }

  navigate(data) {
    this.router.navigateByUrl(data);
    this.bulkservice.countSubject.next(true);
  }
  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }
}
export interface Element {
  username: string;
  name: string;
  Company: number;
  legal_entities: string;
}
export interface AlbumSchema {
  field: string;
  header: string;
}
export interface cols {
  field: string;
  header: string;
}
