import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { BulkOrderService } from '../../../../SGRE-custom/bulk-order-page/services/bulk-order-service';
import { StorageService } from '../../../services/storage.service';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsParagraphComponent } from '@spartacus/core';
import { selectUserRoles } from '../../../../SGRE-shared/services/storage.state';
import { Store,select } from '@ngrx/store';
@Component({
  selector: 'app-bulkorder-upload-type-paragraph',
  templateUrl: './bulkorder-upload-type-paragraph.component.html',
  styleUrl: './bulkorder-upload-type-paragraph.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkorderUploadTypeParagraphComponent {
  currentpage$ :Observable<number>
  public userRoles : any[];
  @Input() data: Observable<CmsParagraphComponent>;
  role: string;
  public componentData: any;
  constructor(public bulkservice: BulkOrderService,public component: CmsComponentData<any>,public storageService : StorageService, private store:Store) { }
  ngOnInit(): void {
    this.currentpage$=this.bulkservice.isNext$;
    this.getUserRole();
    this.component.data$.subscribe((data) => {
      this.componentData = data;
    });
  }
  getUserRole(){
    this.store.pipe(select(selectUserRoles)).subscribe((userRoles) => {
      this.userRoles = userRoles;
    });
    let userRole:any =''
        userRole =  this.userRoles;
        if(userRole?.includes("Requester") && !userRole?.includes("Purchaser")){
          this.role= 'Requester';
        }
        else if(userRole?.includes("Purchaser") && !userRole?.includes("Requester")){
           this.role='Purchaser';
        }
        else if(userRole?.includes("Purchaser") && userRole?.includes("Requester")){
          this.role='Purchaser&Requester'
       }
       
  
  }
}
