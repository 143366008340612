import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LOCALE_ID } from '@angular/core';
import { Observable, takeUntil } from 'rxjs';
import { OrderDetailsService } from '../../services/orderDetails.service';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';

@Component({
  selector: 'app-order-total-summary',
  templateUrl: './order-total-summary.component.html',
  styleUrl: './order-total-summary.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'de-DE', // 'de-DE' for Germany, 'fr-FR' for France ...
    },
  ],
})
export class OrderTotalSummaryComponent implements OnInit {
  public ordersummary: Observable<any>;
  public isQuotation: boolean;
  orderFlag: boolean;

  constructor(
    private orderService: OrderDetailsService,
    private globalService: GlobalService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.globalService.loadingSubject.next(true); 
    this.ordersummary = this.orderService.OrderListData$;
    this.orderService.isQuotation$.subscribe((value) => {
      this.isQuotation = value;
    });
    this.orderFlag = false;
  }
  get isError() {
    return this.orderService.orderErrorFlag;
  }

  findSymbol(currency) {
    let currencies = ['EUR', 'DKK', 'USD', 'GBP', 'CAD'];
    let symbols = ['€', 'DKK', '$', 'GBP', 'CAD'];
    return symbols.at(currencies.indexOf(currency));
  }

  getFormattedValue(val) {
    return this.globalService.getFormattedPrice(val);
  }
}
