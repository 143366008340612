import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  isNotUndefined,
  Product,
  ProductService,
  RoutingService,
} from '@spartacus/core';
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { AppConstants } from '../../../SGRE-shared/constants/app-constant';
import { Options, ProductInfo, ProductScope, ProductVariant } from '../models/product-info';
import { environment } from '../../../../environments/environment';
import { GlobalService } from '../../../SGRE-shared/services/global.service';
import { ApiService } from '../../../SGRE-shared/services/api.service';
import { CurrentProductService } from '@spartacus/storefront';
@Injectable({
  providedIn: 'root'
})
export class ProductDetailsService {

  protected readonly DEFAULT_PRODUCT_SCOPE = ProductScope.DETAILS;
  protected readonly FULL_PRODUCT_SCOPE = 'FULL';
  productData: ProductInfo;
  private variantSubject = new BehaviorSubject<Options>(null);
  variant$ = this.variantSubject.asObservable();
  private productCodeSubject = new BehaviorSubject<string>(null);
  pCode$ = this.productCodeSubject.asObservable();

  constructor(
    private productService: ProductService,
    private routingService: RoutingService,
    private globalService: GlobalService,
    private apiService: ApiService,
    private currentProductService: CurrentProductService
  ) { }

  // getProduct(
  //   scopes?: (ProductScope | string)[] | ProductScope | string
  // ): Observable<Product | null> {
  //   return this.getCode().pipe(
  //     distinctUntilChanged(),
  //     switchMap((productCode: string) => {
  //       return productCode
  //         ? this.productService.get(
  //           productCode,
  //           scopes || this.FULL_PRODUCT_SCOPE || this.DEFAULT_PRODUCT_SCOPE
  //         )
  //         : of(null);
  //     }),
  //     filter(isNotUndefined),
  //     tap(() => this.globalService.clearMessagesOnDestroy())      // FIX applied for defect id-485
  //   );
  // }

  // protected getCode(): Observable<string> {
  //   return this.routingService
  //     .getRouterState()
  //     .pipe(map((state) => state.state.params['productCode']));
  // }
  getOotbProduct (): Observable<Product> {
    return this.currentProductService.getProduct(ProductScope.DEFAULT).pipe(
      tap(() => this.globalService.clearMessagesOnDestroy())
    );
  }
  getCurrentProduct(data: ProductInfo): void {
    this.globalService.loadingSubject.next(true);
    this.productData = data;
    let prdctObj: Options = null;
    if (data?.variantOptions?.length > 0) {
      let variant_N = data.variantOptions.find(obj => obj.code.endsWith(ProductVariant.NEW))
      let variant_R = data.variantOptions.find(obj => obj.code.endsWith(ProductVariant.REFURBISHED))
      if (variant_N) {
        prdctObj = variant_N;
      } else if (variant_R) {
        prdctObj = variant_R;
      }
      this.globalService.loadingSubject.next(false);
    }
    this.variantSubject.next(prdctObj);
  }

  getProductVariant(variantFlag: boolean): void {
    this.globalService.loadingSubject.next(true);
    let prdctObj: Options = null;
    const variant: string = variantFlag ? ProductVariant.NEW : ProductVariant.REFURBISHED;
    if (this.productData?.variantOptions?.length > 0) {
      prdctObj = this.productData.variantOptions.find(obj => obj.code.endsWith(variant))
      this.globalService.loadingSubject.next(false);
    }
    this.variantSubject.next(prdctObj);
  }

  getBtnVariants(data: ProductInfo): string[] {
    let strArr = [];
    if (data?.variantOptions?.length > 0) {
      for (const obj of data.variantOptions) {
        if (obj.code.endsWith(ProductVariant.NEW)) {
          strArr.push(ProductVariant.NEW)
        }
        if (obj.refurbished && obj.code.endsWith(ProductVariant.REFURBISHED)) {
          strArr.push(ProductVariant.REFURBISHED)
        }
      }
    }
    return strArr;
  }

  updatePdpCodeSubject(pCodeVal: string): void {
    this.productCodeSubject.next(pCodeVal);
  }

  public getReferences(pCodeVal: string): Observable<any> {
    const paramsObject = {
      fields: "FULL",
      referenceType: 'SIMILAR,OTHERS',
    }
    return this.apiService.getProductReferences(pCodeVal, paramsObject);
  }
}