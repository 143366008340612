import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { StorageService } from '../../../SGRE-shared/services/storage.service';
import { ApiService } from '../../../SGRE-shared/services/api.service';

@Injectable({
  providedIn: 'root'
})

export class UserProfileService {

  statuscode: string;
  role: string = '';
  disableThreeButton: boolean = false;

  private myProfileSubject = new BehaviorSubject<any>(null);
  profileData$ = this.myProfileSubject.asObservable();

  private orderRequestListSubject = new BehaviorSubject<any>(null);
  data$ = this.orderRequestListSubject.asObservable();

  private pendingListSubject = new BehaviorSubject<any>(null);
  pendingData$ = this.pendingListSubject.asObservable();

  private internalOrExternal = new BehaviorSubject<any>(null);
  checkRole$ = this.internalOrExternal.asObservable();

  private dataSubject = new BehaviorSubject<any[]>([]);
  currentData = this.dataSubject.asObservable();

  private isSelectedRequestSubject = new BehaviorSubject<boolean>(false);
  toggleRequest$ = this.isSelectedRequestSubject.asObservable();

  public userProfileSubjectData = new BehaviorSubject<any>({
    email: '',
    alternateEmail: '',
    customerName: '',
    company: '',
    customerMobilePhone: '',
    comment: ''
  });
  public myUserProfileData$ = this.userProfileSubjectData.asObservable();

  constructor(
    private readonly apiService: ApiService
  ) { }

  setMyProfileObject(userData: any) {
    this.userProfileSubjectData.next(userData)
  }

  public fetchUserProfile(currentUserId: string, targetUserId: string) {
    let paramObj = {
      fields: "FULL",
      currentPage: 0,
      pageSize: 200,
      userID: targetUserId,
      getPendingAccessRoles: false,
      getCustomerDetails: true
    }
    this.apiService.getUserProfileList(paramObj, currentUserId, {})
      .pipe(
        map((data: any) => {
          let user = {
            email: data?.customerEmail ? data?.customerEmail : '-',
            alternateEmail: data?.alternateEmail,
            customerName: data?.customerName ? data?.customerName : '-',
            company: data?.customerCompany ? data?.customerCompany : '-',
            customerMobilePhone: data.customerMobilePhone ? data.customerMobilePhone : '-',
            isInternal: data.isInternal,
            comment: data.comment
          }
          this.internalOrExternal.next(data.isInternal);
          this.setMyProfileObject(user);
          return data?.newLegalEntityPendingRoles.length
            ? (data?.newLegalEntityPendingRoles?.map(item => this.mapData(item)).concat(data?.legalEntities?.map(item => this.mapData(item))))
            : data?.legalEntities?.map(item => this.mapData(item))
        })
      )
      .subscribe((data) => this.myProfileSubject.next(data));
  }

  mapData(profileData, toggleRequest = false) {
    profileData['isRequestor'] = profileData?.roles?.includes('Requester') ? true : false;
    profileData['isPurchaser'] = profileData?.roles?.includes('Purchaser') ? true : false;
    profileData['isSalesRep'] = profileData?.roles?.includes('Sales Rep') ? true : false;
    profileData['isCustomerAdmin'] = profileData?.roles?.includes('Customer Admin') ? true : false;
    profileData['isPendingPurchaser'] = profileData?.pendingRoles?.includes('Purchaser') ? true : false;
    profileData['isPendingSalesRep'] = profileData?.pendingRoles?.includes('Sales Rep') ? true : false;
    profileData['isPendingCustomerAdmin'] = profileData?.pendingRoles?.includes('Customer Admin') ? true : false;
    profileData['isPendingRequester'] = profileData?.pendingRoles?.includes('Requester') ? true : false;
    profileData['Requestor'] = this.checkStatus(profileData, 'Requestor', profileData.isPendingRequester, profileData.isRequestor, toggleRequest);
    profileData['Purchaser'] = this.checkStatus(profileData, 'Purchaser', profileData.isPendingPurchaser, profileData.isPurchaser, toggleRequest);
    profileData['CustomerAdmin'] = this.checkStatus(profileData, 'CustomerAdmin', profileData.isPendingCustomerAdmin, profileData.isCustomerAdmin, toggleRequest);
    profileData['SalesRep'] = this.checkStatus(profileData, 'SalesRep', profileData.isPendingSalesRep, profileData.isSalesRep, toggleRequest);
    profileData['SalesRep'] = this.checkStatus(profileData, 'SalesRep', profileData.isPendingSalesRep, profileData.isSalesRep, toggleRequest);
    profileData['VATid'] = profileData?.vatId;
    profileData['Company'] = profileData?.company;
    profileData['LegalEntity'] = profileData?.LegalEntity ? profileData?.LegalEntity : profileData?.name;
    profileData['uid'] = profileData?.uid ? profileData?.uid : profileData?.legalEntityId;
    return profileData;
  }

  checkStatus(item: any, isUserType, pendingType, isType, toggleRequest?: boolean) {
    if ((!pendingType) && (!(isType))) {
      return item[isUserType] = false;
    } else if ((!pendingType) && (isType) && toggleRequest === false) {
      return item[isUserType] = true
    } else if ((!pendingType) && (isType) && toggleRequest === true) {
      return item[isUserType] = true;
    } else if (pendingType) {
      return item[isUserType] = false
    }
    else return ''
  }

  setSelected(toggleRequest: boolean, cartList?: any) {
    this.isSelectedRequestSubject.next(toggleRequest)
    if (cartList?.length > 0) {
      cartList.map((item, index) => {
        this.mapData(item, toggleRequest)
      })
    }
  }

  updateArrayData(data: any[]) {
    this.dataSubject.next(data)
  }

  public getLegalEntityList(requestObject: any) {
    return this.apiService.getLegalEntityList(requestObject.currentPage, requestObject.fields, requestObject.pageSize, requestObject.userID);
  }

  public getPendingRequestData(paramValue) {
    let paramObj = {
      fields: "FULL",
    }
    let legalEntityId = decodeURIComponent(paramValue.legalEntity);
    this.apiService.getPendingRequest(paramObj, paramValue, legalEntityId)
      .pipe(
        map((data: any) => {
          this.pendingListSubject.next(data);
          return data?.legalEntityPendingRoles?.map(item => item);
        })
      ).subscribe();
  }
}
