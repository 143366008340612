import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, switchMap, tap, throwError } from 'rxjs';
import { GlobalService } from '../../../SGRE-shared/services/global.service';
import { ApiService } from '../../../SGRE-shared/services/api.service';
import { selectUserLegalEntities, selectDefaultLegalEntity } from '../../../SGRE-shared/services/storage.state';
import { Store, select } from '@ngrx/store';
import { Facet, ProductSearchPage, ProductSearchService, SearchConfig } from '@spartacus/core';
import { CategoryName, FacetName } from '../models/plpInfo';

@Injectable({
  providedIn: 'root'
})
export class ProductListService {

  private productsListSubject = new BehaviorSubject<any>(null);
  productsList$ = this.productsListSubject.asObservable();

  private searchResultsListSubject = new BehaviorSubject<any>(null);
  searchData$ = this.searchResultsListSubject.asObservable();

  public paginationReset = new BehaviorSubject<any>(null);
  pagination$ = this.paginationReset.asObservable();

  public userLoggedIn: any;
  public currentPage: number = 0;
  public rows: number = 30;
  public legalEntityListSession: string[];
  public selectedLegalEntitySession: any;
  public searchTextboxQuery: string = null;
  userId: string;
  isLEPresent: boolean;
  public searchQuery: string = null;
  public filterQuery: string = ':name-asc';

  _query: string = ' ';
  _searchConfig: SearchConfig = {
    currentPage: 0,
    pageSize: 30
  };

  constructor(
    private globalService: GlobalService,
    private readonly apiService: ApiService,
    private store: Store,
    private productSearchService: ProductSearchService,
  ) { }

  /** -- Previous Implementation -- */
  public getProductsList(paramsObject = {}, searchResults = false) {
    this.globalService.loadingSubject.next(true);
    this.userId = this.globalService.getUserId();

    // Subscribe to userLegalEntities to get the array of legal entities
    this.store.pipe(select(selectUserLegalEntities)).subscribe((legalEntities) => {
      this.legalEntityListSession = Array.isArray(legalEntities) ? legalEntities.map((item) => item?.name) : [];
      this.store.pipe(select(selectDefaultLegalEntity)).subscribe((defaultLegalEntity) => {
        this.selectedLegalEntitySession = defaultLegalEntity ? defaultLegalEntity : '';
      });

      // Ensure that we wait for the checkLEPresent to be updated
      return this.globalService.checkLE$.pipe(
        switchMap((isLEPresent) => {
          this.isLEPresent = isLEPresent;

          if (this.selectedLegalEntitySession.name || this.userId === 'anonymous' || this.isLEPresent) {
            return this.apiService.getProductsList(paramsObject).pipe(
              tap(data => {
                let facets = data?.facets?.filter(item => item?.name === 'compatibility' || item?.name === 'allCategories');
                this.searchTextboxQuery = this.getQuery(facets);
                this.userId = '';
                if (!searchResults) {
                  let currentQuery = data?.currentQuery?.query?.value;
                  let index = currentQuery?.indexOf(':');
                  if (index != -1) {
                    this.searchQuery = currentQuery?.substring(0, index);
                    this.filterQuery = this.getQuery(facets);
                  }
                  this.productsListSubject.next(data);
                } else {
                  this.searchResultsListSubject.next(data);
                }
                this.globalService.clearMessagesOnDestroy();
                this.globalService.loadingSubject.next(false);
              }),
              catchError(error => {
                this.globalService.loadingSubject.next(false);
                return throwError(() => error);
              })
            );
          } else {
            // Handle case when no valid legal entity is present
            this.productsListSubject.next({ products: [], facets: [] });
            this.globalService.loadingSubject.next(false);
            return new Observable(); // Return an empty observable
          }
        }),
        catchError(error => {
          this.globalService.loadingSubject.next(false);
          return throwError(() => error);
        })
      ).subscribe(); // Ensure to subscribe to the observable
    });
  }

  getQuery(facets) {
    let query: string = ":name-asc";
    if (facets?.length > 0) {
      facets?.map(facet => {
        facet?.values?.map(item => {
          if (item?.selected) {
            query += ":" + facet?.name + ":" + `"${item?.name}"`
          }
        })
      });
    }
    return query;
  }

  /** -- OOTB Implementation -- */
  _search(queryVal?: string, configVal?: SearchConfig): void {
    this._query = queryVal ? queryVal : this._query;
    this._searchConfig = configVal ? configVal : this._searchConfig;
    this.productSearchService.search(this._query, this._searchConfig);
  }

  _onPageChange(event: any) {
    this._searchConfig = {
      currentPage: event.page,
      pageSize: event.rows
    }
    this._search(this._query, this._searchConfig);
  }

  getFacets(): Observable<Facet[]> {
    return this.productSearchService.getResults()
      .pipe(
        map((data: ProductSearchPage) => {
          let facets: Facet[] = data.facets;
          if (facets?.length > 0 && facets.some(x => x.name === FacetName.ALL_CATEGORIES && x.values?.length > 0)) {
            const categoryObj: Facet = { ...facets.find(x => x.name === FacetName.ALL_CATEGORIES) };
            categoryObj.values = categoryObj.values.filter(x => x.name !== CategoryName.TOP_FEATURED_PRODUCTS);
            facets = facets.filter(x => x.name !== FacetName.ALL_CATEGORIES);
            facets = [...facets, categoryObj];
          }
          return facets;
        })
      );
  }
}
