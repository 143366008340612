import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { ApiService } from '../../../SGRE-shared/services/api.service';
import { GlobalService } from '../../../SGRE-shared/services/global.service';

@Injectable({
  providedIn: 'root'
})
export class MyProfileService {

  constructor(
    private readonly apiService: ApiService,
    private globalService: GlobalService,
  ) {
  }

  private myProfileSubject = new BehaviorSubject<any>(null);
  profileData$ = this.myProfileSubject.asObservable();

  private isSelectedRequestSubject = new BehaviorSubject<boolean>(false);
  toggleRequest$ = this.isSelectedRequestSubject.asObservable();

  private dataSubject = new BehaviorSubject<any[]>([]);
  currentData = this.dataSubject.asObservable();

  private updateTableSubject = new BehaviorSubject<string>('');
  currentCommentData = this.updateTableSubject.asObservable();

  private booleanSubject = new BehaviorSubject<boolean>(false);
  currentBoolean = this.booleanSubject.asObservable()

  public myProfileListSubject = new BehaviorSubject<any>({
    email: '',
    alternateEmail: '',
    customerName: '',
    isInternal: false
  });
  public myProfileData$ = this.myProfileListSubject.asObservable();

  setSelected(toggleRequest: boolean) {
    this.isSelectedRequestSubject.next(toggleRequest)
    //this.booleanSubject.next(toggleRequest)
  }

  setMyProfileObject(userData: any) {
    this.myProfileListSubject.next(userData)
  }

  updateAlternativeEmail(requestObject?) {
    let email = `?email=${requestObject}`
    return this.apiService.updateEmail(requestObject, email)
  }

  updateArrayData(data: any[]) {
    this.dataSubject.next(data)
  }

  updateCommentData(update: any) {
    this.updateTableSubject.next(update)
  }

  mapData(profileData) {
    profileData['isRequestor'] = profileData?.roles?.includes('Requester') ? true : false;
    profileData['isPurchaser'] = profileData?.roles?.includes('Purchaser') ? true : false;
    profileData['isSalesRep'] = profileData?.roles?.includes('Sales Rep') ? true : false;
    profileData['isCustomerAdmin'] = profileData?.roles?.includes('Customer Admin') ? true : false;
    profileData['isPendingPurchaser'] = profileData?.pendingRoles?.includes('Purchaser') ? true : false;
    profileData['isPendingSalesRep'] = profileData?.pendingRoles?.includes('Sales Rep') ? true : false;
    profileData['isPendingCustomerAdmin'] = profileData?.pendingRoles?.includes('Customer Admin') ? true : false;
    profileData['isPendingRequester'] = profileData?.pendingRoles?.includes('Requester') ? true : false;
    profileData['Requestor'] = this.checkStatus(profileData, 'Requestor', profileData.isPendingRequester, profileData.isRequestor);
    profileData['Purchaser'] = this.checkStatus(profileData, 'Purchaser', profileData.isPendingPurchaser, profileData.isPurchaser);
    profileData['CustomerAdmin'] = this.checkStatus(profileData, 'CustomerAdmin', profileData.isPendingCustomerAdmin, profileData.isCustomerAdmin);
    profileData['SalesRep'] = this.checkStatus(profileData, 'SalesRep', profileData.isPendingSalesRep, profileData.isSalesRep);
    profileData['SalesRep'] = this.checkStatus(profileData, 'SalesRep', profileData.isPendingSalesRep, profileData.isSalesRep);
    profileData['VAT id'] = profileData?.vatId;
    profileData['Company'] = profileData?.company;
    profileData['Legal Entity'] = profileData?.name ? profileData?.name : profileData?.legalEntityName;
    profileData['name'] = profileData?.name ? profileData?.name : profileData?.legalEntityName;
    profileData['uid'] = profileData?.uid ? profileData?.uid : profileData?.legalEntityId;
    return profileData;
  }

  checkStatus(item: any, isUserType, pendingType, isType) {
    if ((!pendingType) && (!(isType))) {
      return item[isUserType] = false;
    } else if ((!pendingType) && (isType)) {
      return item[isUserType] = { status: 'icon' }
    } else if (pendingType) {
      return item[isUserType] = { status: 'text' }
    }
    else return ''
  }

  public fetchMyProfileList() {
    let paramObj = {
      fields: "FULL",
      currentPage: 0,
      pageSize: 200,
      userID: this.globalService.getUserId(),
      getPendingAccessRoles: true,
      getCustomerDetails: true
    }

    this.apiService.getProfileList(paramObj, {})
      .pipe(
        map((data: any) => {
          let user = {
            email: data?.customerEmail,
            alternateEmail: data?.alternateEmail ? data?.alternateEmail : '-',
            customerName: data?.customerName,
            isInternal: data?.isInternal
          }
          this.setMyProfileObject(user);
          return data?.newLegalEntityPendingRoles?.length ? (data?.newLegalEntityPendingRoles?.map(item => this.mapData(item)).concat(data?.legalEntities?.map(item => this.mapData(item)))) : data?.legalEntities?.map(item => this.mapData(item))
        })
      )
      .subscribe({
        next: (data) => {
          this.myProfileSubject.next(data);
          this.globalService.loadingSubject.next(false);
        },
        error: (error) => {
          this.globalService.loadingSubject.next(false);
        }
      });
  }

  public getAllLegalEntity(requestObject): Observable<any> {
    return this.apiService.getLegalEntity(requestObject, {});
  }

  public updateRequestAccess(requestObject, requestComment): Observable<any> {
    return this.apiService.updateAccessRequest(requestObject, requestComment)
  }

}

export interface User {
  email: '',
  alternateEmail: '',
  customerName: ''
}
