import { ChangeDetectorRef, ChangeDetectionStrategy, Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, tap, takeUntil, Subject, of, filter, timer, take, switchMap } from 'rxjs';
import { ProductDetailsService } from '../../services/product-details-service';
import { Options } from '../../models/product-info';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { isNotNullable, isNotUndefined } from '@spartacus/core';
import { Store,select } from '@ngrx/store';
import { selectActiveCart, selectCartId, selectUserRoles } from '../../../../SGRE-shared/services/storage.state';
import { ActiveCartService, MultiCartService, MultiCartStatePersistenceService, SelectiveCartService } from '@spartacus/cart/base/core';
import {
  ActiveCartFacade,
  MultiCartFacade
} from '@spartacus/cart/base/root';
@Component({
  selector: 'app-add-to-cart',
  templateUrl: './add-to-cart.component.html',
  styleUrl: './add-to-cart.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddToCartComponent implements OnInit, OnDestroy {
  userRoles: string[];
  roleFlag: boolean;
  productDetails$: Observable<any>;
  productOption$: Observable<Options>;
  baseProductCode: string;
  variantProductCode: string;
  quantityValue: number;
  minQuantity: number;
  maxQuantity: number;
  maxQtyLimit: number;
  step: number;
  isMaxLmtReached: Observable<boolean>;
  isAddedToCart: boolean;
  showSpinner: number = 0;
  unsubscribe$ = new Subject<void>();
  storeCartId: any;
  storeActiveCart: any;

  constructor(
    private productsService: ProductDetailsService,
    private globalService: GlobalService,
    private changeRef: ChangeDetectorRef,
    private store: Store,
    private multiCartService: MultiCartFacade,
    private ootbCartService: ActiveCartFacade
  ) { }

  ngOnInit(): void {
    this.store.pipe(select(selectUserRoles)).subscribe((userRoles) => {
      this.userRoles = userRoles;
    });
    this.roleCheck(this.userRoles);
    this.productDetails$ = this.productsService.getOotbProduct().pipe(
      tap((data) => this.baseProductCode = data?.code)
    );

   
    this.productOption$ = this.productsService.variant$.pipe(
      tap((data) => {
        this.variantProductCode = data?.code;
        if (data && Object.keys(data)?.length > 0) {
          let availbleQty = this.getAvailableQty();
          this.step = data?.interval > 0 ? data?.interval : 1;
          this.minQuantity = this.globalService.fetchProductQuantityValue(data, availbleQty);
          this.maxQtyLimit = data?.maxQuantity ? data.maxQuantity : 1000;
          this.checkMaxQty();
          this.quantityValue = this.globalService.fetchProductQuantityValue(data, availbleQty);
          this.isMaxLmtReached = of(availbleQty >= this.maxQtyLimit);
        }
      })
    );

    this.handleSubscriptions();
  }

  handleSubscriptions() {
    // on Add To Cart
    this.globalService.carts$
      .pipe(
        filter(isNotNullable),
        filter(isNotUndefined),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => {
        let availbleQty = this.getAvailableQty();
        this.isMaxLmtReached = of(availbleQty >= this.maxQtyLimit);
        this.quantityValue = this.step;
        this.minQuantity = this.step;
        this.changeRef.markForCheck();
      });
  }

  roleCheck(roles: string[] | string) {
    if (roles?.length > 0) {
      this.roleFlag = (typeof roles === 'string')
        ? JSON.parse(roles).includes('Requester')
        : roles.includes('Requester');
    }
  }

  getAvailableQty(): number {
    this.store.pipe(select(selectActiveCart)).subscribe((carts) => {
      this.storeActiveCart = carts;
    });
    let cardDetails = this.storeActiveCart;
    let productDetails = cardDetails?.entries?.filter(
      (item) => item.product.baseProduct === this.baseProductCode && item.product.code === this.variantProductCode
    );
    let quantity = (productDetails?.at(0)?.quantity)
      ? productDetails?.at(0)?.quantity
      : 0;
    return quantity;
  }

  checkMaxQty() {
    let availbleQty = this.getAvailableQty();
    this.maxQuantity = availbleQty ? (this.maxQtyLimit - availbleQty) : this.maxQtyLimit;
  }

  verifyQty() {
    let availbleQty = this.getAvailableQty();
    if ((this.quantityValue === 0 || this.quantityValue < this.minQuantity) && availbleQty === 0) {
      this.quantityValue = this.minQuantity;
    }
    if (this.quantityValue > (this.maxQtyLimit - availbleQty)) {
      this.quantityValue = availbleQty ? (this.maxQtyLimit - availbleQty) : this.maxQtyLimit;
    } else {
      this.quantityValue = this.checkQtyRange(this.quantityValue, this.step);
    }
  }

  checkQtyRange(qty, step): number {
    let updatedQty;
    if (qty > this.maxQuantity) {
      updatedQty = this.maxQuantity;
    } else if (qty <= step) {
      updatedQty = step;
    } else if (qty % step != 0) {
      updatedQty = Math.round(qty / step) * step;
    } else {
      updatedQty = qty;
    }
    return updatedQty;
  }

  onAddToCart(codeVal: string) {
    this.isAddedToCart = true;
    let paramsObject = {
      code: codeVal,
      quantity: this.quantityValue,
    };
    let requestObject = {
      product: {
        code: codeVal,
      },
      quantity: this.quantityValue,
    };
    this.store.pipe(select(selectCartId)).subscribe((cartId) => {
      this.storeCartId = cartId;
    });
    let cartId = this.storeCartId;
    this.globalService.loadingSubject.next(true);
    this.showSpinner = 1;

    this.multiCartService.loadCart({
      cartId: cartId,
      userId: this.globalService.getUserId(),
    });
    this.ootbCartService.addEntry(codeVal, this.quantityValue);

    this.ootbCartService.isStable().pipe(
      filter(stable => stable),
      take(1),
      tap(() => this.changeStatus()),
      tap(() => this.globalService.getCartsListByLegalEntity()),
      switchMap(() => this.globalService.carts$),
      tap(data => {
        const availbleQty = this.getAvailableQty();
        this.isMaxLmtReached = of(availbleQty >= this.maxQtyLimit);
        this.quantityValue = this.step;
        this.minQuantity = this.step;
        this.changeRef.markForCheck();
      }),
      takeUntil(this.unsubscribe$)
    ).subscribe({
      error: () => {
        this.showSpinner = 0;
        this.globalService.loadingSubject.next(false);
      }
    });
  }

  changeStatus() {
    this.showSpinner = 2;
    this.changeRef.markForCheck();
    timer(1000)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.showSpinner = 0;
        this.changeRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.globalService.clearMessagesOnDestroy();
  }
}
