import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import {
  Column,
  ColumnType,
} from '../../../../SGRE-shared/models/tableConfiguration';
import { usersService } from '../../services/users.service';
import { AppConstants } from '../../../../SGRE-shared/constants/app-constant';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { Router } from '@angular/router';
import { selectUserLegalEntities,selectUserRoles } from '../../../../SGRE-shared/services/storage.state';
import { Store,select } from '@ngrx/store';
import * as StorageActions from '../../../../SGRE-shared/services/storage.actions';
@Component({
  selector: 'app-invite-users-table',
  templateUrl: './invite-users-table.component.html',
  styleUrl: './invite-users-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InviteUsersTableComponent {
  public columns: Column[];
  public columnType: any[];
  public sgredetails: any;
  public masterSgreDetails: any;
  public role: string = '';
  legaldata: any = [];
  legaldataFilteroptions: any[] = [];
  displayname: any[] = [];
  company: any[] = [];
  companylist: any[] = [];
  username: any[] = [];
  alternativeEmail: any[] = [];
  filteredTableData: any[] = [];
  legalEntities: any[] = [];
  userRoles: any;

  constructor(
    private userService: usersService,
    private changeRef: ChangeDetectorRef,
    private globalService: GlobalService,
    private router: Router,
    private store: Store
  ) { }

  ngOnInit() {
    const legalEntitiesSubscription = this.store.pipe(select(selectUserLegalEntities)).subscribe((legalEntities) => {
      this.legalEntities = legalEntities ? legalEntities : [];
      this.getsessionvalues();
      this.globalService.loadingSubject.next(true);
      this.getuserdata();
    });
  }

  getsessionvalues() {
    this.store.pipe(select(selectUserRoles)).subscribe((userRoles) => {
      this.userRoles = userRoles;
    });
    let userRole: any = '';
    userRole = this.userRoles;
    if (userRole?.includes('Customer Admin')) {
      this.role = 'Customer Admin';
    } else if (userRole?.includes('SGRE Admin')) {
      this.role = 'SGRE Admin';
    }
  }

  findLegalEntityName(uid) {
    return this.legalEntities?.find(item => item.uid === uid)?.name;
  }

  getuserdata() {
    this.globalService.loadingSubject.next(true);
    let paramsObject = { currentPage: 0, fields: 'BASIC', pageSize: 20 };
    this.userService.userssgredetails(paramsObject)
      .subscribe((data: any) => {
        this.sgredetails = data.b2bCustomers.map((item) => {
          item['navigateUrl'] = item.userId
          let legalEntites = [];
          item?.legalEntityList?.forEach(item => legalEntites.push(this.findLegalEntityName(item)))
          item['legalEntityNames'] = legalEntites;
          return item;
        });
        this.userService.filteredData = this.sgredetails;
        this.masterSgreDetails = JSON.parse(JSON.stringify(this.sgredetails));
        this.createTableSGREConfiguration(this.masterSgreDetails);
        this.globalService.loadingSubject.next(false);
        this.changeRef.markForCheck();
      });
  }

  onFilter(data) {
    this.filteredTableData = data;
    this.userService.filteredData = data.filteredData;
  }

  createTableSGREConfiguration(userdata) {
    let legalentities = [];
    let defaultlegalentities = [];
    userdata.map((x: any) => {
      if (x.displayName != undefined) {
        this.displayname.push({ displayName: x.displayName });
      }
      if (x.company != undefined) {
        this.company.push({ company: x.company });
      }
      if (x.email != undefined) {
        this.username.push({ email: x.email });
      }
      if (x.alternativeEmail != undefined) {
        this.alternativeEmail.push({ alternativeEmail: x.alternativeEmail });
      }
    });
    let company = this.company.reduce((unique, o) => {
      if (!unique.some((obj) => obj.company === o.company)) {
        unique.push(o);
      }
      return unique;
    }, []);
    let alternativeemail = this.alternativeEmail.reduce((unique, o) => {
      if (!unique.some((obj) => obj.alternativeEmail === o.alternativeEmail)) {
        unique.push(o);
      }
      return unique;
    }, []);
    let paramsObject = { currentPage: 0, fields: 'FULL', pageSize: 20, userID: this.globalService.getUserId() };
    this.userService.getlegalentities(paramsObject)
      .subscribe((data1: any) => {
        this.legaldata = [...data1.legalEntities];
        this.legaldata.map((j: any) => {
          j.roles.filter((l: any) => {
            if (this.role === 'Customer Admin') {
              if (l === 'Customer Admin') {
                defaultlegalentities.push(j.uid);
                let data = { uid: j.uid, legalEntityCount: j.name };
                this.legaldataFilteroptions.push(data);
                this.sgredetails = this.sgredetails.map((x: any) => {
                  let userlegalentities = x.legalEntityList;

                  userlegalentities = userlegalentities.filter((element) =>
                    defaultlegalentities.includes(element)
                  );
                  x['legalEntityCount'] = userlegalentities.length;
                  return x;
                });
              }
            }
            if (this.role === 'SGRE Admin') {
              let data = { uid: j.uid, legalEntityCount: j.name };
              this.legaldataFilteroptions.push(data);
            }
          });
        });
      });
    let columnData = [
      {
        label: 'Name',
        name: 'displayName',
        type: ColumnType.link,
        filter: true,
        filterOptions: this.displayname,
      },
      {
        label: 'Username',
        name: 'email',
        type: ColumnType.link,
        filter: true,
        filterOptions: this.username,
      },
      {
        label: 'Alternative Email',
        name: 'alternativeEmail',
        type: ColumnType.text,
        filter: true,
        filterOptions: alternativeemail,
      },
      {
        label: 'Company',
        name: 'company',
        type: ColumnType.text,
        filter: true,
        filterOptions: company,
      },
      {
        label: 'Legal Entities',
        name: 'legalEntityCount',
        type: ColumnType.number,
        filter: true,
        filterOptions: this.legaldataFilteroptions,
      },
    ];
    this.columns = columnData.map((item) => new Column(item));
  }

  navigate(data) {
    this.store.dispatch(StorageActions.setUserProfileData({ userProfileData: 'users' }));
    this.router.navigate([AppConstants.routeUrls.userProfile], {
      queryParams: { user: data },
    });
  }
}
