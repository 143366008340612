import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageSlotModule } from '@spartacus/storefront';
import { ConfigModule, I18nModule } from '@spartacus/core';
import { PrimengModule } from '../primeng/primeng.module';
import { SGRESharedModule } from '../../SGRE-shared/SGRE-shared.module';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
  TranslateStore,
} from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { HttpBackend } from '@angular/common/http';

import { CustomRouteGuard } from '../../SGRE-shared/services/guard/msal.guard';

import { CSVDownloadComponent } from './components/csv-download/csv-download.component';
import { InviteUsersTableComponent } from './components/invite-users-table/invite-users-table.component';
import { InviteUserButtonComponent } from './components/invite-user-button/invite-user-button.component';


export function HttpLoaderFactory(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, ['/assets/i18n/core/', '/assets/i18n/vendors/']);
}

@NgModule({
  declarations: [
    CSVDownloadComponent,
    InviteUsersTableComponent,
    InviteUserButtonComponent
  ],
  imports: [
    CommonModule,
    PageSlotModule,
    PrimengModule,
    SGRESharedModule,
    ConfigModule.withConfig({
      cmsComponents: {
        InviteUserButtonComponent: {
          component: () => import('./components/invite-user-button/invite-user-button.component').then(m => m.InviteUserButtonComponent),
          guards: [CustomRouteGuard]
        },
        DownloadCSVButtonComponent: {
          component: () => import('./components/csv-download/csv-download.component').then(m => m.CSVDownloadComponent),
          guards: [CustomRouteGuard]
        },
        UsersTableComponent: {
          component: () => import('./components/invite-users-table/invite-users-table.component').then(m => m.InviteUsersTableComponent),
          guards: [CustomRouteGuard]
        },
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    I18nModule
  ],
  providers: [
    TranslateService,
    { provide: TranslateStore }
  ]
})
export class UsersModule { }