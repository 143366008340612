import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductDetailsService } from '../../services/product-details-service';
import { Options } from '../../models/product-info';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';

@Component({
  selector: 'app-product-description',
  templateUrl: './product-description.component.html',
  styleUrl: './product-description.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductDescriptionComponent implements OnInit, OnDestroy {

  productDetails$: Observable<any>;
  productOption$: Observable<Options>;

  constructor(
    public productsService: ProductDetailsService,
    private globalService: GlobalService,
  ) { }

  ngOnInit(): void {
    this.productDetails$ = this.productsService.getOotbProduct();
    this.productOption$ = this.productsService.variant$;
  }

  checkDimensions(optionObj: Options): boolean {
    let flag: boolean = false;
    if (optionObj && Object.keys(optionObj).length > 0) {
      let i = 0;
      if (optionObj.length) i++;
      if (optionObj.width) i++;
      if (optionObj.height) i++;
      if (optionObj.volume) i++;

      flag = i > 1;
    }
    return flag;
  }

  ngOnDestroy(): void {
    this.globalService.clearMessagesOnDestroy();
  }
}