import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, mergeMap, of } from 'rxjs';
import { ApiService } from '../../../SGRE-shared/services/api.service';
import { GlobalService } from '../../../SGRE-shared/services/global.service';
import { selectDefaultLegalEntity } from '../../../SGRE-shared/services/storage.state';
import { select, Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class HomepageService {

  private imageList = new BehaviorSubject<any[]>([]);
  data$ = this.imageList.asObservable();

  private categoriesListSubject = new BehaviorSubject<any>(null);
  dataCat$ = this.categoriesListSubject.asObservable();

  private featuredProductListSubject = new BehaviorSubject<any>(null);
  featuredProduct$ = this.featuredProductListSubject.asObservable();
  selectedLegalEntitySession: any;

  constructor(
    private apiService: ApiService,
    private globalService: GlobalService,
    private store: Store
  ) { }

  public getCategoriesAPI() {
    let paramsObject = { fields: 'FULL' }

    this.apiService.getHomePageCategories(paramsObject)
      .subscribe({
        next: (data: any) => {
          const filteredData = data.categories.filter(item => item.code != 'TOP FEATURED PRODUCTS');
          this.categoriesListSubject.next(filteredData);
        }
      });
  }

  getCarouselImages(banners: string[]) {
    of(banners)
      .pipe(
        mergeMap((banner: string[]) => {
          let fields = 'DEFAULT';
          let currentPage = 0;
          let pageSize = banners.length;

          return forkJoin(
            banner.map((b) => {
              return this.apiService.getCarouselImagesHome(fields, currentPage, pageSize, b)
            })
          );
        })
      )
      .subscribe((data) => this.imageList.next(data));
  }

  public getFeaturedProductList() {
    const userId = this.globalService.getUserId();
    this.store.pipe(select(selectDefaultLegalEntity)).subscribe((defaultLegalEntity) => {
      this.selectedLegalEntitySession = defaultLegalEntity ? defaultLegalEntity : '';
    });
    if (this.selectedLegalEntitySession.name || userId == 'anonymous') {
      this.apiService.getTopFeaturedProductList(0, 'FULL', 20).subscribe({
        next: (data) => this.featuredProductListSubject.next(data),
        error: (error) => console.log("Error from Featured Product Service")
      });
    } else {
      this.featuredProductListSubject.next({ products: [] })
    }
  }
}
