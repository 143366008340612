import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { ApiService } from '../../../../SGRE-shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class OrderRequestsService {

  constructor(private globalService: GlobalService,
    private apiService: ApiService,
  ) { }

  private orderRequestListSubject = new BehaviorSubject<any>(null);
  data$ = this.orderRequestListSubject.asObservable();


  public getOrderRequestList(params) {
    this.globalService.loadingSubject.next(true);
    this.apiService.getOrderRequestList(params).pipe(
      map((data: any) => {
        let results = data?.results?.map(item => this.mapData(item));
        data['results'] = results;
        return data;
      })
    )
      .subscribe({
        next: (data) => {
          this.orderRequestListSubject.next(data);
          this.globalService.loadingSubject.next(false);
        }, error: (error) => {
          this.globalService.loadingSubject.next(false);
        }
      });
  }

  mapData(orderRequestData) {
    let requestedBy = orderRequestData?.requestedBy ? orderRequestData?.requestedBy.split('|')[1] : '';
    orderRequestData['legalEntity'] = orderRequestData?.legalEntity?.name;
    orderRequestData['orderRequestedBy'] = requestedBy != 'null' ? requestedBy : '';
    orderRequestData['productsCount'] = orderRequestData?.lineItemCount;
    orderRequestData['totalPrice'] = orderRequestData?.orderTotal;
    orderRequestData['requestDate'] = orderRequestData?.orderDate;
    orderRequestData['status'] = 'edit';
    return orderRequestData;
  }

}