import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-invite-user-companylist',
  templateUrl: './invite-user-companylist.component.html',
  styleUrl: './invite-user-companylist.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InviteUserCompanylistComponent {
  userType: string;

  mForm: FormGroup = new FormGroup({
    userCompany: new FormControl(''),
    innerCompany: new FormControl('')
  });
  formBuilder: any;

  constructor(){}

  ngOnInit(): void {
    this.mForm = this.formBuilder.group({
      userCompany: ['', [Validators.required]],
      innerCompany: ['', [Validators.required]]
    });
  }

}
