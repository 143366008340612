<div class="row ml-2">
    <span *ngIf="isError" class="error m-2">
        {{"bulkOrder.youMustUploadFile"|cxTranslate}}
    </span>
</div>
<div role="group" class="row btn-group m-1">
    <div class="col">
        <button class="btn cstm-btn-primary" (click)="onNext()">
            {{(counter<=2)?('bulkOrder.next'|cxTranslate):('bulkOrder.submitProcced'|cxTranslate)}} </button>
                <button class="btn cstm-btn-light ml-3" (click)="onCancel()">
                    {{"bulkOrder.cancel"|cxTranslate}}</button>
    </div>
</div>

<div class="modal" id="exampleModal" tabindex="-1" role="dialog" data-backdrop="true" [ngStyle]={display:this.display}>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header d-flex justify-content-between align-items-center">
                <h4 class="m-0">
                    {{('bulkOrder.submit'|cxTranslate)}}
                </h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="goNext()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <label for="">{{('bulkOrder.emailSent'|cxTranslate)}}</label>
            </div>
            <div class="modal-footer d-flex justify-content-start">
                <button class="btn cstm-btn-primary" data-dismiss="modal" data-dismiss="modal" (click)="goNext()">
                    {{('bulkOrder.ok'|cxTranslate)}}
                </button>
            </div>
        </div>
    </div>
</div>