import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageSlotModule } from '@spartacus/storefront';
import { ConfigModule } from '@spartacus/core';

import { FooterContainerComponent } from './Components/footer-container/footer-container.component';
import { FooterNavigationComponent } from './Components/footer-navigation/footer-navigation.component';

@NgModule({
  declarations: [
    FooterContainerComponent,
    FooterNavigationComponent,
  ],
  imports: [
    CommonModule,
    PageSlotModule,
    ConfigModule.withConfig({
      cmsComponents: {
        FooterContainerComponent: {
          component: FooterContainerComponent,
        },
        FooterNavigationComponent: {
          component: FooterNavigationComponent,
        }
      }
    })
  ]
})
export class FooterModule { }
