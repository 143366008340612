import { Injectable } from '@angular/core';
import { AppConstants } from '../constants/app-constant';
import { StatePersistenceService } from '@spartacus/core';
import { Store, select } from '@ngrx/store';
import { distinctUntilChanged, Observable } from 'rxjs';
import {
  selectMsalInfo,
  selectAccessToken,
  selectRedirectUrl,
  selectCurrency,
  selectUserLegalEntities,
  selectDefaultLegalEntity,
  selectUserRoles,
  selectDateFormat,
  selectSelectedLanguage,
  selectQuery,
  selectCartId,
  selectCarts,
  selectActiveCart,
  selectParamsCartGroupId,
  selectCheckoutCartGroupId,
  selectCartCode,
  selectSecondaryCartCode,
  selectUserProfileData
} from './storage.state';
import * as StorageActions from './storage.actions';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  
  // login
  private _msalInfo$: Observable<any>;
  private _accessToken$: Observable<any>;
  private _redirectUrl$: Observable<any>;
  private _currency$: Observable<any>;

  // legal-entity
  private _userLegalEntities$: Observable<any>;
  private _defaultLegalEntity$: Observable<any>;

  // user-roles
  private _userRoles$: Observable<any>;

  // my profile
  private _dateFormat$: Observable<any>;
  private _selectedLanguage$: Observable<any>;

  // plp
  private _query$: Observable<any>;

  // cart
  private _cartId$: Observable<any>;
  private _carts$: Observable<any>;
  private _activeCart$: Observable<any>;

  // checkout
  private _paramsCartGroupId$: Observable<any>;
  private _checkoutCartGroupId$: Observable<any>;
  private _cartCode$: Observable<any>;
  private _secondaryCartCode$: Observable<any>;

  // user-management
  private _userProfileData$: Observable<any>;
  constructor(
    protected statePersistenceService: StatePersistenceService,
    protected store: Store<any>
  ) { 
    this.initializeSync();
    this.loadInitialState();
  }

  private initializeSync() {
    this._msalInfo$ = this.store.pipe(select(selectMsalInfo), distinctUntilChanged());
    this._accessToken$ = this.store.pipe(select(selectAccessToken), distinctUntilChanged());
    this._redirectUrl$ = this.store.pipe(select(selectRedirectUrl), distinctUntilChanged());
    this._currency$ = this.store.pipe(select(selectCurrency), distinctUntilChanged());
    this._userRoles$ = this.store.pipe(select(selectUserRoles), distinctUntilChanged());
    this._userLegalEntities$ = this.store.pipe(select(selectUserLegalEntities), distinctUntilChanged());
    this._defaultLegalEntity$ = this.store.pipe(select(selectDefaultLegalEntity), distinctUntilChanged());
    this._dateFormat$ = this.store.pipe(select(selectDateFormat), distinctUntilChanged());
    this._selectedLanguage$ = this.store.pipe(select(selectSelectedLanguage), distinctUntilChanged());
    this._query$ = this.store.pipe(select(selectQuery), distinctUntilChanged());
    this._cartId$ = this.store.pipe(select(selectCartId), distinctUntilChanged());
    this._carts$ = this.store.pipe(select(selectCarts), distinctUntilChanged());
    this._activeCart$ = this.store.pipe(select(selectActiveCart), distinctUntilChanged());
    this._paramsCartGroupId$ = this.store.pipe(select(selectParamsCartGroupId), distinctUntilChanged());
    this._checkoutCartGroupId$ = this.store.pipe(select(selectCheckoutCartGroupId), distinctUntilChanged());
    this._cartCode$ = this.store.pipe(select(selectCartCode), distinctUntilChanged());
    this._secondaryCartCode$ = this.store.pipe(select(selectSecondaryCartCode), distinctUntilChanged());
    this._userProfileData$ = this.store.pipe(select(selectUserProfileData), distinctUntilChanged());
  }

  private loadInitialState() {
    this.statePersistenceService.syncWithStorage({
      key: AppConstants.LocalStorageKeys.msalInfo,
      state$: this._msalInfo$,
      onRead: (state) => this.store.dispatch(StorageActions.setMsalInfo({ msalInfo: state }))
    });
    this.statePersistenceService.syncWithStorage({
      key: AppConstants.SessionStorageKeys.userLegalEntities,
      state$: this._userLegalEntities$,
      onRead: (state) => this.store.dispatch(StorageActions.setUserLegalEntities({ userLegalEntities: state }))
    });
    this.statePersistenceService.syncWithStorage({
      key: AppConstants.SessionStorageKeys.defaultLegalEntity,
      state$: this._defaultLegalEntity$,
      onRead: (state) => this.store.dispatch(StorageActions.setDefaultLegalEntity({ defaultLegalEntity: state }))
    });
    this.statePersistenceService.syncWithStorage({
      key: AppConstants.SessionStorageKeys.userRoles,
      state$: this._userRoles$,
      onRead: (state) => this.store.dispatch(StorageActions.setUserRoles({ userRoles: state }))
    });
    this.statePersistenceService.syncWithStorage({
      key: AppConstants.SessionStorageKeys.dateFormat,
      state$: this._dateFormat$,
      onRead: (state) => this.store.dispatch(StorageActions.setDateFormat({ dateFormat: state }))
    });
    this.statePersistenceService.syncWithStorage({
      key: AppConstants.SessionStorageKeys.selectedLanguage,
      state$: this._selectedLanguage$,
      onRead: (state) => this.store.dispatch(StorageActions.setSelectedLanguage({ selectedLanguage: state }))
    });
    this.statePersistenceService.syncWithStorage({
      key: AppConstants.SessionStorageKeys.query,
      state$: this._query$,
      onRead: (state) => this.store.dispatch(StorageActions.setQuery({ query: state }))
    });
    this.statePersistenceService.syncWithStorage({
      key: AppConstants.SessionStorageKeys.cartId,
      state$: this._cartId$,
      onRead: (state) => this.store.dispatch(StorageActions.setCartId({ cartId: state }))
    });
    this.statePersistenceService.syncWithStorage({
      key: AppConstants.SessionStorageKeys.carts,
      state$: this._carts$,
      onRead: (state) => this.store.dispatch(StorageActions.setCarts({ carts: state }))
    });
    this.statePersistenceService.syncWithStorage({
      key: AppConstants.SessionStorageKeys.activeCart,
      state$: this._activeCart$,
      onRead: (state) => this.store.dispatch(StorageActions.setActiveCart({ activeCart: state }))
    });
    this.statePersistenceService.syncWithStorage({
      key: AppConstants.SessionStorageKeys.paramsCartGroupId,
      state$: this._paramsCartGroupId$,
      onRead: (state) => this.store.dispatch(StorageActions.setParamsCartGroupId({ paramsCartGroupId: state }))
    });
    this.statePersistenceService.syncWithStorage({
      key: AppConstants.SessionStorageKeys.checkoutCartGroupId,
      state$: this._checkoutCartGroupId$,
      onRead: (state) => this.store.dispatch(StorageActions.setCheckoutCartGroupId({ checkoutCartGroupId: state }))
    });
    this.statePersistenceService.syncWithStorage({
      key: AppConstants.SessionStorageKeys.cartCode,
      state$: this._cartCode$,
      onRead: (state) => this.store.dispatch(StorageActions.setCartCode({ cartCode: state }))
    });
    this.statePersistenceService.syncWithStorage({
      key: AppConstants.SessionStorageKeys.secondaryCartCode,
      state$: this._secondaryCartCode$,
      onRead: (state) => this.store.dispatch(StorageActions.setSecondaryCartCode({ secondaryCartCode: state }))
    });
    this.statePersistenceService.syncWithStorage({
      key: AppConstants.SessionStorageKeys.userProfileData,
      state$: this._userProfileData$,
      onRead: (state) => this.store.dispatch(StorageActions.setUserProfileData({ userProfileData: state }))
    });
  }
  // login
  public get msalInfo() {
    return this._msalInfo$;
  }

  public set msalInfo(v) {
    this.store.dispatch(StorageActions.setMsalInfo({ msalInfo: v }));
  }

  public get accessToken() {
    return this._accessToken$;
  }

  public set accessToken(v) {
    this.store.dispatch(StorageActions.setAccessToken({ accessToken: v }));
  }

  public get redirectUrl() {
    return this._redirectUrl$;
  }

  public set redirectUrl(v) {
    this.store.dispatch(StorageActions.setRedirectUrl({ redirectUrl: v }));
  }

  public get currency() {
    return this._currency$;
  }

  public set currency(v) {
    this.store.dispatch(StorageActions.setCurrency({ currency: v }));
  }

  // legal-entity
  public get userLegalEntities(): Observable<any> {
    return this._userLegalEntities$;
  }

  public set userLegalEntities(v: any) {
    this.store.dispatch(StorageActions.setUserLegalEntities({ userLegalEntities: v }));
  }

  public get defaultLegalEntity(): Observable<any> {
    return this._defaultLegalEntity$;
  }

  public set defaultLegalEntity(v: any) {
    this.store.dispatch(StorageActions.setDefaultLegalEntity({ defaultLegalEntity: v }));
  }

  public get userRoles() {
    return this._userRoles$;
  }

  public set userRoles(v) {
    this.store.dispatch(StorageActions.setUserRoles({ userRoles: v }));
  }


  // my profile
  public get dateFormat() {
    return this._dateFormat$;
  }

  public set dateFormat(v) {
    this.store.dispatch(StorageActions.setDateFormat({ dateFormat: v }));
  }

  public get selectedLanguage() {
    return this._selectedLanguage$;
  }

  public set selectedLanguage(v) {
    this.store.dispatch(StorageActions.setSelectedLanguage({ selectedLanguage: v }));
  }


  // plp
  public get query() {
    return this._query$;
  }

  public set query(v) {
    this.store.dispatch(StorageActions.setQuery({ query: v }));
  }

  public resetQuery(): void {
    this.store.dispatch(StorageActions.setQuery({ query: null }));
  }

  // cart
  public get cartId() {
    return this._cartId$;
  }

  public set cartId(v) {
    this.store.dispatch(StorageActions.setCartId({ cartId: v }));
  }

  public get carts() {
    return this._carts$;
  }

  public set carts(v) {
    this.store.dispatch(StorageActions.setCarts({ carts: v }));
  }

  public get activeCart() {
    return this._activeCart$;
  }

  public set activeCart(v) {
    this.store.dispatch(StorageActions.setActiveCart({ activeCart: v }));
  }

  // checkout
  public get paramsCartGroupId() {
    return this._paramsCartGroupId$;
  }

  public set paramsCartGroupId(v) {
    this.store.dispatch(StorageActions.setParamsCartGroupId({ paramsCartGroupId: v }));
  }

  public get checkoutCartGroupId() {
    return this._checkoutCartGroupId$;
  }

  public set checkoutCartGroupId(v) {
    this.store.dispatch(StorageActions.setCheckoutCartGroupId({ checkoutCartGroupId: v }));
  }

  public get cartCode() {
    return this._cartCode$;
  }

  public set cartCode(v) {
    this.store.dispatch(StorageActions.setCartCode({ cartCode: v }));
  }

  public get secondaryCartCode() {
    return this._secondaryCartCode$;
  }

  public set secondaryCartCode(v) {
    this.store.dispatch(StorageActions.setSecondaryCartCode({ secondaryCartCode: v }));
  }

  // user-management
  public get userProfileData() {
    return this._userProfileData$;
  }

  public set userProfileData(v) {
    this.store.dispatch(StorageActions.setUserProfileData({ userProfileData: v }));
  }

  reset() {
    this.localStorageReset();
    this.sessionStorageReset();

    localStorage.clear();
    sessionStorage.clear();
  }

  localStorageReset() {
    this.store.dispatch(StorageActions.setMsalInfo({ msalInfo: null }));
    this.store.dispatch(StorageActions.setAccessToken({ accessToken: null }));
    this.store.dispatch(StorageActions.setRedirectUrl({ redirectUrl: null }));
    this.store.dispatch(StorageActions.setCurrency({ currency: null }));
    this.store.dispatch(StorageActions.setUserLegalEntities({ userLegalEntities: null }));
    this.store.dispatch(StorageActions.setDefaultLegalEntity({ defaultLegalEntity: null }));
    this.store.dispatch(StorageActions.setUserRoles({ userRoles: null }));
    this.store.dispatch(StorageActions.setCartId({ cartId: null }));
    this.store.dispatch(StorageActions.setCarts({ carts: null }));
    this.store.dispatch(StorageActions.setActiveCart({ activeCart: null }));
    this.store.dispatch(StorageActions.setQuery({ query: null }));
    this.store.dispatch(StorageActions.setUserProfileData({ userProfileData: null }));
    this.store.dispatch(StorageActions.setDateFormat({ dateFormat: null }));
    this.store.dispatch(StorageActions.setCartCode({ cartCode: null }));
    this.store.dispatch(StorageActions.setSecondaryCartCode({ secondaryCartCode: null }));
    this.store.dispatch(StorageActions.setParamsCartGroupId({ paramsCartGroupId: null }));
    this.store.dispatch(StorageActions.setCheckoutCartGroupId ({ checkoutCartGroupId: null }));
    localStorage.removeItem(AppConstants.LocalStorageKeys.bookmarkScenario);
  }

  sessionStorageReset() {
    // Reset session storage logic if needed
  }
  public resetCartCodes(): void {
    this.store.dispatch(StorageActions.setCartCode({ cartCode: null }));
    this.store.dispatch(StorageActions.setSecondaryCartCode({ secondaryCartCode: null }));
}
public resetSecondaryCartCode(): void { 
    this.store.dispatch(StorageActions.setSecondaryCartCode({ secondaryCartCode: null }));
}

public resetCartCode(): void {
    this.store.dispatch(StorageActions.setCartCode({ cartCode: null }));
}

resetSessionDetails() {
localStorage.removeItem(AppConstants.LocalStorageKeys.anonymous);
localStorage.removeItem(AppConstants.LocalStorageKeys.auth);
localStorage.removeItem(AppConstants.LocalStorageKeys.cart);
localStorage.removeItem(AppConstants.LocalStorageKeys.currency);
localStorage.removeItem(AppConstants.LocalStorageKeys.language);
localStorage.removeItem(AppConstants.LocalStorageKeys.msalInfo);
}
}
