import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthConfigService, AuthStorageService, AuthToken, GlobalMessageService, GlobalMessageType, HttpResponseStatus, UserIdService } from '@spartacus/core';
import { EMPTY, Observable, catchError, switchMap, throwError, tap, BehaviorSubject, take } from 'rxjs';
import { MSALSpaAuthService } from '../login-sso/msal-auth.service';
import { AuthenticationResult, SsoSilentRequest } from '@azure/msal-browser';
import { GlobalService } from '../global.service';
import { ApiService } from '../api.service';
import { AuthResponse } from '../../models/authResponse';
import { StorageService } from '../storage.service';
import { ERROR_STATUS_CODES } from '../../../SGRE-config/error-status-codes-config';
import { AppConstants } from '../../constants/app-constant';
import { MSALAuthenticationResponse } from '../../models/msalResponse';
import { StatePersistenceService } from '@spartacus/core';
import { selectDefaultLegalEntity, selectMsalInfo } from '../../../SGRE-shared/services/storage.state';
import { Store,select } from '@ngrx/store';
@Injectable()
export class CustomGlobalInterceptor implements HttpInterceptor {

  isSessionExpired: boolean;
  msalInfoData: any;

  constructor(
    private msalService: MsalService,
    private msalAuthService: MSALSpaAuthService,
    protected authStorageService: AuthStorageService,
    protected userIdService: UserIdService,
    private globalService: GlobalService,
    private apiService: ApiService,
    private storageService: StorageService,
    protected authConfigService: AuthConfigService,
    private globalMessageService: GlobalMessageService,
    private statePersistenceService: StatePersistenceService,
    private store: Store
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.pipe(select(selectDefaultLegalEntity)).pipe(
      take(1), // Get the first emitted value
      switchMap(legalEntity => {
        const clonedRequest = this.getClonedRequest(req, legalEntity);

        return (this.checkExpiryBeforeRequest())
          ? next.handle(clonedRequest)
          : next.handle(clonedRequest).pipe(
              catchError(err => this.handleError(req, next, err)),
          );
      })
    );
  }

  checkExpiryBeforeRequest(): boolean {
    let flag = false;
    if (this.globalService.isUserLoggedIn() && this.isOAuthTokenExpired() && !this.isSessionExpired) {
      this.isSessionExpired = flag = true;
      this.msalAuthService.coreLogout();
    }
    /** Updating the User interactivity to calculate Idle-Time */
    if (flag === false) {
      this.authStorageService.setItem('user_last_activity', new Date().getTime().toString());
    }
    return flag;
  }

  getClonedRequest(request: HttpRequest<any>, legalEntity: any): HttpRequest<any> {
    let modifiedRequest: HttpRequest<any> = request;

    const legalEntityVal = legalEntity ? legalEntity.uid : null; // Access uid safely
    const legaEntityObj = legalEntityVal ? { LegalEntity: legalEntityVal } : {};

    const anonymousFlag = request.url.includes('rest/v2/sgre/users/anonymous');
    const cmsFlag = request.url.includes('cms/pages');
    const legalEntityFlag = !anonymousFlag && cmsFlag && legalEntityVal;

    let paramsObj = request.params.has('curr') ? request.params.delete('curr') : request.params;

    modifiedRequest = modifiedRequest.clone({
      setHeaders: legalEntityFlag ? legaEntityObj : {},
      params: paramsObj
    });

    return modifiedRequest;
  }

  handleError(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
    if (error.status === HttpResponseStatus.UNAUTHORIZED) {
      if (this.isRefreshTokenRequired(request)) {
        return this.getOAuth(request, next);
      }
    }

    if (error.status === HttpResponseStatus.FORBIDDEN) {
      this.handleForbiddenErrors(request, next, error);
    }

    if (error.status === HttpResponseStatus.INTERNAL_SERVER_ERROR) {
      /*-- Incorrect Credentials during Login --*/
      if (request.url === this.authConfigService.getTokenEndpoint()) {
        this.msalAuthService.coreLogout();
        return EMPTY;
      }
    }

    if (error.status >= 400 && error.status <= 499 && (error.status !== HttpResponseStatus.UNAUTHORIZED && error.status !== HttpResponseStatus.FORBIDDEN)) {
      this.handleOtherErrors(request, next, error);
    }

    if (error.status > 500 && error.status <= 599) {
      this.handleOtherErrors(request, next, error);
    }

    /*-- Implement error-handling for this scenario --*/
    return throwError(() => {
      console.info(`Error was re-thrown by HTTP Interceptor...`, error)
      return error
    });
  }

  isRefreshTokenRequired(request: HttpRequest<any>): boolean {
    let flag = false;
    let refreshFlag = false;
    let urlObj = AppConstants?.endPointUrls;
    if (urlObj && Object.keys(urlObj).length > 0) {
      // urls should not be equal to OAuth/Revoke-endpoints
      refreshFlag = Object.keys(urlObj).every(url => urlObj[url] !== request.url)
    }
    let expiryFlag = this.isOAuthTokenExpired();
    flag = refreshFlag && !expiryFlag;

    return flag;
  }

  isOAuthTokenExpired(): boolean {
    let token: AuthToken;
    this.authStorageService.getToken().subscribe(data => token = data);

    const currentTime = new Date().getTime();
    const currentDate = new Date(currentTime);
    const lastUserInteractedTime = this.getLatestUserInteractionTime();
    const tokenIssuedTime = Number(token.access_token_stored_at);
    const tokeExpiryTime = Number(token.expires_at) * 1000;     // converting seconds into milli-seconds
    const tokenValidTime = new Date(tokenIssuedTime + tokeExpiryTime);
    const idleTimeLimit = 240 * 60 * 1000;                      // 4 Hours
    const userIdleTime = (currentTime - lastUserInteractedTime);

    let expiryFlag: boolean = ((currentDate >= tokenValidTime) && (userIdleTime >= idleTimeLimit));

    return expiryFlag;
  }

  getLatestUserInteractionTime(): number {
    let latestInteraction: number = 0;
    latestInteraction = Number(this.authStorageService.getItem('user_last_activity'));
    return latestInteraction;
  }

  handleForbiddenErrors(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse): void {
    let errorMessage = this.fetchErrorMessage(error);

    this.globalMessageService.add(
      errorMessage,
      GlobalMessageType.MSG_TYPE_ERROR
    );
  }

  handleOtherErrors(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse): void {
    let errorMessage = this.fetchErrorMessage(error);

    if (error.status === HttpResponseStatus.NOT_FOUND && !this.globalService.isUserLoggedIn()) {
      localStorage.setItem(AppConstants.LocalStorageKeys.bookmarkScenario, 'true');
    //   this.statePersistenceService.syncWithStorage({
    //     key: AppConstants.LocalStorageKeys.bookmarkScenario,
    //     state$: new BehaviorSubject('true'), 
    // });
    }

    if (ERROR_STATUS_CODES['WARNING'].some(obj => obj['ERROR_CODE'] === error.status)) {
      this.globalMessageService.add(
        errorMessage,
        GlobalMessageType.MSG_TYPE_WARNING
      );
    }

    if (ERROR_STATUS_CODES['ERROR'].some(obj => obj['ERROR_CODE'] === error.status)) {
      this.globalMessageService.add(
        errorMessage,
        GlobalMessageType.MSG_TYPE_ERROR
      );
    }
  }

  fetchErrorMessage(errorResponse: HttpErrorResponse): string {
    let message: string = null;

    if (errorResponse && errorResponse?.error['errors']?.length > 0) {
      let errorObj = errorResponse?.error['errors'];

      message = (errorObj?.at(0)?.message)
        ? errorObj.at(0).message
        : ((errorObj?.at(0)?.type)
          ? errorObj.at(0).type
          : '');
    } else if (errorResponse && errorResponse?.error?.message) {
      message = errorResponse?.error?.message;
    }

    return message;
  }

  getOAuth(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const { tokenUrl, params } = this.globalService.getOAuthDetails();

    return this.apiService.fetchOAuthToken(tokenUrl, params)
      .pipe(
        switchMap(data => this.handleOAuthResponse(request, next, data)),
        catchError(err => this.handleOAuthError(request, next, err)),
      );
  }

  handleOAuthResponse(request: HttpRequest<any>, next: HttpHandler, data: AuthResponse): Observable<HttpEvent<any>> {
    let tokenObj: AuthToken = {
      access_token: data.access_token,
      refresh_token: data.refresh_token,
      expires_at: data.expires_in.toString(),
      granted_scopes: new Array(data.scope),
      access_token_stored_at: new Date().getTime().toString(),
      token_type: data.token_type,
    };
    this.authStorageService.setToken(tokenObj);
    this.authStorageService.setItem('user_last_activity', new Date().getTime().toString());

    let clonedRequest = request.clone({
      setHeaders: {
        Authorization: `Bearer ${data.access_token}`
      }
    });

    return next.handle(clonedRequest);
  }

  handleOAuthError(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse): Observable<any> {
    if (error.status === HttpResponseStatus.UNAUTHORIZED) {
      return this.getAzureToken(request, next);
    }
    return throwError(() => error);
  }

  getAzureToken(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    this.globalService.azureTokenRefreshFlag = true;
    this.store.pipe(select(selectMsalInfo)).subscribe((msalInfo) => {
      this.msalInfoData = msalInfo;
    });
    let msalInfo: MSALAuthenticationResponse = this.msalInfoData;
    let scopes = msalInfo?.payload?.scopes;
    let loginHint = msalInfo?.payload?.account?.login_hint;

    const ssoSilentReq: SsoSilentRequest = { scopes, loginHint };

    return this.msalService.ssoSilent(ssoSilentReq)
      .pipe(
        switchMap((data: AuthenticationResult) => this.clonedOAuth(request, next, data)),
        catchError(err => {
          this.msalAuthService.coreLogout();
          return throwError(() => {
            console.log('error from Azure AD:', err);
            return `Error while trying to fetch refresh-token from Azure AD...`
          });
        }),
      )
  }

  // To Avoid infinite-loop of OAuth calls
  clonedOAuth(request: HttpRequest<any>, next: HttpHandler, msalResponse: AuthenticationResult): Observable<any> {
    const { tokenUrl, params } = this.globalService.updateMsalInfo(msalResponse);

    return this.apiService.fetchOAuthToken(tokenUrl, params)
      .pipe(
        switchMap(data => this.handleOAuthResponse(request, next, data)),
        tap((data) => this.globalService.azureTokenRefreshFlag = false),
        catchError(err => throwError(() => 'OAuth token failure. Please logout and login again to proceed..!')),
      );
  }

  handleAzureError() {
    // write pop-up scenario here
  }

  /* Reference for pop-up implementation. PrimeNG Dynamic Dialog
  import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
  import { AzureErrorComponent } from '../../../../SGRE-shared/utils/azure-error.component';
  providers: [DialogService]
  private dialogService: DialogService,
  ref: DynamicDialogRef | undefined;

  showDialogService() {
    this.ref = this.dialogService.open(AzureErrorComponent, {
      header: 'Select a Product',
      width: '50vw',
      contentStyle: { overflow: 'auto' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      }
    });

    this.ref.onClose.subscribe((data: any) => {
      console.log('close captured');
    });
  }
  */
}
