import { Injectable } from '@angular/core';
import { GlobalService } from '../../../SGRE-shared/services/global.service';
import { ApiService } from '../../../SGRE-shared/services/api.service';
import { BehaviorSubject, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccessRequestsService {

  private orderRequestListSubject = new BehaviorSubject<any>(null);
  data$ = this.orderRequestListSubject.asObservable();

  constructor(
    private globalService: GlobalService,
    private apiService: ApiService
  ) { }


  public getOrderRequestList() {
    let paramsObject = {
      currentPage: 0, fields: 'FULL', pageSize: 500
    }
    this.globalService.loadingSubject.next(true);
    this.apiService.getAccessRequestList(paramsObject)
      .pipe(
        map((data: any) => {
          return data?.legalEntityPendingRoles;
        })
      )
      .subscribe({
        next: (data) => {
          this.orderRequestListSubject.next(data);
          this.globalService.loadingSubject.next(false);
        }, error: (error) => {
          this.globalService.loadingSubject.next(false);
        }
      });
  }
}
