import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { selectDateFormat } from '../../SGRE-shared/services/storage.state';
import { Store,select } from '@ngrx/store';
@Pipe({
  name: 'formatDate',
  pure: false
})
export class FormatDatePipe implements PipeTransform {

  formatValue: any = 'MM/dd/yyyy'

  constructor(
    private datePipe: DatePipe,
    private store: Store
  ) { }

  transform(value: any, ...args: unknown[]): unknown {
    this.store.pipe(select(selectDateFormat)).subscribe((dateFormat) => {
      this.formatValue = dateFormat;
    });
    return this.datePipe.transform(value, this.formatValue)
  }

}
