import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-legal-entity-selector-header',
  templateUrl: './legal-entity-selector-header.component.html',
  styleUrl: './legal-entity-selector-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegalEntitySelectorHeaderComponent {
  countries: any[] | undefined;

  selectedCountry: string | undefined;
}
