import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductDetailsService } from '../../services/product-details-service';
import { Options } from '../../models/product-info';

@Component({
  selector: 'app-product-sum-price',
  templateUrl: './product-sum-price.component.html',
  styleUrl: './product-sum-price.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductSumPriceComponent implements OnInit {

  productDetails$: Observable<any>;
  productOption$: Observable<Options>;

  constructor(
    private productsService: ProductDetailsService
  ) { }

  ngOnInit(): void {
    this.productDetails$ = this.productsService.getOotbProduct();
    this.productOption$ = this.productsService.variant$;
  }
}