import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent implements OnInit {

  isLoading$: Observable<boolean>;

  constructor(public globalService: GlobalService) { }

  ngOnInit(): void {
    this.isLoading$ = this.globalService.loading$;
  }
}
