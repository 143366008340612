import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AppConstants } from '../../../../SGRE-shared/constants/app-constant';
import { MsalService } from '@azure/msal-angular';
import {
  catchError,
  switchMap,
  take,
} from 'rxjs/operators';
import {
  BehaviorSubject,
  Observable,
  of,
  Subject,
} from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  AuthActions,
  AuthConfigService,
  AuthRedirectService,
  AuthService,
  AuthStorageService,
  AuthToken,
  BaseSiteService,
  GlobalMessageService,
  GlobalMessageType,
  OCC_USER_ID_CURRENT,
  User,
  UserIdService,
} from '@spartacus/core';
import { Store } from '@ngrx/store';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { MSALAuthenticationResponse } from '../../../../SGRE-shared/models/msalResponse';

@Component({
  selector: 'app-sign-in-banner-header',
  templateUrl: './sign-in-banner-header.component.html',
  styleUrl: './sign-in-banner-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInBannerHeaderComponent implements OnInit, OnDestroy {

  loginDisplay = new BehaviorSubject(false);
  loader$ = new BehaviorSubject(false);
  msalResponse: MSALAuthenticationResponse;
  user$: Observable<User | undefined>;
  requestAccessUrl = AppConstants.routeUrls.requestAccess;
  protected showBanner$: Observable<boolean>;
  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private globalService: GlobalService,
    private msalService: MsalService,
    protected http: HttpClient,
    protected authConfigService: AuthConfigService,
    protected store: Store,
    protected authStorageService: AuthStorageService,
    protected userIdService: UserIdService,
    protected globalMessageService: GlobalMessageService,
    protected authRedirectService: AuthRedirectService,
    protected baseSiteService: BaseSiteService,
    private authService: AuthService,
    private userAccount: UserAccountFacade,
  ) { }

  ngOnInit() {
    this.showBanner$ = this.globalService.loginBanner$;
  }

  login() {
    this.globalService.checkSmartEdit()
      ? this.msalService.loginPopup()
      : this.msalService.loginRedirect();
  }

  /* OOTB relevant code start here. */
  setLoginDisplay() {
    this.loginDisplay.next(
      this.msalService.instance.getAllAccounts().length > 0
    );
    // 'auth.isUserLoggedIn()' returns boolean based on access_token availability.
    this.user$ = this.authService.isUserLoggedIn().pipe(
      switchMap((isUserLoggedIn) => {
        if (isUserLoggedIn) {
          /**
           * 'userAccount.get()' returns user_account details based on interface 'User'.
           * similar to msalResponse -> payload.account.
           */
          //
          return this.userAccount.get();
        } else {
          return of(undefined);
        }
      })
    );
  }

  loadTokenUsingCustomFlow(
    UID: string,
    baseSite: string,
    scope: string
  ): Observable<Partial<AuthToken> & { expires_in?: number }> {
    const url = this.authConfigService.getTokenEndpoint();
    const params = new HttpParams()
      .set('client_id', this.authConfigService.getClientId())
      .set('client_secret', this.authConfigService.getClientSecret())
      .set('grant_type', 'custom')
      .set('UID', encodeURIComponent(UID))
      .set('baseSite', encodeURIComponent(baseSite));

    return this.http
      .post<Partial<AuthToken> & { expires_in?: number }>(url, params)
      .pipe(catchError((error) => this.handleAuthError(error)));
  }

  handleAuthError(error: any): any {
    this.globalMessageService.add(
      error.message ? error.message : { key: 'httpHandlers.unknownIdentifier' },
      GlobalMessageType.MSG_TYPE_ERROR
    );
    this.setLoginDisplay();
    return of();
  }

  /**
   * Transform and store the token received from custom flow to library format and login user.
   *
   * @param token
   */
  loginWithToken(token: Partial<AuthToken> & { expires_in?: number }): void {
    let stream$ = of(true);
    stream$.pipe(take(1)).subscribe((canLogin) => {
      if (canLogin) {
        // Code mostly based on auth lib we use and the way it handles token properties
        this.setTokenData(token);

        // OCC specific code
        this.userIdService.setUserId(OCC_USER_ID_CURRENT);

        this.store.dispatch(new AuthActions.Login());

        // Remove any global errors and redirect user on successful login
        this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
        this.loader$.next(false);
        this.authRedirectService.redirect();
      }
    });
  }

  protected setTokenData(token: any): void {
    this.authStorageService.setItem('access_token', token.access_token);

    if (token.granted_scopes && Array.isArray(token.granted_scopes)) {
      this.authStorageService.setItem(
        'granted_scopes',
        JSON.stringify(token.granted_scopes)
      );
    }

    this.authStorageService.setItem('access_token_stored_at', '' + Date.now());

    if (token.expires_in) {
      const expiresInMilliseconds = token.expires_in * 1000;
      const now = new Date();
      const expiresAt = now.getTime() + expiresInMilliseconds;
      this.authStorageService.setItem('expires_at', '' + expiresAt);
    }

    if (token.refresh_token) {
      this.authStorageService.setItem('refresh_token', token.refresh_token);
    }
  }
  /* OOTB relevant code end here. */

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.globalService.clearMessagesOnDestroy();
  }
}
