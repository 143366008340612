<div class="row" *ngIf="deliveryInformation | async as deliveryInformation">
  <div class="col-md-12">
    <h2 class="font-weight-bold">{{"orderDetail.deliveryInformation"|cxTranslate}}</h2>
    <div class="delivery-info-container">
        <div class="form-group">
          <label for="" class="label-text">{{"orderDetail.shippingMethod"|cxTranslate}}</label>
          <p class="information-text" *ngIf="!deliveryInformation?.deliveryMode?.name">
            -
          </p>
          <p class="information-text" *ngIf="deliveryInformation?.deliveryMode?.name">
            {{ deliveryInformation?.deliveryMode?.name }}
          </p>
        </div>
        <div class="form-group">
          <label class="label-text">{{"orderDetail.incoterms"|cxTranslate}}</label>
          <p class="information-text" *ngIf="!deliveryInformation?.incoterm">-</p>
          <p class="information-text" *ngIf="deliveryInformation?.incoterm">{{ deliveryInformation?.incoterm }}</p>
        </div>
        <div class="form-group" *ngIf="deliveryInformation?.deliveryAddress else noAddress">
          <label for='' class="label-text">{{"orderDetail.shippingAddress"|cxTranslate}}</label>
          <div>
            <p class="information-text">
              {{ deliveryInformation?.deliveryAddress?.companyName }}
            </p>
            <p class="information-text">
              {{ deliveryInformation?.deliveryAddress?.line1 }}
            </p>
            <p class="information-text">
              {{ deliveryInformation?.deliveryAddress?.line2 }}
            </p>
            <!---->
            <p class="information-text">
              <span>{{ deliveryInformation?.deliveryAddress?.postalCode }},{{ deliveryInformation?.deliveryAddress?.town
              }}
            </span>
              <span>{{ deliveryInformation?.deliveryAddress?.region?.name }},
              </span>
              <!---->
            </p>
            <p class="information-text">
              {{ deliveryInformation?.deliveryAddress?.country?.name }}
            </p>
            <p class="information-text">
              {{ deliveryInformation?.deliveryAddress?.phone }}
            </p>
            <!---->
          </div>
        </div>
        <ng-template #noAddress>
          <label for="" class="label-text">{{"orderDetail.shippingAddress"|cxTranslate}}</label>
          <div>-</div>
        </ng-template>
        <div class="form-group">
          <label class="label-text">{{"orderDetail.billingAddress"|cxTranslate}}</label>
          <div *ngIf="deliveryInformation?.deliveryAddress?.billingAddress === false">
            <div *ngIf="!deliveryInformation?.legalEntity?.addresses?.addresses">
              -
            </div>
            <div *ngIf="getAddress(deliveryInformation?.legalEntity?.addresses?.addresses) as data">
              <p class="information-text"> {{ data?.companyName }}</p>
              <p class="information-text">{{ data?.line1 }}</p>
              <p class="information-text">{{ data?.line2 }}</p>
              <p class="information-text">
                <span>{{ data?.postalCode}},{{ data?.town}} </span>
                <span>{{ data?.region?.name }}, </span>
              </p>
              <p class="information-text">{{ data?.country?.name }}</p>
              <p class="information-text">{{ data?.phone }}</p>
            </div>
          </div>
          <div *ngIf="deliveryInformation?.deliveryAddress?.billingAddress === true else noBillingAddress">
            <div>
              <p class="information-text">
                {{ deliveryInformation?.deliveryAddress?.companyName }}
              </p>
              <p class="information-text">
                {{ deliveryInformation?.deliveryAddress?.line1 }}
              </p>
              <p class="information-text">
                {{ deliveryInformation?.deliveryAddress?.line2 }}
              </p>
              <!---->
              <p class="information-text">
                <span>{{ deliveryInformation?.deliveryAddress?.postalCode }},{{
                  deliveryInformation?.deliveryAddress?.town}}
                </span>
                <span>{{ deliveryInformation?.deliveryAddress?.region?.name }},
                </span>
                <!---->
              </p>
              <p class="information-text">
                {{ deliveryInformation?.deliveryAddress?.country?.name }}
              </p>
              <p class="information-text">
                {{ deliveryInformation?.deliveryAddress?.phone }}
              </p>
              <!---->
            </div>
          </div>

        </div>
        <ng-template #noBillingAddress>
          <div> - </div>
        </ng-template>
        <div class="form-group">
          <label for="" class="label-text">{{"orderDetail.contactPerson"|cxTranslate}}</label>
          <p class="information-text information-text-wrap" *ngIf="!deliveryInformation?.contactPersons">
          -
        </p>
          <p class="information-text information-text-wrap" *ngIf="deliveryInformation?.contactPersons?.at(0)?.name">
            {{ deliveryInformation?.contactPersons?.at(0)?.name }}
          </p>
        </div>
        <div class="form-group">
          <label class="label-text">{{"orderDetail.contactPhone"|cxTranslate}} </label>
          <p class="information-text information-text-wrap" *ngIf="!deliveryInformation?.contactPhone">
            -
          </p>
          <p class="information-text information-text-wrap" *ngIf="deliveryInformation?.contactPhone">
            {{ deliveryInformation?.contactPhone }}
          </p>
        </div>
    </div>
  </div>
</div>