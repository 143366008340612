import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header-faq',
  templateUrl: './header-faq.component.html',
  styleUrl: './header-faq.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderFaqComponent implements OnInit {

  public componentData$: Observable<any>;

  constructor(public component: CmsComponentData<any>) { }

  ngOnInit(): void {
    this.componentData$ = this.component.data$;
  }
}
