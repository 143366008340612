import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';

@Component({
  selector: 'app-contactus-paragraph',
  templateUrl: './contactus-paragraph.component.html',
  styleUrl: './contactus-paragraph.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ContactusParagraphComponent {

  public componentData: any;
  value: string = 'Hello Guys';

  constructor(public component: CmsComponentData<any>) { }

  ngOnInit(): void {
    this.component.data$.subscribe((data) => {
      this.componentData = data;
    });
  }

  onSubmit() {
    console.log("submit invoked");
  }
}
