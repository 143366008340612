import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, map, of } from 'rxjs';
import {
  isNotUndefined,
  Product,
  ProductScope,
  ProductService,
  RoutingService,
} from '@spartacus/core';
import { AppConstants } from '../../../SGRE-shared/constants/app-constant';
import { environment } from '../../../../environments/environment';
import { GlobalService } from '../../../SGRE-shared/services/global.service';
import { ApiService } from '../../../SGRE-shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class BulkOrderService {

  uplaod: any
  protected readonly DEFAULT_PRODUCT_SCOPE = ProductScope.DETAILS;
  public isNextSubject = new BehaviorSubject<number>(1);
  public isUploadFileNameSubject = new BehaviorSubject<string>("");
  public isPartNumber = new BehaviorSubject<any>({});
  public cartNameSubject = new BehaviorSubject<string>("");
  public isUploadArrayLengthSubject = new BehaviorSubject<number>(0);
  // public excelsArrayLength = new BehaviorSubject<number>(0);
  public excelDataSubject = new BehaviorSubject<any>([]);
  public productNameList = new BehaviorSubject<string[]>([]);
  public tableDataSubject = new BehaviorSubject<any>({});
  public tableDataSubjectUpdate = new BehaviorSubject<any>({});
  public countSubject = new BehaviorSubject<boolean>(false);
  isUploadLength$ = this.isUploadArrayLengthSubject.asObservable();
  isUploadFile$ = this.isUploadFileNameSubject.asObservable();
  isNext$ = this.isNextSubject.asObservable();
  productCodeSubject = new BehaviorSubject<string>(null);
  pCode$ = this.productCodeSubject.asObservable();
  // FirstTbale = [];
  // SecondTable :string;
  // productCodeSubject = new BehaviorSubject<string>(null);
  // pCode$ = this.productCodeSubject.asObservable();
  public userDetails = this.globalService.getUserId();
  public errorSubject = new BehaviorSubject<boolean>(false);
  error$ = this.errorSubject.asObservable();

  constructor(
    private readonly http: HttpClient,
    private routingService: RoutingService,
    private bulkService: ProductService,
    private globalService: GlobalService,
    private readonly apiService: ApiService,
  ) { }

  setNext(isSelected: number) {
    this.isNextSubject.next(isSelected)
  }

  setUploadFileName(fileName: string) {
    this.isUploadFileNameSubject.next(fileName)
  }

  getUploadFileArrayLength() {
    return this.isUploadArrayLengthSubject.getValue()
  }

  setUploadFileArrayLength(isUploadLength: number) {
    this.isUploadArrayLengthSubject.next(isUploadLength)
  };

  public getBulkOrder(): Observable<any> {
    let params = new HttpParams().append('fields', 'FULL');
    const url =
      environment.siteUrls.getBaseUrl +
      environment.siteUrls.getPrefix +
      environment.siteUrls.getSiteName +
      environment.siteUrls.getUser +
      this.userDetails + '/' +
      AppConstants.apiUrls.getBulkOrder
    // `${environment.siteUrls.getBaseUrl}${environment.siteUrls.getPrefix}${environment.siteUrls.getSiteName}${environment.siteUrls.getUser}${AppConstants.apiUrls.getBulkOrder}`;
    return this.http.post(url, this.productNameList.getValue(), { params: params });
  }

  public getCartBulkOrder(requestobj, cartValue): Observable<any> {
    return this.apiService.postBulkOrder(requestobj, cartValue).pipe(map(res => res));
  }
}



