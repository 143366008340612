import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, Observable, Subject, delay, map, takeUntil, timer } from 'rxjs';
import { GlobalService } from '../../services/global.service';
import { selectActiveCart, selectUserRoles } from '../../../SGRE-shared/services/storage.state';
import { Store, select } from '@ngrx/store';
@Component({
  selector: 'app-card-template',
  templateUrl: './card-template.component.html',
  styleUrl: './card-template.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardTemplateComponent implements OnInit, OnChanges, OnDestroy {
  @Input() productId: string;
  @Input() productName: string;
  @Input() productPrice: any;
  @Input() index: any;
  @Input() userLoggedIn: boolean = false;
  @Input() obsoleteFlag: boolean = false;
  @Input() quotationFlag: boolean = false;
  @Input() productNavigateUrl: string;
  @Input() productImageUrl: string;
  @Input() productQuantity: number = 1;
  @Input() qtyStep: number = 1;
  @Input() minimumQty: number = 1;
  @Input() maximumQty: number = 1000;
  @Input() currency: string;
  @Input() apiCallSuccess: boolean = false;
  @Output() addToCartClk = new EventEmitter<any>();

  public symbol: string;
  public price: string;
  public userRoles: any[];
  public Roles: any[];
  public isMaxLmtReached = false;
  isLoading = false;
  isdataloading = true;
  productqtyflag: boolean = false;
  pdqty: any;
  id: string;
  isLoading$: Observable<any>;
  apiSuccess = false;
  public maxQtyLimit: number;
  storeActiveCart: any;
  unsubscribe$ = new Subject<void>();

  constructor(
    private globalService: GlobalService,
    private changeRef: ChangeDetectorRef,
    private store: Store
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    this.isLoading$ = this.globalService.loading$.pipe(
      map((data) => {
        if (this.globalService.id.value && this.apiCallSuccess === true) {
          this.apiSuccess = this.apiCallSuccess;
          this.id = this.globalService.id.value;
        }
        timer(2000)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(data => {
            this.id = "";
            this.apiSuccess = false;
            this.apiCallSuccess = false;
            this.changeRef.markForCheck();
          })
        return data;
      })
    );
    this.maxQtyLimit = this.maximumQty;
    let availbleQty = this.getAvailableQty();
    this.pdqty = availbleQty > 0 ? this.qtyStep : this.minimumQty;
    this.minimumQty = availbleQty > 0 ? this.qtyStep : this.minimumQty;
    if (availbleQty) {
      this.maximumQty = this.maxQtyLimit - availbleQty;
      this.maximumLimit();
      this.isMaxLmtReached = availbleQty >= this.maxQtyLimit;
    }
  }

  maximumLimit() {
    let availbleQty = this.getAvailableQty();
    return availbleQty >= this.maxQtyLimit
  }

  checkAccess(): boolean {
    this.store.pipe(select(selectUserRoles)).subscribe((userRoles) => {
      this.Roles = userRoles;
    });
    this.userRoles = this.Roles;
    return this.userRoles?.includes('Requester');
  }

  checkRoleAccess(): boolean {
    this.store.pipe(select(selectUserRoles)).subscribe((userRoles) => {
      this.Roles = userRoles;
    });
    this.userRoles = this.Roles;
    return (
      this.userRoles?.includes('Requester') ||
      this.userRoles?.includes('Purchaser')
    );
  }

  getIndex() {
    const str = String(this.productPrice);
    const match = str?.match(/\d/);
    return match.index as number;
  }

  getPrice() {
    this.price = String(this.productPrice)?.substring(this.getIndex());
    return this.price;
  }

  checkMaximumLimit() {
    this.store.pipe(select(selectActiveCart)).subscribe((carts) => {
      this.storeActiveCart = carts;
    });
    let cartObj = this.storeActiveCart?.entries?.find(
      (item) => item.product.baseProduct === this.productId
    );
    let availbleQty = cartObj?.quantity ? cartObj.quantity : 0;
    if (availbleQty) {
      this.maximumQty = this.maxQtyLimit - availbleQty;
      this.maximumLimit();
    }
  }

  checkQty(val: number | string) {
    this.checkMaximumLimit();
  }

  addToCart(qty, productId) {
    this.apiSuccess = false;
    this.id = productId;
    this.globalService.id.next(productId);
    this.addToCartClk.emit({ index: this.index, quantity: qty });
  }

  getAvailableQty() {
    this.store.pipe(select(selectActiveCart)).subscribe((carts) => {
      this.storeActiveCart = carts;
    });
    let cardDetails = this.storeActiveCart;
    // Check if cardDetails and cardDetails.entries are valid arrays before using find()
    let cartObj = Array.isArray(cardDetails?.entries) ? cardDetails.entries.find(item => item.product.baseProduct === this.productId) : undefined;
    // Get quantity, default to 0 if cartObj is undefined
    let quantity = cartObj?.quantity || 0;
    return quantity;
  }

  updateQty(qty) {
    if (+qty === 0 || qty < this.minimumQty) {
      qty = this.minimumQty;
      this.pdqty = qty;
    }
    this.store.pipe(select(selectActiveCart)).subscribe((carts) => {
      this.storeActiveCart = carts;
    });
    let cartObj = this.storeActiveCart?.entries?.find(
      (item) => item.product.baseProduct === this.productId
    );
    let availbleQty = cartObj?.quantity ? cartObj.quantity : 0;
    if (qty > this.maxQtyLimit - availbleQty) {
      this.pdqty = availbleQty ? this.maxQtyLimit - availbleQty : this.maximumQty;
    } else {
      this.pdqty = this.checkQtyRange(qty, this.qtyStep);
    }
  }

  checkQtyRange(qty, step): string {
    let updatedQty;
    if (qty > this.maximumQty) {
      updatedQty = this.maximumQty;
    } else if (qty <= step) {
      updatedQty = step;
    } else if (qty % step != 0) {
      updatedQty = Math.round(qty / step) * step;
    } else {
      updatedQty = qty;
    }
    return updatedQty;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }
}
