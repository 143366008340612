import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
@Component({
  selector: 'app-profile-external-paragraph',
  templateUrl: './profile-external-paragraph.component.html',
  styleUrl: './profile-external-paragraph.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileExternalParagraphComponent {
  public componentData: any;
  constructor(public component: CmsComponentData<any>) { }
  ngOnInit(): void {
    // this.getUserRole();
    this.component.data$.subscribe((data) => {
      this.componentData = data;
    });
  }
}
