import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import {
  Column,
  ColumnType,
} from '../../../../SGRE-shared/models/tableConfiguration';
import { OrderDetailsService } from '../../services/orderDetails.service';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { Store,select } from '@ngrx/store';
import { selectUserRoles } from '../../../../SGRE-shared/services/storage.state';
@Component({
  selector: 'app-order-detailtable',
  templateUrl: './order-detailtable.component.html',
  styleUrl: './order-detailtable.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderDetailtableComponent implements OnInit, OnDestroy {

  public columns: Column[];
  public columns1: Column[];
  public columns2: Column[];
  public columnType: any[];
  orderDetails: Observable<any>;
  orderDetailsstatus: any = [];
  orderDetailsarray: any = [];
  orderDetailscancel: any = [];
  orderTablearray: any = [];
  status: any;
  cancel: Observable<any>;
  canceldata: any = [];
  cancelflag: boolean = false;
  cancelflagmain: boolean = true;
  commentobj: string;
  comment: string = '';
  updatePoup: boolean;
  entryNumber: any;
  private unsubscribe$ = new Subject<void>();
  displayRole: boolean;
  userRoles: any;

  constructor(
    private orderService: OrderDetailsService,
    private globalService: GlobalService,
    private store: Store
  ) { }

  ngOnInit() {
    this.orderService.getOrderDetails();
    this.orderDetails = this.orderService.OrderListData$
      .pipe(
        map((data) => {
          this.orderDetailsstatus = data;
          this.orderTablearray = data?.results;
          this.orderService.canceldata$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((x: any) => {
              this.canceldata = x;
              if (this.canceldata === true) {
                this.orderTablearray.map((x: any) => {
                  x['deleteoption'] = true;
                });
              }
            });
          this.createTabledata(this.orderDetailsstatus);
          this.globalService.loadingSubject.next(false);
          return data?.results;
        })
      );
  }

  createTabledata(data) {
    this.store.pipe(select(selectUserRoles)).subscribe((userRoles) => {
      this.userRoles = userRoles;
    });
    let userRoles = this.userRoles;
    this.displayRole = (userRoles) ? (userRoles.includes('Requester') && !userRoles.includes('Purchaser')) : false;
    this.status = data;
    let deleteobj = { label: 'Delete', name: 'delete', type: ColumnType.delete };
    let cancelobj = {
      label: 'Comment',
      name: 'comment',
      type: ColumnType.comment,
    };
    if (data.sgreOrderType === 'SALES_ORDER') {
      if (data.status != 'REJECTED' && data.status !='CANCELLED') {
        let columnData = [
          { label: 'Image', name: 'image', type: ColumnType.image2 },
          { label: 'Part Number', name: 'partnumber', type: ColumnType.text },
          { label: 'Product Name', name: 'productname', type: ColumnType.text },
          { label: 'Variant', name: 'varianttype', type: ColumnType.text },
          { label: 'ETA', name: 'updatedeta', type: ColumnType.eta, orgEta: true },

          {
            label: 'Unit Price',
            name: 'unit',
            type: ColumnType.price,
            align: 'left',
          },
          { label: 'Quantity', name: 'quantity', type: ColumnType.number },
          { label: 'Total', name: 'total', type: ColumnType.totalprice },
        ];
        if (
          (data.status === 'Not Yet Processed' &&
            data?.deliveryMode?.name != 'Turbine down' &&  !this.displayRole) ||
          (data.status === 'Not Yet Processed' &&
            data?.deliveryMode?.name === 'Turbine down' && !this.displayRole)
        ) {
          columnData.push(deleteobj);
        }
        if (data?.itemCancel === true) {
          columnData.push(cancelobj);
        }
        this.columns1 = columnData?.map((item) => new Column(item));
      } else {
        let columnData = [
          { label: 'Image', name: 'image', type: ColumnType.image2 },
          { label: 'Part Number', name: 'partnumber', type: ColumnType.text },
          { label: 'Product Name', name: 'productname', type: ColumnType.text },

          { label: 'Variant', name: 'varianttype', type: ColumnType.text },
          {
            label: 'Unit Price',
            name: 'unit',
            type: ColumnType.price,
            align: 'left',
          },
          { label: 'Quantity', name: 'quantity', type: ColumnType.number },
          { label: 'Total', name: 'total', type: ColumnType.totalprice },
        ];
        if (
          (data.status === 'Not Yet Processed' &&
            data?.deliveryMode?.name != 'Turbine down' && !this.displayRole) ||
          (data.status === 'Not Yet Processed' &&
            data?.deliveryMode?.name === 'Turbine down' && !this.displayRole) 
        ) {
          columnData.push(deleteobj);
        }
         if (data?.itemCancel === true) {
          columnData.push(cancelobj);
        }
        this.columns1 = columnData?.map((item) => new Column(item));
      }
    } else {
      if (data.status != 'REJECTED') {
        let columnData = [
          { label: 'Image', name: 'image', type: ColumnType.image },
          { label: 'Part Number', name: 'partnumber', type: ColumnType.text },
          { label: 'Product Name', name: 'productname', type: ColumnType.text },
          { label: 'Variant', name: 'varianttype', type: ColumnType.text },
          { label: 'Lead time', name: 'leadTime', type: ColumnType.text },
          {
            label: 'Unit Price',
            name: 'unit',
            type: ColumnType.price,
            align: 'left',
          },
          { label: 'Quantity', name: 'quantity', type: ColumnType.number },
          { label: 'Total', name: 'total', type: ColumnType.totalprice },
        ];
        if (
          (data.status === 'Not Yet Processed' &&
            data?.deliveryMode?.name != 'Turbine down'  && !this.displayRole) ||
          (data.status === 'Not Yet Processed' &&
            data?.deliveryMode?.name === 'Turbine down' && !this.displayRole)
        ) {
          columnData.push(deleteobj);
        }
        if (data?.itemCancel) {
          columnData.push(cancelobj);
        }
        this.columns1 = columnData?.map((item) => new Column(item));
      } else {
        let columnData = [
          { label: 'Image', name: 'image', type: ColumnType.image },
          { label: 'Part Number', name: 'partnumber', type: ColumnType.text },
          { label: 'Product Name', name: 'productname', type: ColumnType.text },
          { label: 'Variant', name: 'varianttype', type: ColumnType.text },
          { label: 'Lead time', name: 'leadTime', type: ColumnType.text },
          {
            label: 'Unit Price',
            name: 'unit',
            type: ColumnType.price,
            align: 'left',
          },
          { label: 'Quantity', name: 'quantity', type: ColumnType.number },
          { label: 'Total', name: 'total', type: ColumnType.totalprice },
        ];
        if (
          (data.status === 'Not Yet Processed' &&
            data?.deliveryMode?.name != 'Turbine down' && !this.displayRole) ||
          (data.status === 'Not Yet Processed' &&
            data?.deliveryMode?.name === 'Turbine down' && !this.displayRole)
        ) {
          columnData.push(deleteobj);
        }
        if (data.itemCancelComment != undefined) {
          columnData.push(cancelobj);
        }
        this.columns1 = columnData?.map((item) => new Column(item));
      }
    }
  }

  public deleteCart(entryNumber) {
    this.comment = '';
    this.updatePoup = true;
    this.entryNumber = entryNumber;
  }

  cancelOrder() {
    this.comment = (this.comment?.length > 0) ? this.comment : '';
    this.globalService.loadingSubject.next(true); 
    this.orderService.deleteOrderDetails(this.entryNumber, this.comment)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (success) => {
          this.orderService.getOrderDetails();
          this.createTabledata(this.orderDetailsstatus);
          this.globalService.loadingSubject.next(false);
        },
        error: (err) => {
          this.globalService.loadingSubject.next(false);
        }
      });
  }

  ngOnDestroy(): void {
    this.orderService.removeData();
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.globalService.clearMessagesOnDestroy();
  }
}
