<div *ngIf="orderInformation | async as orderInformation" class="component">
  <h2 class="font-weight-bold">{{"orderDetail.orderInformation"|cxTranslate}}</h2>
  <div class="row">
    <div class=" component col-xl-6">
      <div class="form-group">
        <label for="" class="label-text">{{"orderDetail.company"|cxTranslate}} </label>
        <p *ngIf="!orderInformation?.legalEntity?.name" class="information-text">-</p>
        <p *ngIf="orderInformation?.legalEntity?.name" class="information-text">{{orderInformation?.legalEntity?.name}}</p>
      </div>
      <div class="form-group">
        <label for="" class="label-text">{{"orderDetail.requesterName"|cxTranslate}}</label>
        <div>
          <p *ngIf="!orderInformation?.user?.name" class="information-text">-</p>
          <p *ngIf="orderInformation?.user?.name" class="information-text">{{orderInformation?.user?.name}}</p>
          <p id="requesterEmail" class="information-text">{{orderInformation?.user?.email}}</p>
        </div>
      </div>
      <div class="form-group">
        <label for="" class="label-text">{{"orderDetail.sgreSo"|cxTranslate}}</label>
        <p class="information-text" *ngIf ="!orderInformation?.sapSalesOrderNo">--</p>
        <p class="information-text" *ngIf ="orderInformation?.sapSalesOrderNo">{{orderInformation?.sapSalesOrderNo}}</p>
      </div>
      <div class="form-group">
        <label for="" class="label-text">{{"orderDetail.orderStatus"|cxTranslate}}</label>
        <p *ngIf="!orderInformation?.status" class="information-text">-</p>
        <p *ngIf="orderInformation?.status" class="information-text">{{orderInformation?.status}}</p>
      </div>
    </div>
    <div class="component col-xl-6">
      <div class="form-group">
        <label for="" class="label-text">{{"orderDetail.legalEntity"|cxTranslate}}</label>
        <p *ngIf="!orderInformation?.legalEntity?.name" class="information-text">-</p>
        <p *ngIf="orderInformation?.legalEntity?.name" class="information-text">
          {{orderInformation?.legalEntity?.name}}
        </p>
      </div>
      <div class="form-group">
        <label for="" class="label-text">{{"orderDetail.approverName"|cxTranslate}}</label>
        <p *ngIf="!orderInformation.approver" class="information-text-none">-</p>
        <p class="information-text">{{orderInformation.approver?.name}}</p>
        <p id="requesterEmail" class="information-text">{{orderInformation?.approver?.email}}</p>
      </div>
      <div class="form-group">
        <label for="" class="label-text">{{"orderDetail.companyPo"|cxTranslate}} </label>
        <p class="information-text">{{orderInformation?.customerPurchaseOrderNo}}</p>
      </div>
      <div class="form-group">
        <label for="" class="label-text">{{"orderDetail.orderDate"|cxTranslate}}</label>
        
        <p class="information-text">{{getUpdatedDate(orderInformation?.date)}}</p>
      </div>
    </div>
  </div>
</div>