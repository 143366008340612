import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { GlobalService } from '../../../SGRE-shared/services/global.service';
import { ApiService } from '../../../SGRE-shared/services/api.service';
import { environment } from '../../../../environments/environment';
import { Price } from '../../product-details-page/models/product-info';
import { ErrorType } from '../../../SGRE-config/error-config';
import { Store,select } from '@ngrx/store';
import { selectCartId } from '../../../SGRE-shared/services/storage.state';
import * as StorageActions from '../../../SGRE-shared/services/storage.actions';

@Injectable({
  providedIn: 'root',
})
export class CartService {

  public imageUrl: any = environment.siteUrls.getBackofficeUrl.slice(0, -1);

  // Get Products list for a cart
  private cartsListSubject = new BehaviorSubject<any>(null);
  data$ = this.cartsListSubject.asObservable();
  storeCartId: any;

  constructor(
    private globalService: GlobalService,
    private apiService: ApiService,
    private store: Store
  ) { }

  public getCartsList(cartCode?) {
    this.store.pipe(select(selectCartId)).subscribe((cartId) => {
      this.storeCartId = cartId;
    });
    let cartId = cartCode ? cartCode : this.storeCartId;
    if (cartId?.length > 0 && !isNaN(Number(cartId))) {
      let paramsObject = { fields: 'FULL' };
      this.globalService.loadingSubject.next(true);
      this.apiService.getCartsList(cartId, paramsObject)
        .pipe(
          map((data: any) => {
            return data?.entries?.map((item) => this.mapData(item, data));
          }),
          tap(() => this.globalService.clearMessagesOnDestroy(ErrorType.WARNING))
        )
        .subscribe({
          next: (data) => {
            this.cartsListSubject.next(data);
            this.store.dispatch(StorageActions.setActiveCart({ activeCart: data }));
            this.globalService.loadingSubject.next(false);
          },
          error: (error) => {
            this.globalService.loadingSubject.next(false);
          },
        });
    }
  }

  mapData(cartsData, data?) {
    cartsData['imageUrl'] = cartsData?.product?.images?.at(0)?.url
      ? this.imageUrl + cartsData?.product?.images?.at(0)?.url
      : '../../../../assets/images_and_icons/image1.jpg';
    cartsData['partNumber'] = cartsData?.product?.baseProduct;
    cartsData['productName'] = cartsData?.product?.baseOptions?.[0]?.selected?.name ?? '-';
    cartsData['variant'] = cartsData?.product?.code.charAt(cartsData.product.code?.length - 1);
    cartsData['isQuotation'] = cartsData?.isQuotation
      ? cartsData.isQuotation
      : false;
    cartsData['leadTime'] = !cartsData['isQuotation'] ? cartsData?.product?.stock?.estimatedLeadTime
      ? cartsData?.product?.stock?.estimatedLeadTime + ' Days'
      : null : '-';
    cartsData['unitPrice'] = !cartsData['isQuotation'] ? cartsData?.basePrice?.value : '-';
    cartsData['salesUnit'] = '';
    cartsData['total'] = !cartsData['isQuotation'] ? cartsData?.totalPrice?.value : '-';
    cartsData['currency'] = !cartsData['isQuotation'] ? cartsData?.totalPrice?.currencyIso : '-';
    cartsData['navigateUrl'] = this.globalService.getProductUrl(cartsData?.product?.url);
    cartsData['minQuantity'] = this.getUpdatedMinQty(cartsData?.product?.minQuantity, cartsData?.product?.interval);
    cartsData['maxQuantity'] = cartsData?.product?.maxQuantity > 0 ? cartsData?.product?.maxQuantity : 1000;
    cartsData['step'] = cartsData?.product?.interval > 0 ? cartsData?.product?.interval : 1;
    cartsData['isChecked'] = true;
    cartsData['statusRequester'] = cartsData?.isQuotation
      ? cartsData.isQuotation
      : false;
    cartsData['statusPurchaser'] = false;
    cartsData['status'] = 'edit';
    if (data) {
      cartsData['shippingFee'] = data?.shippingFee?.value
      cartsData['transportFee'] = data?.transportFee?.value
      cartsData['totalTax'] = data?.totalTax?.value
      cartsData['totalPriceWithTax'] = data?.totalPriceWithTax?.value
    }

    return cartsData;
  }

  formatPrice(data: Price): string | null {
    return this.globalService.getFormattedPrice(data);
  }

  getUpdatedMinQty(minQuantity, interval) {
    return this.globalService.getUpdatedMinQty(minQuantity, interval);
  }

  public updateQty(entryNumber, qty, specificCartCode?): Observable<any> {
    if (!specificCartCode) {
      this.store.pipe(select(selectCartId)).subscribe((cartId) => {
        this.storeCartId = cartId;
      });
      specificCartCode = this.storeCartId
    }
    return this.apiService.updateCart(specificCartCode, entryNumber, {
      quantity: qty,
    });
  }

  public postToCheckout(cartId: string): Observable<any> {
    let paramsObject = {
      fields: "FULL"
    }
    return this.apiService.postToCheckout(cartId, paramsObject);
  }

  public deleteCart(entryNumber, cartCode?): Observable<any> {
    this.store.pipe(select(selectCartId)).subscribe((cartId) => {
      this.storeCartId = cartId;
    });
    if (!cartCode) { cartCode = this.storeCartId }
    return this.apiService.deleteCart(cartCode, entryNumber);
  }
}