import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from '../../../../SGRE-shared/constants/app-constant';

@Component({
  selector: 'app-invite-user-button',
  templateUrl: './invite-user-button.component.html',
  styleUrl: './invite-user-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InviteUserButtonComponent {
  constructor(private router: Router) {}
  NavigateInviteusers() {
    this.router.navigate([AppConstants.routeUrls.inviteUser]);
  }
}
