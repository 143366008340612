import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../../../SGRE-shared/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class usersService {

  private dataSubject = new BehaviorSubject<any>(null);
  data$ = this.dataSubject.asObservable();

  public filteredData = [];

  statuscode: string;
  role: string = '';

  constructor(private readonly apiService: ApiService) { }

  userssgredetails(paramsObject) {
    return this.apiService.userssgredetails(paramsObject, {});
  }

  getlegalentities(paramsObject) {
    return this.apiService.getlegalentities(paramsObject, {});
  }
}