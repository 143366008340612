import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageSlotModule } from '@spartacus/storefront';

import { HeaderModule } from './header/header.module';
import { ContactUsModule } from './contactUs/contactus.module';
import { BreadcrumbModule } from './breadcrumb/breadcrumb.module';
import { FooterModule } from './footer/footer.module';
import { HomepageModule } from './homepage/homepage.module';
import { ProductListPageModule } from './product-list-page/product-list-page.module';
import { ProductDetailsPageModule } from './product-details-page/product-details-page.module';
import { MyProfileModule } from './my-profile/my-profile.module';
import { InviteUserModule } from './invite-user/invite-user.module';
import { BulkOrderModule } from './bulk-order-page/bulk-order-module';
import { SaveCartModule } from './save-cart/save-cart.module';
import { CartModule } from './cart/cart.module';
import { UsersModule } from './Users/Users.module';
import { CheckoutPageModule } from './checkout/checkout.module';
import { FaqModule } from './faq/faq.module';
import { OrderDetailModule } from './order-details/orderDetails.module';
import { OrderHistoryModule } from './order-history/order-history.module';
import { RequestAccessModule } from '../SGRE-custom/request-access/request-access.module';
import { USerProfileModule } from './user-profile/user-profile.module';
import { AccessRequestModule } from '../SGRE-custom/access-requests/access-request.module';
import { OrderRequestsModule } from './order-requests/order-requests.module';
import { PrivacyPolicyModule } from './privacy-policy/privacy-policy.module';
import { CookiePolicyModule } from './cookie-policy/cookie-policy/cookie-policy.module';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    PageSlotModule,
    HomepageModule,
    HeaderModule,
    BreadcrumbModule,
    ContactUsModule,
    ProductListPageModule,
    ProductDetailsPageModule,
    PrivacyPolicyModule,
    CookiePolicyModule,
    FooterModule,
    FaqModule,
    MyProfileModule,
    InviteUserModule,
    BulkOrderModule,
    SaveCartModule,
    CartModule,
    UsersModule,
    RequestAccessModule,
    OrderHistoryModule,
    OrderDetailModule,
    CheckoutPageModule,
    USerProfileModule,
    AccessRequestModule,
    OrderRequestsModule
  ]
})
export class SGRECustomModules { }