import { createAction, props } from '@ngrx/store';

export const setMsalInfo = createAction(
  '[Storage] Set MSAL Info',
  props<{ msalInfo: any }>()
);

export const setAccessToken = createAction(
  '[Storage] Set Access Token',
  props<{ accessToken: any }>()
);

export const setRedirectUrl = createAction(
  '[Storage] Set Redirect URL',
  props<{ redirectUrl: any }>()
);

export const setCurrency = createAction(
  '[Storage] Set Currency',
  props<{ currency: any }>()
);

export const setUserLegalEntities = createAction(
  '[Storage] Set User Legal Entities',
  props<{ userLegalEntities: any }>()
);

export const setDefaultLegalEntity = createAction(
  '[Storage] Set Default Legal Entity',
  props<{ defaultLegalEntity: any }>()
);

export const setUserRoles = createAction(
  '[Storage] Set User Roles',
  props<{ userRoles: any }>()
);

export const setDateFormat = createAction(
  '[Storage] Set Date Format',
  props<{ dateFormat: any }>()
);

export const setSelectedLanguage = createAction(
  '[Storage] Set Selected Language',
  props<{ selectedLanguage: any }>()
);

export const setQuery = createAction(
  '[Storage] Set Query',
  props<{ query: any }>()
);

export const setCartId = createAction(
  '[Storage] Set Cart ID',
  props<{ cartId: any }>()
);

export const setCarts = createAction(
  '[Storage] Set Carts',
  props<{ carts: any }>()
);

export const setActiveCart = createAction(
  '[Storage] Set Active Cart',
  props<{ activeCart: any }>()
);

export const setParamsCartGroupId = createAction(
  '[Storage] Set Params Cart Group ID',
  props<{ paramsCartGroupId: any }>()
);

export const setCheckoutCartGroupId = createAction(
  '[Storage] Set Checkout Cart Group ID',
  props<{ checkoutCartGroupId: any }>()
);

export const setCartCode = createAction(
  '[Storage] Set Cart Code',
  props<{ cartCode: any }>()
);

export const setSecondaryCartCode = createAction(
  '[Storage] Set Secondary Cart Code',
  props<{ secondaryCartCode: any }>()
);

export const setUserProfileData = createAction(
  '[Storage] Set User Profile Data',
  props<{ userProfileData: any }>()
);

export const setCartUserData = createAction(
  '[Storage] Set User Profile Data',
  props<{ cartUserData: any }>()
);
