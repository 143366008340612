import { Pipe, PipeTransform } from '@angular/core';
import { FacetValue } from '@spartacus/core';

@Pipe({
  name: 'categoryToggleFilter'
})
export class CategoryToggleFilterPipe implements PipeTransform {

  transform(data: FacetValue[], categoryToggle = false): FacetValue[] {
    if (!data || data.length === 0) {
      return [];
    }
    return categoryToggle
      ? data
      : data.slice(0, 8);
  }
}
