import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable, Subscription, forkJoin, map, switchMap } from 'rxjs';
import { FaqService } from '../../../../SGRE-custom/faq/services/faq.service';
import { CmsNavigationComponent, CmsNavigationNode } from '@spartacus/core';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { Link, LinkDropdownOptions, LinkValues } from '../../../models/CmsLinks';

@Component({
  selector: 'app-faq-navigation',
  templateUrl: './faq-navigation.component.html',
  styleUrl: './faq-navigation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqNavigationComponent {

  apiSubscription: Subscription;
  navigationNodes: CmsNavigationNode[] = [];
  regularNodes: CmsNavigationNode[] = [];
  nestedNodes: CmsNavigationNode[] = [];
  links$?: Observable<any>
  regularLinks: any[] = [];
  nestedLinks: any[] = [];

  constructor(
    private component: CmsComponentData<CmsNavigationComponent>,
    private faqService: FaqService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.links$ = this.component.data$
      .pipe(
        map(data => {
          let arr = [];
          if (data?.uid) {
            this.navigationNodes = data.navigationNode?.children;
            if (this.navigationNodes?.length > 0) {
              this.navigationNodes.forEach((node: CmsNavigationNode) => {
                if (node?.entries?.at(0)?.itemId) {
                  this.regularNodes.push(node);
                  arr.push(node?.entries?.at(0)?.itemId);
                } else if (this.checkNodeWithChildren(node)) {
                  this.nestedNodes.push(node);
                  node.children.forEach((nestNode: CmsNavigationNode) => {
                    arr.push(nestNode?.entries?.at(0)?.itemId)
                  });
                }
              });
            }
          }
          return arr;
        }),
        switchMap(itemIds =>
          forkJoin([
            this.faqService.getLinksData(itemIds.join(','))
              .pipe(
                map((linksData: any) => {
                  let linksArr: Link[] = [];
                  if (linksData?.component?.length > 0) {
                    linksArr = this.getLinks(linksData, this.navigationNodes, this.nestedNodes);
                  }
                  return linksArr;
                })
              )
          ]).pipe(map(res => res.at(0)))
        )
      )
  }

  getLinks(apiData: any, navNodes: CmsNavigationNode[], nestedNodes: CmsNavigationNode[]): Link[] {
    let linkArr: Link[] = [];
    for (const linkObj of apiData.component) {
      if (navNodes.find(node => node?.entries?.at(0)?.itemId === linkObj.uid)) {
        const nodeObj = navNodes.find(node => node?.entries?.at(0)?.itemId === linkObj.uid);
        if (nodeObj) {
          let linkValueObj: LinkValues = {
            name: nodeObj.title,
            url: linkObj.url
          }
          let obj: Link = {
            type: 'text',
            value: linkValueObj
          }
          linkArr.push(obj);
        }
      } else if (nestedNodes?.length > 0) {
        let nodeObj = nestedNodes.find(nodeObj => nodeObj.children?.some(nestNode => nestNode?.entries?.at(0)?.itemId === linkObj.uid))
        if (nodeObj && nodeObj.children) {
          let optionsArr: any[] = [];
          const index = linkArr.findIndex(lObj => lObj.dropdownLabel === nodeObj.title);
          let childNode = nodeObj.children.find(node => node?.entries?.at(0)?.itemId === linkObj.uid);
          if (childNode) {
            if (index !== -1) {
              let drpDwnValueObj: LinkDropdownOptions = {
                label: childNode.title,
                value: childNode.title,
                url: linkObj.url
              }

              linkArr[index].dropdownOptions.push(drpDwnValueObj);
            } else {
              let drpDwnValueObj: LinkDropdownOptions = {
                label: childNode.title,
                value: childNode.title,
                url: linkObj.url
              }
              optionsArr.push(drpDwnValueObj);

              let obj: Link = {
                type: 'dropdown',
                dropdownLabel: nodeObj.title,
                dropdownOptions: optionsArr
              }
              linkArr.push(obj);
            }
          }
        }
      }
    }
    return linkArr;
  }

  checkNodeWithChildren(node: CmsNavigationNode): boolean {
    let flag = false;
    flag = node?.children?.length > 0 && node.children.some(nestNode => nestNode?.entries?.at(0)?.itemId.length > 0);
    return flag;
  }

  identify(index, item) {
    return index;
  }

  navigateToLinks(event: DropdownChangeEvent) {
    this.router.navigate([event.value.url]);
  }
}
