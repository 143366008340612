<div class="component">
   <h2>{{"homePageHeader.categories"|cxTranslate}}</h2>
   <div class="row">
      <div class="col-12">
         <div class="row" *ngIf="categoryList$ | async as categories">
            <div class="col-md-6 col-lg-4 col-xl-3 mb-3 align-items-stretch" *ngFor="let ctgry of categories">
               <div style="background-color: #321850;" class="product-card d-flex flex-column justfiy-content-end p-3 h-100">
                  <a (click)="categoryClick(ctgry.code)" style="background-color: #321850; text-align: center;"
                     [ngClass]="{'mb-3': !(ctgry?.image)}">
                     <img style="height:100px; text-align: center;" src="{{imageUrl}}{{ctgry?.image?.url}}"
                        class="product-card-image p-2" alt="Product {{ctgry?.name}}">
                  </a>
                  <div class="my-2">
                     <div style="text-align: center; color: var(--white);" class="d-flex flex-column p-0">
                        <a class="product-name color-white category-name" (click)="categoryClick(ctgry.code)">
                           {{ctgry.name}}</a>
                     </div>
                  </div>
               </div>
            </div>
            <div class="btn viewAllproductsBtnContainer">
               <div class="pt-1 ml-1 mr-2">
                  <div style="text-align: center; color: #321850;" class="d-flex flex-column p-0">
                     <a (click)="categoryClick()" class="product-name color-primary viewAllProductsBtn">
                        {{"homePageHeader.viewAllProducts"|cxTranslate}}
                     </a>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>