import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageSlotModule } from '@spartacus/storefront';
import { ConfigModule, I18nModule } from '@spartacus/core';
import { PrimengModule } from '../primeng/primeng.module';
import { FormsModule } from '@angular/forms';
import { SGRESharedModule } from '../../SGRE-shared/SGRE-shared.module';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
  TranslateStore,
} from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { HttpBackend } from '@angular/common/http';

import { HomepageCarouselSection1Component } from './components/homepage-carousel-section1/homepage-carousel-section1.component';
import { HomepageTopProductsSection2Component } from './components/homepage-top-products-section2/homepage-top-products-section2.component';
import { HomepageCategorySection3Component } from './components/homepage-category-section3/homepage-category-section3.component';
import { HomepageVideoSection4Component } from './components/homepage-video-section4/homepage-video-section4.component';
import { HomepageSection5LinksComponent } from './components/homepage-section5-links/homepage-section5-links.component';

export function HttpLoaderFactory(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, ['/assets/i18n/core/', '/assets/i18n/vendors/']);
}
@NgModule({
  declarations: [
    HomepageCarouselSection1Component,
    HomepageTopProductsSection2Component,
    HomepageCategorySection3Component,
    HomepageVideoSection4Component,
    HomepageSection5LinksComponent
  ],
  imports: [
    CommonModule,
    PageSlotModule,
    PrimengModule,
    FormsModule,
    SGRESharedModule,
    ConfigModule.withConfig({
      cmsComponents: {
        RotatingImagesComponent: {
          component: HomepageCarouselSection1Component,
        },
        TopFeaturedProductsFlexComponent: {
          component: HomepageTopProductsSection2Component,
        },
        HomepageCategoryComponent: {
          component: HomepageCategorySection3Component,
        },
        VideoComponent: {
          component: HomepageVideoSection4Component,
        },
        SignInAndRequestAccessFlexComponent: {
          component: HomepageSection5LinksComponent,
        },
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    I18nModule
  ],
  providers: [
    TranslateService,
    { provide: TranslateStore }
  ]
})
export class HomepageModule { }