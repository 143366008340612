import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { BulkOrderService } from '../../../../SGRE-custom/bulk-order-page/services/bulk-order-service';
import { StorageService } from '../../../services/storage.service';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsParagraphComponent } from '@spartacus/core';
import { selectUserRoles } from '../../../../SGRE-shared/services/storage.state';
import { Store,select } from '@ngrx/store';
@Component({
  selector: 'app-bulkorder-product-notfound-paragraph',
  templateUrl: './bulkorder-product-notfound-paragraph.component.html',
  styleUrl: './bulkorder-product-notfound-paragraph.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkorderProductNotfoundParagraphComponent {
  currentpage$: Observable<number>
  public userRoles: any[];
  @Input() data: Observable<CmsParagraphComponent>;
  role: string;
  productLength: number = 0;
  foundProductLength: boolean = false;
  public componentData: any;

  constructor(public bulkservice: BulkOrderService, public component: CmsComponentData<any>, public changeRef: ChangeDetectorRef, public storageService: StorageService,
    private cdRef: ChangeDetectorRef, private store: Store
  ) { }
  ngOnInit(): void {
    this.currentpage$ = this.bulkservice.isNext$;
    this.getUserRole();
    this.getData();
    this.component.data$.subscribe((data) => {
      this.componentData = data;
    });
  }
  getUserRole() {
    this.store.pipe(select(selectUserRoles)).subscribe((userRoles) => {
      this.userRoles = userRoles;
    });
    let userRole: any = ''
    userRole = this.userRoles;
    if (userRole?.includes("Requester") && !userRole?.includes("Purchaser")) {
      this.role = 'Requester';
    }
    else if (userRole?.includes("Purchaser") && !userRole?.includes("Requester")) {
      this.role = 'Purchaser';

    }
    else if (userRole?.includes("Purchaser") && userRole?.includes("Requester")) {
      this.role = 'Purchaser&Requester'
    }


  }
  getData() {
    this.bulkservice.tableDataSubject.subscribe((req) => {
      const data = req
      if (Object.keys(data).length > 0) {
        this.productLength = data?.notFoundProductCodes?.length;
        this.foundProductLength = data?.foundProductCodes?.length === 0 ? true : false
        this.cdRef.markForCheck();
      }
    })
    this.changeRef.markForCheck();
  }
}
