import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from '../../../services/storage.service';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsParagraphComponent } from '@spartacus/core';
import { selectUserRoles } from '../../../../SGRE-shared/services/storage.state';
import { Store,select } from '@ngrx/store';
@Component({
  selector: 'app-profile-paragraph',
  templateUrl: './profile-paragraph.component.html',
  styleUrl: './profile-paragraph.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileParagraphComponent {
  public userRoles: any[];
  @Input() data: Observable<CmsParagraphComponent>;
  role: string;
  public componentData: any;
  constructor(
    public storageService: StorageService,
    public component: CmsComponentData<any>,
    private store:Store
  ) {}
  ngOnInit(): void {
    this.component.data$.subscribe((data) => {
      this.componentData = data;
    });
    this.getUserRole();
  }

  getUserRole() {
    this.store.pipe(select(selectUserRoles)).subscribe((userRoles) => {
      this.userRoles = userRoles;
    });
    let userRole: any = '';
    userRole = this.userRoles;
    if (userRole?.includes('Customer Admin')) {
      this.role = 'Customer Admin';
    } else if (userRole?.includes('SGRE Admin')) {
      this.role = 'SGRE Admin';
    } else if (userRole?.includes('Sales Rep')) {
      this.role = 'Sales Rep';
    }
  }
}
