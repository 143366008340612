import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CmsComponentData, NavigationNode, NavigationService } from '@spartacus/storefront';
import { AuthService, CmsNavigationComponent } from '@spartacus/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-footer-navigation',
  templateUrl: './footer-navigation.component.html',
  styleUrl: './footer-navigation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterNavigationComponent implements OnInit {
 
  data$?: Observable<NavigationNode>;
  isLoggedIn$: Observable<boolean>;
 
  constructor(
    private component: CmsComponentData<CmsNavigationComponent>,
    private navigationService: NavigationService,
    private authService: AuthService
  ) { }
 
  ngOnInit(): void {
    this.isLoggedIn$ = this.authService.isUserLoggedIn();
    this.data$ = this.navigationService.getNavigationNode(this.component.data$);
  }
}

