import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ProductListService } from '../../services/product-list.service';
import { Observable, Subject, takeUntil, tap, map } from 'rxjs';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { environment } from '../../../../../environments/environment';
import { Store, select } from '@ngrx/store';
import { selectCartId, selectQuery } from '../../../../SGRE-shared/services/storage.state';
import * as StorageActions from '../../../../SGRE-shared/services/storage.actions';
import { ProductSearchPage, ProductSearchService, SearchConfig, RoutingService, OCC_CART_ID_CURRENT, Images, Product, Image, PaginationModel } from '@spartacus/core';
import { ProductListComponentService } from '@spartacus/storefront';
import { MultiCartSelectors } from '@spartacus/cart/base/core';

@Component({
  selector: 'app-product-grid',
  templateUrl: './product-grid.component.html',
  styleUrl: './product-grid.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductGridComponent implements OnInit, OnDestroy {

  protected productsList: Observable<any>;
  protected productsListData: any[];
  public userLoggedIn: Observable<any>;
  public imageUrl: any = environment.siteUrls.getBackofficeUrl.slice(0, -1);
  private unsubscribe$ = new Subject<void>();
  public requestObject;
  public productId: string;
  apiCallSuccess: boolean = false;
  userQueryData: any;
  storeCartId: any;

  productsList$: Observable<ProductSearchPage>;
  pagination$: Observable<PaginationModel>;
  public first: number;
  public rows: number;
  public totalRecords: number;

  constructor(
    private productsService: ProductListService,
    private globalService: GlobalService,
    private storageService: StorageService,
    private store: Store,
    private productSearchService: ProductSearchService,
  ) { }

  ngOnInit(): void {
    this.userLoggedIn = this.globalService.loginBanner$;
    // this.getProductsList();
    this.globalService.recreate(false);
    this.handleSubscriptions();
    this.store.pipe(
      select(MultiCartSelectors.getCartIdByTypeFactory(OCC_CART_ID_CURRENT))
    )

    this.initializePageLoad();
    this._getProductsList();
  }

  /** -- OOTB Implementation -- */
  initializePageLoad() {
    this.productsService._search();
  }

  _getProductsList() {
    this.productsList$ = this.productSearchService.getResults()
    this.productsList$.subscribe(data => console.log('data', data));
      // .pipe(
      //   tap(data => {
      //     if (data?.pagination) {
      //       let paginationData: PaginationModel = data.pagination;
      //       this.first = paginationData.currentPage;
      //       this.rows = paginationData.pageSize;
      //       this.totalRecords = paginationData.totalResults;
      //     }
      //   })
      // );

    // this.pagination$ = this.productSearchService.getResults()
    // .pipe(
    //   map(data => data.pagination)
    // )
  }

  paginationChange(event: any) {
    this.productsService._onPageChange(event);
  }

  /** -- Previous Implementation -- */
  getProductsList() {
    this.requestObject = { currentPage: 0, pageSize: 30, fields: 'FULL' };
    let queryVal: string;
    this.store.pipe(select(selectQuery)).subscribe((queryData) => {
      this.userQueryData = queryData;
    });
    if (this.userQueryData) {
      queryVal = this.userQueryData;
      this.storageService.resetQuery();
      this.requestObject['query'] = queryVal;
    }
    // Reset Filters & Search
    this.productsService.currentPage = 0;
    this.productsService.rows = 30;
    this.productsService.getProductsList(this.requestObject);
    this.productsList = this.productsService.productsList$.pipe(
      tap(data => {
        this.productsListData = data?.products;
        this.totalRecords = data?.pagination?.totalResults
      }),
      takeUntil(this.unsubscribe$)
    );
  }

  handleSubscriptions() {
    // on Mini-cart GET/UPDATE actions
    this.globalService.productSearch$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data) { this.getProductsList(); }
      });

    // on LE header-dropdown/PSP/PLP-filter change actions
    this.productsService.pagination$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        if (data) { this.first = 0; }
      });
  }

  getProductUrl(urlVal: string): string {
    return this.globalService.getProductUrl(urlVal);
  }

  getImageUrl(imageVal: unknown): string {
    let imgArr = imageVal as Image[];
    return (imgArr?.length > 0 && imgArr?.at(0)?.url)
      ? environment.siteUrls.getBackofficeUrl + imgArr.at(0).url
      : '../../../../assets/images_and_icons/image1.jpg';
  }

  getUpdatedMinQty(minQuantity, interval): number {
    return this.globalService.getUpdatedMinQty(minQuantity, interval);
  }

  onPageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;
    this.productsService.currentPage = event.page;
    this.productsService.rows = event.rows;
    this.requestObject = {
      currentPage: event.page,
      pageSize: this.rows,
      query: ''
    };
    if (this.productsService.searchQuery) {
      this.requestObject.query += this.productsService.searchQuery;
    }
    if (this.productsService.filterQuery) {
      this.requestObject.query += this.productsService.filterQuery;
    }
    this.productsService.getProductsList(this.requestObject);
  }

  addToCart(event) {
    let index = event.index;
    let paramsObject = {
      code: this.productsListData[index]?.code,
      quantity: event.quantity,
    };
    let requestObject = {
      product: { code: this.productsListData[index]?.code, },
      quantity: event.quantity,
    };
    this.store.pipe(select(selectCartId)).subscribe((cartId) => {
      this.storeCartId = cartId;
    });
    let cartId = this.storeCartId;
    this.globalService.addToCart(cartId, paramsObject, requestObject)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (success: any) => {
          this.apiCallSuccess = true;
          this.globalService.getCartsListByLegalEntity(undefined, false);
          this.requestObject = {
            currentPage: this.productsService.currentPage ? this.productsService.currentPage : 0,
            pageSize: this.productsService.rows ? this.productsService.rows : 30,
            query: ''
          };
          if (this.productsService.searchQuery) {
            this.requestObject.query += this.productsService.searchQuery;
          }
          if (this.productsService.filterQuery) {
            this.requestObject.query += this.productsService.filterQuery;
          }
          this.productsService.getProductsList(this.requestObject);
        },
        error: (error: any) => {
          this.apiCallSuccess = false;
          this.globalService.loadingSubject.next(false);
        },
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.store.dispatch(StorageActions.setQuery({ query: '' }));
    this.productsService.searchQuery = '';
    this.productsService.filterQuery = '';
    this.globalService.clearMessagesOnDestroy();
  }
}
