import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MyProfileService } from '../../services/my-profile.service';
import { Observable, Subscription } from 'rxjs';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { LanguageService } from '@spartacus/core';
import { Store, select } from '@ngrx/store';
import { selectUserRoles,selectDateFormat } from '../../../../SGRE-shared/services/storage.state';
import { setDateFormat, setSelectedLanguage } from '../../../../SGRE-shared/services/storage.actions';
import * as StorageActions from '../../../../SGRE-shared/services/storage.actions';
@Component({
  selector: 'app-my-profile-detail',
  templateUrl: './my-profile-detail.component.html',
  styleUrl: './my-profile-detail.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyProfileDetailComponent implements OnInit {
  userRoles: any;

  public selectedDate: string = 'American';
  public data: {} = {};
  public isEdit: boolean = false;
  public toggleRequest: boolean = false;
  public role: string = '';
  public visible: boolean = false;
  public modalpopup: boolean;
  public comment: string = '';
  public userData: any;
  myProfileForm: FormGroup;
  submitted: boolean = false;
  private dataSubscripion: Subscription;
  public receivedData: any[] = [];
  public toasterSuccess: boolean = false;
  public userLoggedIn: Observable<any>;
  public currentLanguage: any = 'English';
  updatedCutomers: any[] = [];
  public selectedDateFormat: any;
  public selectedLanguage: any;

  languageOptions: any[] = [
    { label: "myProfile.english", value: "English" },
    { label: "myProfile.german", value: "German (Germany)" },
    { label: "myProfile.spanish", value: "Spanish (Spain)" },
  ];
  dateFormatValue: any[] = [
    { label: "myProfile.american", value: "American" },
    { label: "myProfile.europeanLatin1", value: "European Latin 1" },
    { label: "myProfile.utc", value: "UTC" },
  ];
  selectedLang: string;
  selectedDateFormatValue: any;

  constructor(
    private myProfileService: MyProfileService,
    private changeRef: ChangeDetectorRef,
    private fb: FormBuilder,
    public storageService: StorageService,
    private globalService: GlobalService,
    private languageService: LanguageService,
    private store: Store
  ) { }

  ngOnInit() {
    this.myProfileFormInitialise();
    this.store.pipe(select(selectDateFormat)).subscribe((dateFormat) => {
      this.selectedDateFormat = dateFormat;
    });
    this.store.pipe(select(setSelectedLanguage)).subscribe((selectedLanguage) => {
      this.selectedLanguage = selectedLanguage;
    });
    if (this.selectedDateFormat === 'MM-dd-yyyy') {
      this.myProfileForm.controls['dateFormat'].setValue("American");
      this.selectedDateFormatValue = 'myProfile.american';
    } else if (this.selectedDateFormat === 'dd-MM-yyyy') {
      this.myProfileForm.controls['dateFormat'].setValue("European Latin 1");
      this.selectedDateFormatValue = 'myProfile.europeanLatin1';
    } else if (this.selectedDateFormat === 'yyyy-MM-dd') {
      this.myProfileForm.controls['dateFormat'].setValue("UTC");
      this.selectedDateFormatValue = 'myProfile.utc';
    }
    this.languageService.getActive().subscribe((val) => {
      this.currentLanguage = val;
      if (this.currentLanguage === 'en') {
        this.myProfileForm.controls['language'].setValue("English");
        this.selectedLang = 'myProfile.english';
      } else if (this.currentLanguage === 'de') {
        this.myProfileForm.controls['language'].setValue("German (Germany)");
        this.selectedLang = 'myProfile.german';
      } else if (this.currentLanguage === 'es') {
        this.myProfileForm.controls['language'].setValue("Spanish (Spain)");
        this.selectedLang = 'myProfile.spanish'
      }
      this.changeRef.markForCheck();
    })
    this.getFromSessionStorage();
    this.userLoggedIn = this.globalService.loginBanner$
    this.myProfileService.myProfileData$.subscribe(
      (data) => {
        this.toggleRequest = false
        this.userData = data;
        this.updateValues();
        this.changeRef.markForCheck();
      }
    );
    this.dataSubscripion = this.myProfileService.currentData.subscribe(data => {
      this.receivedData = data;
    })
    this.getTableData();
  }

  getTableData() {
    this.myProfileService.profileData$.subscribe((data) => {
      this.updatedCutomers = data;
      this.changeRef.markForCheck();
    });
  }

  myProfileFormInitialise() {
    this.store.pipe(select(selectDateFormat)).subscribe((dateFormat) => {
      this.selectedDateFormat = dateFormat;
    });
    this.myProfileForm = this.fb.group({
      language: [this.returnCurrentSelector(this.currentLanguage) ? this.returnCurrentSelector(this.currentLanguage) : 'English', Validators.required],
      dateFormat: [this.storageService.dateFormat ? this.returnCurrentDateSelector() : 'American', Validators.required],
      name: [''],
      email: [''],
      alternativeEmail: [''],
    });
  }

  updateValues() {
    this.myProfileForm.patchValue({
      alternativeEmail: this.userData.alternateEmail,
    });
  }

  onSubmitEmail() {
    this.submitted = true;
    if (this.myProfileForm.value.alternativeEmail != '') {
      this.userData.alternateEmail = this.myProfileForm.value?.alternativeEmail;
      this.myProfileService.updateAlternativeEmail(this.userData.alternateEmail).subscribe({
        next: (value: any) => {
        },
        error: err => console.error('Observable emitted an error: ' + err)

      });
    }
  }

  selectChangeHandler(event: any) {
    this.selectedDate = event.value;
    if (this.selectedDate === 'European Latin 1') {
      this.store.dispatch(StorageActions.setDateFormat({ dateFormat: 'dd-MM-yyyy' }));
      let langDateObj = { dateFormat: 'dd-MM-yyyy' };
      this.languageDateChange(langDateObj);
      this.selectedDateFormatValue = 'myProfile.europeanLatin1';
    }
    else if (this.selectedDate === 'American') {
      this.store.dispatch(StorageActions.setDateFormat({ dateFormat: 'MM-dd-yyyy' }));
      let langDateObj = { dateFormat: 'MM-dd-yyyy' };
      this.languageDateChange(langDateObj);
      this.selectedDateFormatValue = 'myProfile.american';
    }
    else {
      this.store.dispatch(StorageActions.setDateFormat({ dateFormat: 'yyyy-MM-dd' }));
      let langDateObj = { dateFormat: 'yyyy-MM-dd' };
      this.languageDateChange(langDateObj);
      this.selectedDateFormatValue = 'myProfile.utc';
    }
  }

  selectLanguageCHandler(event: any) {
    if (event.value === 'German (Germany)') {
      this.languageService.setActive('de');
      this.globalService.setLanguage('GER');
      this.store.dispatch(StorageActions.setSelectedLanguage({ selectedLanguage: 'de' }));
      let langDateObj = { languageISOCode: 'de' };
      this.languageDateChange(langDateObj);
      this.selectedLang = 'myProfile.german';
    } else if (event.value === 'Spanish (Spain)') {
      this.languageService.setActive('es');
      this.globalService.setLanguage('ESP');
      this.store.dispatch(StorageActions.setSelectedLanguage({ selectedLanguage: 'es' }));
      let langDateObj = { languageISOCode: 'es' };
      this.languageDateChange(langDateObj);
      this.selectedLang = 'myProfile.spanish';
    } else {
      this.languageService.setActive('en');
      this.globalService.setLanguage('ENG');
      this.store.dispatch(StorageActions.setSelectedLanguage({ selectedLanguage: 'en' }));
      let langDateObj = { languageISOCode: 'en' };
      this.languageDateChange(langDateObj);
      this.selectedLang = 'myProfile.english';
    }
  }

  languageDateChange(langDateObj) {
    let req1 = langDateObj?.dateFormat === undefined ? { languageISOCode: langDateObj.languageISOCode } : { dateFormat: langDateObj?.dateFormat }
    this.globalService.addLanguageDateChange(req1).subscribe()
  }

  returnCurrentSelector(value) {
    if (value === 'de') {
      return 'German (Germany)'
    }
    else if (value === 'es') {
      return 'Spanish (Spain)'
    }
    else {
      return 'English'
    }
  }

  returnCurrentDateSelector() {
    this.store.pipe(select(setDateFormat)).subscribe((dateFormat) => {
      this.selectedDateFormat = dateFormat;
    });
    if (this.selectedDateFormat === 'dd-MM-yyyy') {
      return 'European Latin 1'
    }
    else if (this.selectedDateFormat === 'MM-dd-yyyy') {
      return 'American'
    }
    else {
      return 'UTC'
    }
  }
  get f(): { [key: string]: AbstractControl } {
    return this.myProfileForm.controls;
  }

  toggleEdit(edit?: string) {
    if (edit) {
      this.isEdit = !this.isEdit;
    }
    else {
      this.toggleRequest = !this.toggleRequest
      this.myProfileService.setSelected(this.toggleRequest)
    }
  }

  getFromSessionStorage() {
    this.store.pipe(select(selectUserRoles)).subscribe((userRoles) => {
      this.userRoles = userRoles;
    });
    let userRole: any = '';
    userRole = this.userRoles;
    if (userRole?.includes("Requester")) {
      this.role = 'Requester'
    }
    else if (userRole?.includes("Purchaser")) {
      this.role = 'Purchaser'
    }
    else if (userRole?.includes("Customer Admin")) {
      this.role = 'Customer Admin'
    }
    else if (userRole?.includes("SGRE Admin")) {
      this.role = 'SGRE Admin'
    }
    else if (userRole?.includes("Sales Rep")) {
      this.role = 'Sales Rep'
    }
  }

  sendRequest() {
    this.myProfileService.updateCommentData(this.comment);
    this.comment = '';
  }

  closeRequest() {
    this.comment = '';
  }

  ngOnDestroy() {
    this.dataSubscripion.unsubscribe();
    this.myProfileService.updateCommentData('');
    this.globalService.clearMessagesOnDestroy();
  }
}




