import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-header-container',
  templateUrl: './header-container.component.html',
  styleUrl: './header-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderContainerComponent implements OnInit, OnDestroy {

  protected showBanner$: Observable<boolean>;
  unsubscribe$ = new Subject<void>();

  constructor(
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.showBanner$ = this.globalService.loginBanner$
      .pipe(
        takeUntil(this.unsubscribe$)
      )
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.globalService.clearMessagesOnDestroy();
  }
}
