import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';
import { ApiService } from '../../../SGRE-shared/services/api.service';
import { CartService } from '../../cart/services/cart.service';

@Injectable({
  providedIn: 'root',
})
export class SharedCheckoutService {

  private addressSubject = new BehaviorSubject<any>(null);
  addresses = this.addressSubject.asObservable();

  private showModalSubject = new Subject<void>();
  showModal$ = this.showModalSubject.asObservable();

  private orderBooleanService = new BehaviorSubject<boolean>(false);
  public showPlaceOrder = this.orderBooleanService.asObservable();

  private errorBooleanService = new BehaviorSubject<boolean>(false);
  public showSimulationError = this.errorBooleanService.asObservable();

  private quotationData = new BehaviorSubject<boolean>(false);
  public isQuatationData = this.quotationData.asObservable();

  private nonQuotationDataLength = new BehaviorSubject<boolean>(false);
  public isNonQuatationData = this.nonQuotationDataLength.asObservable();

  private errorStringSource = new BehaviorSubject<string>('');
  public simulationErrorMessage = this.errorStringSource.asObservable();

  private cartGroupDataSource = new BehaviorSubject<any>(null);
  public cartGroupData$ = this.cartGroupDataSource.asObservable();

  private isCheckoutPageSubject = new BehaviorSubject<boolean>(false);
  public isCheckoutPage$ = this.isCheckoutPageSubject.asObservable();

  private isBtnClickSubject = new BehaviorSubject<boolean>(false);
  public isBtnClick$ = this.isBtnClickSubject.asObservable();

  private currentTotalSubject = new BehaviorSubject<number>(0);
  currentTotal$ = this.currentTotalSubject.asObservable();

  private minimumOrderAmountSubject = new BehaviorSubject<number>(0);
  minimumOrderAmount$ = this.minimumOrderAmountSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private cartService: CartService
  ) { }

  getAccordionData(cartId: string): Observable<any> {
    let paramsObject = { fields: 'FULL' };
    return this.apiService.getCartsList(cartId, paramsObject);
  }

  getTableData(data): any[] {
    return data?.entries?.map((item) => this.cartService.mapData(item));
  }

  saveCartGroupData(data: any) {
    this.cartGroupDataSource.next(data)
  }

  changeErrorMessage(data: string) {
    this.errorStringSource.next(data);
  }

  changeShowError(data: boolean) {
    this.errorBooleanService.next(data);
  }

  changePlaceOrder(data: boolean) {
    this.orderBooleanService.next(data);
  }

  triggerShowModal() {
    this.showModalSubject.next();
  }

  setCheckoutPage(isCheckout: boolean): void {
    this.isCheckoutPageSubject.next(isCheckout);
  }

  setClickBtn(isBtnClicked: boolean): void {
    this.isBtnClickSubject.next(isBtnClicked);
  }

  checkQuotationLength(data: boolean) {
    this.quotationData.next(data);
  }

  checkNonQuatationLength(data: boolean) {
    this.nonQuotationDataLength.next(data);
  }

  setCurrentTotal(total: number) {
    this.currentTotalSubject.next(total);
  }

  setMinimumOrderAmount(amount: number) {
    this.minimumOrderAmountSubject.next(amount);
  }

  updateShippingAddress(cartId: string, addressId: string): Observable<any> {
    return this.apiService.putShippingAddress(cartId, addressId);
  }

  clearCheckoutData() {
    this.addressSubject.next(null);
    this.showModalSubject.next(null);
    this.orderBooleanService.next(null);
    this.errorBooleanService.next(null);
    this.quotationData.next(null);
    this.nonQuotationDataLength.next(null);
    this.errorStringSource.next(null);
    this.cartGroupDataSource.next(null);
    this.isCheckoutPageSubject.next(null);
    this.isBtnClickSubject.next(null);
    this.currentTotalSubject.next(null);
    this.minimumOrderAmountSubject.next(null);
    this.apiService.addressSubject.next(null);
    this.apiService.deliveryModeSubject.next(null);
    this.apiService.addressChangeQuotationSubject.next(null);
    this.apiService.deliveryModeChangeQuotationSubject.next(null);
    this.apiService.originalCartIdSubject.next(null);
  }
}
