import { createReducer, on } from '@ngrx/store';
import * as StorageActions from './storage.actions';
import { initialState } from './storage.state';

const storageReducer = createReducer(
  initialState,
  on(StorageActions.setMsalInfo, (state, { msalInfo }) => ({ ...state, msalInfo })),
  on(StorageActions.setAccessToken, (state, { accessToken }) => ({ ...state, accessToken })),
  on(StorageActions.setRedirectUrl, (state, { redirectUrl }) => ({ ...state, redirectUrl })),
  on(StorageActions.setCurrency, (state, { currency }) => ({ ...state, currency })),
  on(StorageActions.setUserLegalEntities, (state, { userLegalEntities }) => ({ ...state, userLegalEntities })),
  on(StorageActions.setDefaultLegalEntity, (state, { defaultLegalEntity }) => ({ ...state, defaultLegalEntity })),
  on(StorageActions.setUserRoles, (state, { userRoles }) => ({ ...state, userRoles })),
  on(StorageActions.setDateFormat, (state, { dateFormat }) => ({ ...state, dateFormat })),
  on(StorageActions.setSelectedLanguage, (state, { selectedLanguage }) => ({ ...state, selectedLanguage })),
  on(StorageActions.setQuery, (state, { query }) => ({ ...state, query })),
  on(StorageActions.setCartId, (state, { cartId }) => ({ ...state, cartId })),
  on(StorageActions.setCarts, (state, { carts }) => ({ ...state, carts })),
  on(StorageActions.setActiveCart, (state, { activeCart }) => ({ ...state, activeCart })),
  on(StorageActions.setParamsCartGroupId, (state, { paramsCartGroupId }) => ({ ...state, paramsCartGroupId })),
  on(StorageActions.setCheckoutCartGroupId, (state, { checkoutCartGroupId }) => ({ ...state, checkoutCartGroupId })),
  on(StorageActions.setCartCode, (state, { cartCode }) => ({ ...state, cartCode })),
  on(StorageActions.setSecondaryCartCode, (state, { secondaryCartCode }) => ({ ...state, secondaryCartCode })),
  on(StorageActions.setUserProfileData, (state, { userProfileData }) => ({ ...state, userProfileData })),
  on(StorageActions.setCartUserData, (state, { cartUserData }) => ({ ...state, cartUserData })),
);

export function reducer(state, action) {
  return storageReducer(state, action);
}
