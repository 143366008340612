import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { HomepageService } from '../../../../SGRE-custom/homepage/services/homepage.service';

@Component({
  selector: 'app-homepage-paragraph-section4',
  templateUrl: './homepage-paragraph-section4.component.html',
  styleUrl: './homepage-paragraph-section4.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomepageParagraphSection4Component {

  public componentData: any;

  constructor(public component: CmsComponentData<any>) { }

  ngOnInit(): void {
    this.component.data$.subscribe((data) => {
      this.componentData = data;
    });
  }
}
