import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { ProductDetailsService } from '../../services/product-details-service';
import { Observable, Subject, map, of, takeUntil, tap } from 'rxjs';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { ProductInfo, ProductVariant } from '../../../../SGRE-custom/product-details-page/models/product-info';

@Component({
  selector: 'app-product-variant',
  templateUrl: './product-variant.component.html',
  styleUrl: './product-variant.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductVariantComponent implements OnInit, OnDestroy {

  productDetails$: Observable<any>;
  public productVariant = ProductVariant;
  btnVariant$: Observable<string[]>;
  selectedBtnFlag: boolean;
  private unsubscribe$ = new Subject<void>();

  constructor(
    public productsService: ProductDetailsService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.productDetails$ = this.productsService.getOotbProduct()
      .pipe(
        map((originalResponse: unknown) => {
          let data = originalResponse as ProductInfo;
          console.log('PDP data:', data);
          this.btnVariant$ = of(this.productsService.getBtnVariants(data));
          this.productsService.getCurrentProduct(data);
          this.productsService.updatePdpCodeSubject(data?.code);
          return data;
        })
      );
    this.productsService.variant$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        if (data) {
          console.log('variantObj:', data);
          this.selectedBtnFlag = data.code.includes(ProductVariant.NEW);
        }
      });
  }

  onVariantChange(variantVal: string) {
    this.selectedBtnFlag = (variantVal === ProductVariant.NEW);
    this.productsService.getProductVariant(variantVal === ProductVariant.NEW);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.globalService.clearMessagesOnDestroy();
  }
}