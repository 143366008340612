import { NgModule } from '@angular/core';
import { translationChunksConfig, translations } from "@spartacus/assets";
import { ConfigModule, FeaturesConfig, I18nConfig, OccConfig, provideConfig, RoutingConfig, SiteContextConfig } from "@spartacus/core";
import { LayoutConfig, defaultCmsContentProviders, layoutConfig, mediaConfig } from "@spartacus/storefront";
import { environment } from '../../environments/environment';
import { customGlobalMessageConfig } from '../SGRE-config/message-config';
import { customLayoutConfig } from '../SGRE-config/layout-config';

@NgModule({
  declarations: [],
  imports: [
    ConfigModule.withConfig({
      routing: {
        routes: {
          login: {
            paths: ['homepage'],
          }
        },
      },
    } as RoutingConfig),
    ConfigModule.withConfig(customGlobalMessageConfig),
  ],
  providers: [
    /** Below provided is the custom-layoutConfig that is used in this application */
    provideConfig(<LayoutConfig>{
      layoutSlots: customLayoutConfig.layoutSlots,
    }),
    provideConfig(mediaConfig),
    ...defaultCmsContentProviders,
    provideConfig(<OccConfig>{
      backend: {
        occ: {
          baseUrl: environment.siteUrls.getBaseUrl,
          prefix: environment.siteUrls.getPrefix,
        }
      },
    }),
    provideConfig({
      features: {
        userCmsEndpoints: true
      },
    }),
    provideConfig(<SiteContextConfig>{
      context: {
        language: ['en', 'de', 'es']
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        backend: {
          loadPath: 'assets/i18n/{{lng}}.json',
        },
        resources: translations,
        chunks: translationChunksConfig,
        fallbackLang: 'en'
      },
    }),
    provideConfig(<FeaturesConfig>{
      features: {
        level: '2211.27'
      }
    }),
  ]
})
export class SpartacusConfigurationModule { }
