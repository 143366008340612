import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';

@Component({
  selector: 'app-shared-navigation',
  templateUrl: './shared-navigation.component.html',
  styleUrl: './shared-navigation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedNavigationComponent {
  public componentData: any;

  constructor(public component: CmsComponentData<any>) { }

  ngOnInit(): void {
    this.component.data$.subscribe((data) => {
      this.componentData = data;
    });
  }
}
