import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';

@Component({
  selector: 'app-homepage-paragraph-section8',
  templateUrl: './homepage-paragraph-section8.component.html',
  styleUrl: './homepage-paragraph-section8.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomepageParagraphSection8Component {
  public componentData: any;

  constructor(public component: CmsComponentData<any>) { }

  ngOnInit(): void {
    this.component.data$.subscribe((data) => {
      this.componentData = data;
    });
  }
}
