import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@spartacus/core';
import { Observable, tap } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { GlobalService } from '../global.service';
import * as StorageActions from  '../storage.actions';
import { Store,select } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class CustomRouteGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private msalService: MsalService,
    private globalService: GlobalService,
    private store: Store
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean | UrlTree {
    return this.authService.isUserLoggedIn().pipe(
      tap((isLoggedIn) => {
        if (!isLoggedIn) {
          this.store.dispatch(StorageActions.setRedirectUrl({ redirectUrl: state.url }));
          this.globalService.checkSmartEdit()
            ? this.msalService.loginPopup()
            : this.msalService.loginRedirect();
        }
      })
    );
  }
}