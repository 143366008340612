<div class="container-fluid">
	<form [formGroup]="myProfileForm" class="formmodal">
		<div class="form-row">
			<div class="form-group col-lg-3">
				<label for="" class="profile-label">
					{{"myProfile.name" |cxTranslate}}
				</label>
				<p class="profile-data">
					{{userData?.customerName }}
				</p>
				<input type="text" style="display: none;" class="form-control email" formControlName="name">
			</div>
			<div class="form-group col-lg-3">
				<label for="" class="profile-label">
					{{"myProfile.email" |cxTranslate}}
				</label>
				<p class="profile-data cstm-next-line email-text">
					{{userData?.email }}
				</p>
				<input type="text" style="display: none;" class="form-control email" formControlName="email">
			</div>
			<div *ngIf="role === 'user'|| role === 'Customer Admin' || role === 'Purchaser'||role === 'Requester'"
				class="form-group col-lg-4">
				<label for="" class="profile-label">
					{{"myProfile.alternativeEmail" |cxTranslate}}
				</label>
				<p *ngIf="!isEdit" class="profile-data email-text">
					{{userData?.alternateEmail}}
				</p>
				<input *ngIf="isEdit" [ngClass]="{ 'is-invalid': submitted && f['alternativeEmail'].errors }"
					type="text" class="form-control email" formControlName="alternativeEmail">
			</div>
			<div *ngIf="role === 'user'|| role === 'Customer Admin' || role === 'Purchaser'||role === 'Requester'"
				class="form-group col-lg-2">
				<div *ngIf="!isEdit" (click)="toggleEdit('edit')" class="cstm-margin">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
						class="bi bi-pencil-square" viewBox="0 0 16 16">
						<path
							d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z">
						</path>
						<path fill-rule="evenodd"
							d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z">
						</path>
					</svg>
				</div>
				<div *ngIf="isEdit" (click)="toggleEdit('edit');onSubmitEmail()" class="cstm-margin">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
						class="bi bi-floppy" viewBox="0 0 16 16">
						<path d="M11 2H9v3h2z"></path>
						<path
							d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z">
						</path>
					</svg>
				</div>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-md-3">
				<label for="" class="profile-label">
					{{"myProfile.preferredLanguage" |cxTranslate}}
				</label>
				<p-dropdown styleClass="cstm-dropdown" formControlName="language" [options]="languageOptions"
					optionLabel="label" optionValue="value" (onChange)="selectLanguageCHandler($event)">
					<ng-template pTemplate="selectedItem">
						<div class="flex align-items-center gap-2">
							<div>{{selectedLang|cxTranslate }}</div>
						</div>
					</ng-template>
					<ng-template let-option pTemplate="item">
						<span>{{option.label|cxTranslate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
			<div class="form-group col-md-3">
				<label for="" class="profile-label">
					{{"myProfile.preferredDateFormat" |cxTranslate}}
				</label>
				<p-dropdown styleClass="cstm-dropdown" formControlName="dateFormat" [options]="dateFormatValue"
					optionLabel="label" optionValue="value" (onChange)="selectChangeHandler($event)">
					<ng-template pTemplate="selectedItem">
						<div class="flex align-items-center gap-2">
							<div>{{selectedDateFormatValue|cxTranslate }}</div>
						</div>
					</ng-template>
					<ng-template let-option pTemplate="item">
						<span>{{option.label|cxTranslate}}</span>
					</ng-template>
				</p-dropdown>
			</div>
		</div>
		<div class="form-group">
			<label for="" class="profile-label">
				{{"myProfile.myRoles" |cxTranslate}}
			</label>
			<br>
			<div *ngIf="!toggleRequest">
				<button [disabled]="updatedCutomers?.length === 0" (click)="toggleEdit()"
					class="btn btn-outline-primary">
					{{"myProfile.requestAccess" |cxTranslate}}
				</button>
			</div>
			<div *ngIf="toggleRequest">
				<button (click)="this.modalpopup = true; " class="btn cstm-btn-primary" data-toggle="modal"
					data-target="#exampleModal" data-backdrop="false">
					{{"myProfile.send" |cxTranslate}}
				</button>				
				<button (click)="toggleEdit()" class="btn btn-outline-primary ml-3">
					{{"myProfile.cancel" |cxTranslate}}
				</button>
			</div>
		</div>
	</form>
</div>
<!-- Modal -->
<div *ngIf="modalpopup && receivedData.length">
	<div class="modal fade" tabindex="-1" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel"
		aria-hidden="true">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">
						{{'myProfile.sendAccessRequest'|cxTranslate}}
					</h5>
					<button (click)="closeRequest()" type="button" class="close" data-dismiss="modal"
						aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div id="send-request-modal___BV_modal_body_" class="modal-body">
						<div role="group" class="form-group">
							<label for="comment-input" class="d-block">
								{{'myProfile.CommentLabel'|cxTranslate}}
							</label>
							<div>
								<textarea [(ngModel)]="comment" id="comment-input"
									placeholder="{{'myProfile.addCommentPlaceholder'|cxTranslate}}" rows="2" wrap="soft"
									class="form-control"></textarea>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer pull-left">
					<button (click)="closeRequest()" class="btn btn-outline-primary ml-3" data-dismiss="modal">
						{{'myProfile.cancel'|cxTranslate}}
					</button>
					<button (click)="sendRequest()" class="btn cstm-btn-primary" data-dismiss="modal">
						{{'myProfile.send'|cxTranslate}}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>