import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { Observable, Subject, Subscription, combineLatest, filter, takeUntil } from 'rxjs';
import { AppConstants } from '../../../../SGRE-shared/constants/app-constant';
import { Router } from '@angular/router';
import { SharedCheckoutService } from '../../../checkout/services/sharedcheckout.service';
import { ProductListService } from '../../../product-list-page/services/product-list.service';
import { CmsService, isNotNullable, isNotUndefined } from '@spartacus/core';
import { selectUserLegalEntities,selectDefaultLegalEntity } from '../../../../SGRE-shared/services/storage.state';
import { Store,select } from '@ngrx/store';
@Component({
  selector: 'app-legal-entity-selector',
  templateUrl: './legal-entity-selector.component.html',
  styleUrl: './legal-entity-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegalEntitySelectorComponent implements OnInit, OnDestroy {

  public legalEntityList: any[] = [];
  public legalEntityListUid: string[] = [];
  public legalEntityListSession: string[] = [];
  public selectedLegalEntitySession: any;
  public selectedLegalEntity: Observable<any>;
  public legalEntityListNew: Observable<any>;
  public searchText: string = '';
  public userLoggedIn: Observable<any>;
  private isCheckoutPage = false;
  private unsubscribe$ = new Subject<void>();
  public searchLegalEntity: string = "Search Legal Entity";
  private legalEntityChangeSubscription: Subscription | null = null;

  constructor(
    private globalService: GlobalService,
    private router: Router,
    private sharedCheckoutService: SharedCheckoutService,
    private productsService: ProductListService,
    private cmsService: CmsService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.userLoggedIn = this.globalService.loginBanner$;

    this.legalEntityListNew = this.globalService.legalEntitiesList$;
    this.selectedLegalEntity = this.globalService.defaultLegalEntitySubject$;

    // get entities by store select
    this.store.pipe(select(selectUserLegalEntities)).subscribe((userLegalEntities) => {
      this.legalEntityListSession = Array.isArray(userLegalEntities) ? userLegalEntities.map(item => item.name) : [];
    });
    this.store.pipe(select(selectDefaultLegalEntity)).subscribe((defaultLegalEntity) => {
      this.selectedLegalEntitySession = defaultLegalEntity ? defaultLegalEntity : '';
    });
    this.sharedCheckoutService.isCheckoutPage$
      .pipe(
        filter(isNotNullable),
        filter(isNotUndefined),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((isCheckout) => {
        this.isCheckoutPage = isCheckout;
      });
  }

  onValueChange(legalEntity: string) {
    // Unsubscribe if there's an existing subscription to prevent multiple calls
    if (this.legalEntityChangeSubscription) {
      this.legalEntityChangeSubscription.unsubscribe();
    }

    this.legalEntityChangeSubscription = combineLatest([
      this.store.pipe(select(selectUserLegalEntities)),
      this.store.pipe(select(selectDefaultLegalEntity))
    ]).pipe(takeUntil(this.unsubscribe$))
      .subscribe(([userLegalEntities, defaultLegalEntity]) => {
        this.legalEntityListSession = Array.isArray(userLegalEntities) ? userLegalEntities.map(item => item.name) : [];
        const currentDefaultLegalEntityName = defaultLegalEntity ? defaultLegalEntity.name : '';

        // Check if legalEntity has changed and is in the list
        if (legalEntity !== currentDefaultLegalEntityName && this.legalEntityListSession?.length > 0) {
          const index = this.legalEntityListSession.indexOf(legalEntity);
          console.log("3");// Will be removed
          this.globalService.updateLegalEntity(index);

          if (this.isCheckoutPage) {
            this.router.navigate([AppConstants.routeUrls.plp]);
          }
        }
      });

    this.searchText = '';
    if (this.router.url.includes(AppConstants.routeUrls.plp)) {
      this.productsService.paginationReset.next(true);
    }
    this.cmsService.refreshLatestPage();
  }

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.globalService.clearMessagesOnDestroy();
  }
}
