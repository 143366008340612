import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageSlotModule } from '@spartacus/storefront';
import { ConfigModule } from '@spartacus/core';
import { PrimengModule } from '../primeng/primeng.module';
import { FormsModule } from '@angular/forms';
import { SGRESharedModule } from '../../SGRE-shared/SGRE-shared.module';


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    PageSlotModule,
    PrimengModule,
    FormsModule,
    SGRESharedModule,
    ConfigModule.withConfig({
      cmsComponents: {
        AccessRequestsQ1VideoPopupComponent: {
          component: () => import('./components/faq-video-popup/faq-video-popup.component').then(m => m.FaqVideoPopupComponent),
        },
        OrderQ1VideoPopupComponent: {
          component: () => import('./components/faq-order-video-popup/faq-order-video-popup.component').then(m => m.FaqOrderVideoPopupComponent),
        }
      }
    })
  ]
})
export class FaqModule { }