import { Component,ChangeDetectionStrategy } from '@angular/core';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-homepage-section5-links',
  templateUrl: './homepage-section5-links.component.html',
  styleUrl: './homepage-section5-links.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomepageSection5LinksComponent {

  constructor(
    private globalService: GlobalService,
    private msalService: MsalService
  ) { }

  login() {
    this.globalService.checkSmartEdit()
      ? this.msalService.loginPopup()
      : this.msalService.loginRedirect();
  }
}
