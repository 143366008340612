<div class="row m-0 mb-3">
  <div class="col-12">
    <ng-container *ngIf="facets$ | async as facetsList; else noFacets">
      <div *ngIf="facetsList?.length > 0; else noFacets" class="d-flex flex-column">
        <!-- TURBINE -->
        <ng-container *ngFor="let facet of facetsList">
          <ng-container *ngIf="facet?.name === facetName.COMPATIBILITY && facet?.values?.length > 0">
            <div class="turbineFilters">
              <h3 class="mb-2">{{"psp.turbine"|cxTranslate }}</h3>
              <input type="text" placeholder="{{'psp.filterTurbines'|cxTranslate }}" (input)="updateTurbines($event)"
                class="form-control my-2 rounded-0">
              <div *ngFor="let turbine of facet?.values|filterDropdown:searchText|categoryToggleFilter:turbineToggle;index as i"
                class="form-check form-check-container">
                <input type="checkbox" id="turbine_id" class="form-check-input" value="{{turbine.name}}"
                  [checked]="turbine.selected" (change)="onValueChange(turbine)">
                <label for="" class="form-check-label">
                  {{turbine.name}}
                  <span *ngIf="!(userLoggedIn|async)" class="gray">{{turbine.count}}</span>
                </label>
              </div>
              <ng-container *ngIf="!turbineToggle; else showLess">
                <a *ngIf="(facet?.values|filterDropdown:searchText).length >= 8" class="pl-1 text-decoration-none">
                  <i class="fa fa-caret-down toggle-show"></i>
                  <span class="gray show-all-label" (click)="filterToggle(facetName.COMPATIBILITY)">
                    {{"psp.showAll"|cxTranslate}}
                  </span>
                </a>
              </ng-container>
              <ng-template #showLess>
                <a *ngIf="(facet?.values|filterDropdown:searchText).length >= 8" class="pl-1 text-decoration-none">
                  <i class="fa fa-caret-up toggle-show"></i>
                  <span class="gray show-all-label" (click)="filterToggle(facetName.COMPATIBILITY)">
                    {{"psp.showLess"|cxTranslate}}
                  </span>
                </a>
              </ng-template>
            </div>
          </ng-container>
        </ng-container>
        <!-- Exception case where facets.name is not available -->
        <ng-container *ngIf="checkFacetName(facetsList, facetName.COMPATIBILITY)">
          <ng-container *ngTemplateOutlet="noTurbine"></ng-container>
        </ng-container>

        <!-- CATEGORY -->
        <ng-container *ngFor="let facet of facetsList">
          <ng-container *ngIf="facet?.name === facetName.ALL_CATEGORIES && facet?.values?.length > 0">
            <div class="categoryFilters">
              <h3 class="mb-2">{{'psp.category'|cxTranslate }}</h3>
              <div *ngFor="let category of facet?.values" class="form-check form-check-container">
                <input type="checkbox" id="category_id" class="form-check-input" value="{{category.name}}"
                  [checked]="category.selected" (change)="onValueChange(category)">
                <label for="" class="form-check-label">
                  {{ category.name }}
                  <span *ngIf="!(userLoggedIn|async)" class="gray">({{category.count}})</span>
                </label>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <!-- Exception case where facets.name is not available -->
        <ng-container *ngIf="checkFacetName(facetsList, facetName.ALL_CATEGORIES)">
          <ng-container *ngTemplateOutlet="noCategory"></ng-container>
        </ng-container>
      </div>
    </ng-container>
    <ng-template #noFacets>
      <ng-container *ngTemplateOutlet="noTurbine"></ng-container>
      <ng-container *ngTemplateOutlet="noCategory"></ng-container>
    </ng-template>
    <ng-template #noTurbine>
      <div class="turbineFilters">
        <h3 class="mb-2">{{"psp.turbine"|cxTranslate }}</h3>
        <input type="text" placeholder="Filter Turbines" (input)="updateTurbines($event)"
          class="form-control my-2 rounded-0">
      </div>
    </ng-template>
    <ng-template #noCategory>
      <div class="categoryFilters">
        <h3 class="mb-2">{{'psp.category'|cxTranslate }}</h3>
      </div>
    </ng-template>
  </div>
</div>