import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ProductListService } from '../../services/product-list.service';
import { PlpInfo, FacetName, CategoryName } from '../../models/plpInfo';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import {
  ProductSearchService,
  Facet,
  ProductSearchPage,
  FacetValue,
} from '@spartacus/core';

@Component({
  selector: 'app-product-filter',
  templateUrl: './product-filter.component.html',
  styleUrl: './product-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductFilterComponent implements OnInit {
  filterList: Observable<PlpInfo>;
  public searchText: string = '';
  turbineToggle: boolean;
  categoryToggle: boolean;
  public userLoggedIn: Observable<any>;

  facets$: Observable<Facet[]>;
  facetName = FacetName;
  categoryName = CategoryName;

  constructor(
    private productsService: ProductListService,
    private globalService: GlobalService,
    private productSearchService: ProductSearchService
  ) { }

  ngOnInit(): void {
    this.userLoggedIn = this.globalService.loginBanner$;
    // this.getProductsList();

    this.getFacetsList();
  }

  /** -- OOTB Implementation -- */
  getFacetsList() {
    this.facets$ = this.productsService.getFacets();
  }

  checkFacetName(facetsList: Facet[], name: FacetName): boolean {
    return facetsList.every((facet) => facet.name !== name);
  }

  onValueChange(facetObj: FacetValue) {
    let queryVal = facetObj.query?.query?.value ?? '';
    // if (queryVal?.incldues(' ')) {
    //   queryVal.split(' ').join('')
    // }
    // console.log('queryVal:', queryVal);
    this.productsService._search(queryVal);
  }

  /** -- Previous Implementation -- */
  public getProductsList() {
    this.filterList = this.productsService.productsList$;
  }

  public updateTurbines(event: any) {
    this.searchText = event.target.value;
  }

  getCategoryIndex(facet) {
    return facet?.at(0)?.name === 'ALL_CATEGORIES' ? 0 : facet?.at(1)?.name === 'ALL_CATEGORIES' ? 1 : -1;
  }

  valueChange(query, name, isSelected) {
    let searchQuery =
      this.productsService.searchQuery + this.productsService.filterQuery;
    if (!isSelected) {
      query = searchQuery + query + `"${name}"`;
    } else if (searchQuery?.includes(query + `"${name}"`)) {
      query = searchQuery?.replace(query + `"${name}"`, '');
    }
    this.productsService.paginationReset.next(true);
    this.productsService.getProductsList({
      query: query,
      currentPage: 0,
      pageSize: 30,
      fields: 'FULL',
    });
  }

  filterToggle(categoryName: string) {
    if (categoryName === this.facetName.COMPATIBILITY) {
      this.turbineToggle = !this.turbineToggle;
    } else if (categoryName === this.facetName.ALL_CATEGORIES) {
      this.categoryToggle = !this.categoryToggle;
    }
  }

  ngOnDestroy() {
    this.globalService.clearMessagesOnDestroy();
  }
}
