<ng-container *ngIf="currentpage$ | async as page">
  <div *ngIf="page===2" class="container-fluid my-1">
    <div class="DragDropUpload px-1" style="line-height:3.5;">
      <div class="box drag-drop-enabled drag-drop-upload-container">
        <div class="box__input" (drop)="onDrop($event)" (dragover)="onDragOver($event)">
          <input type="file" id="file" multiple="true" class="box__file" #fileUploader (change)="uploadImage($event)" />
          <label for="file">
            <i data-v-172755ba="" class="sg-icon sg-icon-download cursor-pointer chooseAFile-icon"></i>
            <span class="select-file-label" style="display: block">{{"bulkOrder.chooseAFile"|cxTranslate}}</span>
          </label>
          <div class="file-listing" *ngFor="let res of array; let i = index">
            <div class="uploaded-file">
              <span class="file-name font-weight-light">{{ res.name }}</span>
              <span class="remove-file" (click)="remove(i)">
                <i class="fa fa-times"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <span *ngIf="invalidFile" class="error">
      {{"bulkOrder.invalidFileTypes"|cxTranslate}}:{{invalidFile}}
    </span>
    <span *ngIf="isError" class="error">
      <p>{{"bulkOrder.invalidFileDatas"|cxTranslate}}</p>
    </span>
    <span *ngIf="isErrorFile" class="error">
      <p>{{"bulkOrder.invalidFileError"|cxTranslate}}</p>
    </span>
    <span *ngIf="isQtyError" class="error">
      <p>{{"bulkOrder.isQtyerrorFile"|cxTranslate}}</p>
    </span>
    <span *ngIf="sizeFlag" class="error">
      <p>{{"bulkOrder.invalidFileSize"|cxTranslate}}</p>
    </span>
  </div>
</ng-container>