import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderHistorySearchBoxComponent } from './components/order-history-search-box/order-history-search-box.component';
import { OrderHistorySummaryComponent } from './components/order-history-summary/order-history-summary.component';
import { OrderHistoryCsvPdfComponent } from './components/order-history-csv-pdf/order-history-csv-pdf.component';
import { OrderHistoryTableComponent } from './components/order-history-table/order-history-table.component';
import { ConfigModule, I18nModule } from '@spartacus/core';
import { SGRESharedModule } from '../../SGRE-shared/SGRE-shared.module';
import { PrimengModule } from '../primeng/primeng.module';
import { ReactiveFormsModule } from '@angular/forms';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
  TranslateStore,
} from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { HttpBackend } from '@angular/common/http';

import { CustomRouteGuard } from '../../SGRE-shared/services/guard/msal.guard';

import { SalesChartsPanelContainerComponent } from './components/sales-charts-panel-container/sales-charts-panel-container.component';
import { SoldTovsSalesChartComponent } from './components/sold-tovs-sales-chart/sold-tovs-sales-chart.component';
import { CustomerwiseSalesComponent } from './components/customerwise-sales/customerwise-sales.component';
import { LegalEntityWiseSalesComponent } from './components/legal-entity-wise-sales/legal-entity-wise-sales.component';

export function HttpLoaderFactory(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, ['/assets/i18n/core/', '/assets/i18n/vendors/']);
}

@NgModule({
  declarations: [
    OrderHistorySearchBoxComponent,
    OrderHistorySummaryComponent,
    OrderHistoryCsvPdfComponent,
    OrderHistoryTableComponent,
    SalesChartsPanelContainerComponent,
    SoldTovsSalesChartComponent,
    CustomerwiseSalesComponent,
    LegalEntityWiseSalesComponent
  ],
  imports: [
    CommonModule,
    SGRESharedModule,
    PrimengModule,
    ReactiveFormsModule,
    PowerBIEmbedModule,
    ConfigModule.withConfig({
      cmsComponents: {
        OrdersPageSearchComponent: {
          component: () => import('./components/order-history-search-box/order-history-search-box.component').then(m => m.OrderHistorySearchBoxComponent),
          guards: [CustomRouteGuard]
        },
        OrderSummaryFlexComponent: {
          component: () => import('./components/order-history-summary/order-history-summary.component').then(m => m.OrderHistorySummaryComponent),
          guards: [CustomRouteGuard]
        },
        OrderHistoryFlexComponent: {
          component: () => import('./components/order-history-table/order-history-table.component').then(m => m.OrderHistoryTableComponent),
          guards: [CustomRouteGuard]
        },
        DownloadCSVPDFFlexComponent: {
          component: () => import('./components/order-history-csv-pdf/order-history-csv-pdf.component').then(m => m.OrderHistoryCsvPdfComponent),
          guards: [CustomRouteGuard]
        },
        SalesChartFlexComponent: {
          component: () => import('./components/sales-charts-panel-container/sales-charts-panel-container.component').then(m => m.SalesChartsPanelContainerComponent),
          guards: [CustomRouteGuard]
        },
        SoldTovsSalesChartFlexComponent: {
          component: () => import('./components/sold-tovs-sales-chart/sold-tovs-sales-chart.component').then(m => m.SoldTovsSalesChartComponent),
          guards: [CustomRouteGuard]
        },
        CustomerwiseSalesChartFlexComponent: {
          component: () => import('./components/customerwise-sales/customerwise-sales.component').then(m => m.CustomerwiseSalesComponent),
          guards: [CustomRouteGuard]
        },
        LegalEntityWiseSalesChartFlexComponent: {
          component: () => import('./components/legal-entity-wise-sales/legal-entity-wise-sales.component').then(m => m.LegalEntityWiseSalesComponent),
          guards: [CustomRouteGuard]
        }
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    I18nModule
  ],
  providers: [
    TranslateService,
    { provide: TranslateStore }
  ]
})
export class OrderHistoryModule { }
