import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CmsParagraphComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-footer-notice',
  templateUrl: './footer-notice.component.html',
  styleUrl: './footer-notice.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterNoticeComponent implements OnInit {

  @Input() data: Observable<CmsParagraphComponent>;
  currentYear: any;

  constructor(public component: CmsComponentData<any>) { }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear()
  }
}
