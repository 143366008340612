import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Column, ColumnType } from '../../../../SGRE-shared/models/tableConfiguration';
import { AccessRequestsService } from '../../service/access-requests.service'
import { ApiService } from '../../../../SGRE-shared/services/api.service'
import { Router } from '@angular/router';
import { AppConstants } from '../../../../SGRE-shared/constants/app-constant';
import { UserProfileService } from '../../../user-profile/services/user-profile.service';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { Subject, takeUntil } from 'rxjs';
import { Store,select } from '@ngrx/store';
import { selectUserRoles } from '../../../../SGRE-shared/services/storage.state';
import * as StorageActions from '../../../../SGRE-shared/services/storage.actions';
@Component({
  selector: 'app-access-request-table',
  templateUrl: './access-request-table.component.html',
  styleUrl: './access-request-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessRequestTableComponent implements OnInit, OnDestroy {

  public columns: Column[];
  public column1: Column[];
  data: any = [];
  userRolesSGRE: boolean = false;
  userRolesCustomer: boolean = false;
  accessRequestsList: any = [];
  private unsubscribe$ = new Subject<void>();
  userRoles: any;
  userProfileData: any;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private accessRequestsService: AccessRequestsService,
    public userProfileService: UserProfileService,
    public globalService: GlobalService,
    private store: Store
  ) { }

  ngOnInit() {
    this.store.pipe(select(selectUserRoles)).subscribe((userRoles) => {
      this.userRoles = userRoles;
    });
    this.roleCheck(this.userRoles);
    this.getDataTable();
    this.userProfileService.disableThreeButton = true;
  }

  roleCheck(roles: string[] | string) {
    if (roles?.length > 0) {
      let rolesArr = typeof (roles) === 'string' ? JSON.parse(roles) : roles;
      this.userRolesSGRE = rolesArr.some(val => val === 'SGRE Admin');
      this.userRolesCustomer = rolesArr.some(val => val === 'Customer Admin');
    }
  }

  getDataTable() {
    this.globalService.loadingSubject.next(true);
    this.accessRequestsService.getOrderRequestList();
    this.accessRequestsList = this.accessRequestsService.data$;
    if (this.accessRequestsList) {
      this.accessRequestsList
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((data) => {
          this.createTableConfiguration(data)
          this.globalService.loadingSubject.next(false);
        })
    }
  }

  createTableConfiguration(data) {
    const nameFilter = this.addArrayFilter(data, 'customerName');
    const userNameFilter = this.addArrayFilter(data, 'customerEmail');
    const legalEntities = this.addArrayFilter(data, 'company');
    const companyFilter = this.addArrayFilter(data, 'legalEntityName');
    let columnData = [
      { label: "Name", name: "customerName", type: ColumnType.text, filter: true, filterOptions: (nameFilter).map((item) => { return { customerName: item } }) },
      { label: "User Name", name: "customerEmail", type: ColumnType.text, filter: true, filterOptions: (userNameFilter).map((item) => { return { customerEmail: item } }) },
      { label: "Action", name: "action", type: ColumnType.removeAndReview },
    ];
    let columnData1 = [
      { label: "Name", name: "customerName", type: ColumnType.text, filter: true, filterOptions: (nameFilter).map((item) => { return { customerName: item } }) },
      { label: "User Name", name: "customerEmail", type: ColumnType.text, filter: true, filterOptions: (userNameFilter).map((item) => { return { customerEmail: item } }) },
      { label: "Company", name: "company", type: ColumnType.text, filter: true, filterOptions: (legalEntities).map((item) => { return { company: item } }) },
      { label: "Legal Entity", name: "legalEntityName", type: ColumnType.text, filter: true, filterOptions: (companyFilter).map((item) => { return { legalEntityName: item } }) },
      { label: "Action", name: "action", type: ColumnType.removeAndReview },
    ];
    this.columns = columnData.map(item => new Column(item));
    this.column1 = columnData1.map(item => new Column(item));
  }

  addArrayFilter(testArrayData, filterKey) {
    let array = testArrayData?.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[filterKey]).indexOf(obj[filterKey]) === pos;
    });
    return array.filter((item) => {
      return item[filterKey] !== undefined
    }).map((c) => c[filterKey]
    )
  }

  removeRow(data) {
    let val = data.rowData;
    if (data.data == 'review') {
      this.store.dispatch(StorageActions.setUserProfileData({ userProfileData: 'access' }));
      this.router.navigate(
        [AppConstants.routeUrls.userProfile],
        {
          queryParams: {
            legalEntity: val.legalEntityId,
            user: val.customerId,
            date: val.requestDate
          }
        }
      );
    } else {
      const requestObject: any = {};
      let dat = (val.requestDate).replaceAll(":", "%3A")
      requestObject.date = (dat).replaceAll("+", "%2B")
      requestObject.customerId = val.customerId;
      requestObject.legalEntityId = encodeURIComponent(val.legalEntityId);
      this.globalService.loadingSubject.next(true);
      this.apiService.updateAccessRequests(requestObject)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (val: any) => {
            this.globalService.loadingSubject.next(false);
            this.getDataTable();
          },
          error: err => {
            console.error('Observable emitted an error: ' + err);
            this.globalService.loadingSubject.next(false);
          }
        });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.globalService.clearMessagesOnDestroy();
  }

}
