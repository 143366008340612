/** -- OOTB Implementation -- */
export enum FacetName {
    COMPATIBILITY = 'compatibility',
    ALL_CATEGORIES = 'allCategories',
    STORES = 'Stores',
    PRICE = 'Price',
}

export enum CategoryName {
    MECHANICAL = 'MECHANICAL',
    ELECTRICAL = 'ELECTRICAL',
    HYDRAULIC = 'HYDRAULIC',
    OTHERS = 'OTHERS',
    LARGE_COMPONENTS = 'LARGE COMPONENTS',
    TOP_FEATURED_PRODUCTS = 'TOP FEATURED PRODUCTS',
}

/** -- Previous Implementation -- */
export class PlpInfo {
    breadcrumbs: any[];
    currentQuery: Query;
    facets: Facets[];
    freeTextSearch: string;
    pagination: Pagination;
    products: ProductDetails[];
    sorts: Sort[];
    type: string;
}

export class Query {
    query: ValueOfQuery;
    url: string;
}
export class ValueOfQuery {
    value: string;
}

export class Facets {
    category: boolean;
    multiSelect: boolean;
    name: string;
    priority: number;
    values: ValueOfFacets[];
    visible: boolean;
}
export class ValueOfFacets {
    count: number;
    name: string;
    query: Query;
    selected: boolean;
}

export class Pagination {
    currentPage: number;
    pageSize: number;
    sort: string;
    totalPages: number;
    totalResults: number;
}

export class ProductDetails {
    baseProduct: string;
    code: string;
    configurable: boolean;
    description: string;
    discountPriceData: DiscountPriceData;
    images: Image[];
    interval: number;
    minQuantity: number;
    maxQuantity: number;
    multidimensional: boolean;
    name: string;
    obsoleteFlag: boolean;
    priceRange: any;
    quotationFlag: boolean;
    stock: Stock;
    url: string;
    volumePricesFlag: boolean;
}
export class DiscountPriceData {
    currencyIso: string;
    formattedValue: string;
    priceType: string;
    value: number
}
export class Image {
    format: string;
    imageType: string;
    url: string;
}
export class Stock {
    stockLevel: number;
    stockLevelStatus: string;
}

export class Sort {
    code: string;
    selected: boolean;
}

// {
//     "type": "productCategorySearchPageWsDTO",
//     "breadcrumbs": [],
//     "currentQuery": {
//       "query": {
//         "value": ":name-asc"
//       },
//       "url": "/search?q=%3Aname-asc"
//     },
//     "facets": [
//       {
//         "category": false,
//         "multiSelect": true,
//         "name": "compatibility",
//         "priority": 10000,
//         "values": [
//           {
//             "count": 1,
//             "name": "D1",
//             "query": {
//               "query": {
//                 "value": ":name-asc:compatibility:D1"
//               },
//               "url": "/search?q=%3Aname-asc%3Acompatibility%3AD1"
//             },
//             "selected": false
//           }
//         ],
//         "visible": true
//       },
//       {
//         "category": true,
//         "multiSelect": true,
//         "name": "allCategories",
//         "priority": 6000,
//         "values": [
//           {
//             "count": 1,
//             "name": "ELECTRICAL",
//             "query": {
//               "query": {
//                 "value": ":name-asc:allCategories:ELECTRICAL"
//               },
//               "url": "/search?q=%3Aname-asc%3AallCategories%3AELECTRICAL"
//             },
//             "selected": false
//           }
//         ],
//         "visible": true
//       }
//     ],
//     "freeTextSearch": "",
//     "pagination": {
//       "currentPage": 0,
//       "pageSize": 20,
//       "sort": "name-asc",
//       "totalPages": 1,
//       "totalResults": 8
//     },
//     "products": [
//       {
//         "baseProduct": "50009",
//         "code": "50009_N",
//         "configurable": false,
//         "description": "Circuit breaker 20A 400/440V 2P A12-51 2CDS252001R0204 This spare part is located in the Electrical Cabinet & Ground Cabinet subsystem",
//         "discountPriceData": {
//           "currencyIso": "USD",
//           "formattedValue": "$0.00",
//           "priceType": "BUY",
//           "value": 0.0
//         },
//         "images": [
//           {
//             "format": "product",
//             "imageType": "PRIMARY",
//             "url": "/medias/?context=bWFzdGVyfGltYWdlc3w4OTcxOXxpbWFnZS9qcGVnfGFHTmtMMmhpWWk4NU1EazNPVFkyTlRnek9ETTR8ZmMxMWRkODc5NWQxYmI1NmQ5NGUzODNmNDBiOTM3M2Q5MmM2MGM3ZDY2MjkwZTQ1YmVlNDdhMjE3N2Q1NTBhZg"
//           }
//         ],
//         "interval": 0,
//         "minQuantity": 0,
//         "multidimensional": false,
//         "name": "CIRCUIT BREAKER 20A 400/440V 2P",
//         "obsoleteFlag": false,
//         "priceRange": {},
//         "quotationFlag": true,
//         "stock": {
//           "stockLevel": 0,
//           "stockLevelStatus": "outOfStock"
//         },
//         "url": "/c/CIRCUIT-BREAKER-20A-400-440V-2P/p/50009_N",
//         "volumePricesFlag": false
//       },
//       {
//         "baseProduct": "A9B00053277",
//         "code": "A9B00053277_N",
//         "configurable": false,
//         "description": "Cable Stockinf f Hanging Cable",
//         "discountPriceData": {
//           "currencyIso": "USD",
//           "formattedValue": "$0.00",
//           "priceType": "BUY",
//           "value": 0.0
//         },
//         "images": [
//           {
//             "format": "product",
//             "imageType": "PRIMARY",
//             "url": "/medias/?context=bWFzdGVyfGltYWdlc3wxNTk0MjF8aW1hZ2UvanBlZ3xhR1psTDJoaE1pODVNRGszTnprNU5qWXpOalEyfGEyNGRhYWI1MmM4YjU4YTcwYjJhNTIxYjE1OTUzMDZhMmJlMTdkODQ0N2MxNzcwOTRiMDQyZWU0ZjJkOWU5ZWM"
//           }
//         ],
//         "interval": 0,
//         "minQuantity": 0,
//         "multidimensional": false,
//         "name": "ELEVATOR CABLE COVER",
//         "obsoleteFlag": false,
//         "priceRange": {},
//         "quotationFlag": true,
//         "stock": {
//           "stockLevel": 0,
//           "stockLevelStatus": "outOfStock"
//         },
//         "url": "/c/ELEVATOR-CABLE-COVER/p/A9B00053277_N",
//         "volumePricesFlag": false
//       },
//       {
//         "baseProduct": "A9B00011567",
//         "code": "A9B00011567_N",
//         "configurable": false,
//         "description": "<p>Oil reservoir kit f yaw-gear</p>",
//         "discountPriceData": {
//           "currencyIso": "USD",
//           "formattedValue": "$0.00",
//           "priceType": "BUY",
//           "value": 0.0
//         },
//         "images": [
//           {
//             "format": "product",
//             "imageType": "PRIMARY",
//             "url": "/medias/?context=bWFzdGVyfGltYWdlc3w0ODI0OHxpbWFnZS9qcGVnfGFEQm1MMmhoWmk4NU1EazJPVFkyTWpReU16TTB8ZTIyYmIyODM4ZmU2OTg3Mzg3YWQ4ZGI5ZjEwOTYzM2VhMjllNjFjMjUyNDlhNTY0NjlkMzgzMWVmYmVlNDg5Zg"
//           }
//         ],
//         "interval": 0,
//         "minQuantity": 0,
//         "multidimensional": false,
//         "name": "EXPANSION TANK KIT",
//         "obsoleteFlag": false,
//         "priceRange": {},
//         "quotationFlag": true,
//         "stock": {
//           "stockLevel": 0,
//           "stockLevelStatus": "outOfStock"
//         },
//         "url": "/c/EXPANSION-TANK-KIT/p/A9B00011567_N",
//         "volumePricesFlag": false
//       },
//       {
//         "baseProduct": "A9B00018193",
//         "code": "A9B00018193_N",
//         "configurable": false,
//         "description": "Temperature sensor PT100 PEAS 4456,1 HSS-Bearing rear L=106mm",
//         "discountPriceData": {
//           "currencyIso": "USD",
//           "formattedValue": "$0.00",
//           "priceType": "BUY",
//           "value": 0.0
//         },
//         "images": [
//           {
//             "format": "product",
//             "imageType": "PRIMARY",
//             "url": "/medias/?context=bWFzdGVyfGltYWdlc3w1NDk2MnxpbWFnZS9qcGVnfGFETTNMMmc0TkM4NU1EazRNRFF5TmpBMU5UazR8MjJhNzcyY2RmZTY2NTQyMTJhZDRlMTFmYmIzNDdkOGRlMjAzNGVkYzMyYmMyZWFjYzcyNTg4ZTYyY2U2MWFiNA"
//           }
//         ],
//         "interval": 0,
//         "minQuantity": 0,
//         "multidimensional": false,
//         "name": "PT100 SENSOR L=110MM",
//         "obsoleteFlag": false,
//         "priceRange": {},
//         "quotationFlag": true,
//         "stock": {
//           "stockLevel": 0,
//           "stockLevelStatus": "outOfStock"
//         },
//         "url": "/c/PT100-SENSOR-L%3D110MM/p/A9B00018193_N",
//         "volumePricesFlag": false
//       },
//       {
//         "baseProduct": "A9B00018036",
//         "code": "A9B00018036_N",
//         "configurable": false,
//         "description": "Temperature sensor PT100 1/4\" L=30mm",
//         "discountPriceData": {
//           "currencyIso": "USD",
//           "formattedValue": "$0.00",
//           "priceType": "BUY",
//           "value": 0.0
//         },
//         "images": [
//           {
//             "format": "product",
//             "imageType": "PRIMARY",
//             "url": "/medias/?context=bWFzdGVyfGltYWdlc3w4MjU4OHxpbWFnZS9qcGVnfGFETm1MMmhtTVM4NU1EazNOemsxTXpNNE1qY3d8YWVlNzJjOWMxYzVmZTFjZWU0NzcwMDg5M2M3YTllYjZiY2Y2ZTY4OGJjM2M4YzQyMzhhZWVjZDM4YWY5YmZlMA"
//           }
//         ],
//         "interval": 0,
//         "minQuantity": 0,
//         "multidimensional": false,
//         "name": "PT100 SENSOR L=30MM",
//         "obsoleteFlag": false,
//         "priceRange": {},
//         "quotationFlag": true,
//         "stock": {
//           "stockLevel": 0,
//           "stockLevelStatus": "outOfStock"
//         },
//         "url": "/c/PT100-SENSOR-L%3D30MM/p/A9B00018036_N",
//         "volumePricesFlag": false
//       },
//       {
//         "baseProduct": "A9B00017476",
//         "code": "A9B00017476_N",
//         "configurable": false,
//         "description": "UPS f controler 420VA 230VAC",
//         "discountPriceData": {
//           "currencyIso": "USD",
//           "formattedValue": "$0.00",
//           "priceType": "BUY",
//           "value": 0.0
//         },
//         "images": [
//           {
//             "format": "product",
//             "imageType": "PRIMARY",
//             "url": "/medias/?context=bWFzdGVyfGltYWdlc3w5MzM5N3xpbWFnZS9qcGVnfGFETTBMMmd4WVM4NU1EazJPVGMyTURjeU56TTB8Y2FjZmYyYzZkYzFiOTdjNGE2NmE2YTFhZWE4MGY5ODJmOGQwNmY1MjYyYWNhMDg3OTU0NjFjMTAyYWE4ZDM5ZQ"
//           }
//         ],
//         "interval": 0,
//         "minQuantity": 0,
//         "multidimensional": false,
//         "name": "UPS 420VA 230VAC",
//         "obsoleteFlag": false,
//         "priceRange": {},
//         "quotationFlag": true,
//         "stock": {
//           "stockLevel": 0,
//           "stockLevelStatus": "outOfStock"
//         },
//         "url": "/Electrical/UPS-420VA-230VAC/p/A9B00017476_N",
//         "volumePricesFlag": false
//       },
//       {
//         "baseProduct": "A9B00009743",
//         "code": "A9B00009743_N",
//         "configurable": false,
//         "discountPriceData": {
//           "currencyIso": "USD",
//           "formattedValue": "$0.00",
//           "priceType": "BUY",
//           "value": 0.0
//         },
//         "images": [
//           {
//             "format": "product",
//             "imageType": "PRIMARY",
//             "url": "/medias/?context=bWFzdGVyfGltYWdlc3wzOTI5NnxpbWFnZS9qcGVnfGFHVTVMMmhpT1M4NU1EazJPVGd5TVRZM05UZ3l8NGM3MzE5MWU2ZWNjODg1ZjhjNmRjMjZlNTE1MGEzZWQ3Zjg0YTY0MDNjYzM4YTYyMjI2MWQ0ZWY5ZDkzNzljNw"
//           }
//         ],
//         "interval": 0,
//         "minQuantity": 0,
//         "multidimensional": false,
//         "obsoleteFlag": false,
//         "priceRange": {},
//         "quotationFlag": true,
//         "stock": {
//           "stockLevel": 0,
//           "stockLevelStatus": "outOfStock"
//         },
//         "url": "/c//p/A9B00009743_N",
//         "volumePricesFlag": false
//       },
//       {
//         "baseProduct": "A9B00026257",
//         "code": "A9B00026257_N",
//         "configurable": false,
//         "discountPriceData": {
//           "currencyIso": "USD",
//           "formattedValue": "$0.00",
//           "priceType": "BUY",
//           "value": 0.0
//         },
//         "images": [
//           {
//             "format": "product",
//             "imageType": "PRIMARY",
//             "url": "/medias/?context=bWFzdGVyfGltYWdlc3w1MDkyNHxpbWFnZS9qcGVnfGFEUTNMMmc0Tnk4NU1EazJPVGd3TnpreE16STJ8N2E0YmIxNGJlN2NjY2QwZmJlODc2NDc3Yzg5MGI0ODgxNmUyNTQyNWE0NDA5NTY3NmVjMDBiOTA0Mjk4OGQxYw"
//           }
//         ],
//         "interval": 0,
//         "minQuantity": 0,
//         "multidimensional": false,
//         "obsoleteFlag": false,
//         "priceRange": {},
//         "quotationFlag": true,
//         "stock": {
//           "stockLevel": 0,
//           "stockLevelStatus": "outOfStock"
//         },
//         "url": "/c//p/A9B00026257_N",
//         "volumePricesFlag": false
//       }
//     ],
//     "sorts": [
//       {
//         "code": "name-asc",
//         "selected": true
//       },
//       {
//         "code": "name-desc",
//         "selected": false
//       }
//     ]
// }