<div class="container-fluid invite-user-form-container">
	<form [formGroup]="inviteForm">
		<div class="form-row">
			<div *ngIf="userType.value === 'customer' || this.role !=='SGRE Admin'" class=" col-lg-6">
				<label for="" class="request-label required">
					{{"inviteUser.firstName" |cxTranslate}}
				</label>
				<input type="text" class="form-control request-data inp-element" formControlName="firstName" width="85%"
					[ngClass]="{'formControlError':(firstNameError)}">
				<span *ngIf="firstNameError" style="color:red">
					{{"inviteUser.invalidFirstName" |cxTranslate}}
				</span>
			</div>
		</div>
		<div class="form-row">
			<div *ngIf="userType.value === 'customer' || this.role !=='SGRE Admin'" class="form-group col-lg-6 mt-3">
				<label for="" class="request-label required">
					{{"inviteUser.lastName" |cxTranslate}}
				</label>
				<input type="text" class="form-control request-data inp-element"
					[ngClass]="{'formControlError':(lastNameError)}" formControlName="lastName">
				<span *ngIf="lastNameError" style="color:red">
					{{"inviteUser.invalidLastName" |cxTranslate}}
				</span>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-lg-6">
				<label for="" class="request-label required">
					{{"inviteUser.email" |cxTranslate}}
				</label>
				<input type="text" class="form-control request-data inp-element"
					[ngClass]="{'formControlError':(emailError || emailErrormsg)}" formControlName="email">
				<span *ngIf="emailError || emailErrormsg" style="color:red">
					{{emailErrormsg}}
					<!-- {{(emailErrormsg ? emailErrormsg : "inviteUser.invalidEmail")|cxTranslate}} -->
				</span>
			</div>
		</div>
		<div *ngIf="role === 'SGRE Admin'" class="form-row">
			<div class="form-group col-lg-6">
				<label for="" class="request-label required">
					{{"inviteUser.userType" |cxTranslate}}
				</label>
				<div class="option-container">
					<p-radioButton styleClass="custom-radioBtn" formControlName="userType" name="userType" value="SGRE"
						(onClick)="clearTable()" />
					<span>{{"inviteUser.sgreUser" |cxTranslate}}</span>
				</div>
				<div class="option-container">
					<p-radioButton styleClass="custom-radioBtn" formControlName="userType" name="userType"
						value="customer" (onClick)="userTypeChange('customer')" />
					<span>{{"inviteUser.customerUser" |cxTranslate}}</span>
				</div>
			</div>
		</div>
		<div *ngIf="userType.value !== 'customer' && this.role === 'SGRE Admin'" class="form-row">
			<div class="form-group col-lg-6">
				<label for="" class="request-label required">
					{{"inviteUser.userRole" |cxTranslate}}
				</label>
				<div class="option-container">
					<p-radioButton styleClass="custom-radioBtn" formControlName="userRole" name="userRole"
						value="sales" />
					<span>{{"inviteUser.salesRepresentative" |cxTranslate}}</span>
				</div>
				<div class="option-container">
					<p-radioButton styleClass="custom-radioBtn" formControlName="userRole" name="userRole"
						value="administrator" />
					<span>{{"inviteUser.administrator" |cxTranslate}}</span>
				</div>
			</div>
		</div>
		<div *ngIf="userType.value === 'customer' && this.role === 'SGRE Admin'" class="form-group company-cls col-lg-6">
			<label for="" class="request-label required">
				{{'inviteUser.company' | cxTranslate}}
			</label>
			<div>
				<p-dropdown [options]="companyData" [filter]="true"
					[styleClass]="companyError ? 'border-red-500 cstm-dropdown' : 'border-round-md cstm-dropdown'"
					(onChange)="changeCompany($event)" optionLabel="name" optionValue="name" filterBy="name"
					class="dropdon-cls" placeholder="{{'inviteUser.selectACompany' | cxTranslate}}"
					formControlName="company" required>
				</p-dropdown>
			</div>
			<span *ngIf="companyError" style="color:red">
				{{"inviteUser.invalidCompany" |cxTranslate}}
			</span>
		</div>
		<div *ngIf="this.role !=='SGRE Admin'" class="form-row">
			<div class="form-group col-lg-6">
				<label for="" class="request-label required">
					{{"inviteUser.company" |cxTranslate}}
				</label>
				<div class="option-container">
					<p-radioButton styleClass="custom-radioBtn" formControlName="companyExt" name="companyExt"
						value="myCompany" (onClick)="radioValue('myCompany')" />
					<span>{{"inviteUser.UserIsEmployed" |cxTranslate}}</span>
				</div>
				<div class="option-container">
					<p-radioButton styleClass="custom-radioBtn" formControlName="companyExt" name="companyExt"
						value="anotherCom" (onClick)="radioValue('anotherCom')" />
					<span>{{"inviteUser.UserIsEmployedAnotherCompany" |cxTranslate}}</span>
				</div>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-lg-12">
				<div *ngIf=" userType.value === 'SGRE' && inviteForm.get('userRole').value === 'sales' && this.role === 'SGRE Admin'"
					class="inviteUser-table-container">
					<label for="" class="request-label required">
						{{"inviteUser.roles" | cxTranslate}}
					</label>
					<label *ngIf="arrayFlag1" for="" style="color: red;">
						{{"inviteUser.leastRightsToOneLegalEntity" | cxTranslate}}
					</label>
					<app-table-template [columns]="columns" [tableData]="cartsList"
						(checkBtnClickEvent)="removeRow($event)">
					</app-table-template>
				</div>
				<div *ngIf="userType.value === 'customer' || this.role !=='SGRE Admin'"
					class="inviteUser-table-container">
					<label for="" class="request-label required">
						{{"inviteUser.roles" | cxTranslate}}
					</label>
					<label *ngIf="arrayFlag2" for="" style="color: red;">
						{{"inviteUser.leastRightsToOneLegalEntity" | cxTranslate}}
					</label>
					<app-table-template [columns]="column1" [tableData]="cartsList"
						(checkBtnClickEvent)="checkEvent($event)">
					</app-table-template>
				</div>
			</div>
		</div>
		<div class="form-row mb-4 px-0">
			<div *ngIf="userType.value==='customer'||userType.value==='SGRE' && inviteForm.get('userRole').value==='sales'"
				class="form-group col-lg-12">
				<div class="col-lg-6 d-flex px-0 legal-entity-container align-items-center"
					style="height: fit-content;">
					<p-dropdown styleClass="cstm-dropdown" [options]="legalentities" [filter]="true" optionLabel="name"
						filterBy="name" optionValue="uid" class="dropdon-cls" panelStyleClass="minWidthOverride"
						placeholder="{{'inviteUser.selectALegalEntity' |cxTranslate}}" formControlName="addValues">
					</p-dropdown>
					<button (click)="addEntity()" type="button" class="btn cstm-btn-primary ml-2 addBtn pb-1"
						label="Add">
						{{"inviteUser.add" |cxTranslate}}
					</button>
				</div>
			</div>
		</div>
		<div class="form-row mb-3">
			<div class="form-group">
				<button (click)="submitInviteUser()" type="submit" class="btn cstm-btn-primary mb-2"
					style="margin-left: 7px;">
					{{"inviteUser.inviteUser" |cxTranslate}}
				</button>
			</div>
		</div>
	</form>
</div>

<!-- Modal -->
<div [ngStyle]={display:this.display} class="modal" id="exampleModal" tabindex="-1" role="dialog" data-backdrop="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h1 class="modal-title">
					{{"inviteUser.confirmation" |cxTranslate}}
				</h1>
				<button (click)="onCloseHandled()" type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body" style="overflow:hidden;">
				<div *ngIf="isSuccess==2" class="custom-align-center">
					<div class="mr-2">
						<i class="pi pi-exclamation-circle confirmation-icon failure-icon"></i>
						<!-- <img src="../../../../../assets/images_and_icons/mark.png" style="width: 25px;height: 25px;"> -->
					</div>
					<div>
						{{this.emailErrormsg}}
						<!-- {{(emailErrormsg ? emailErrormsg : "inviteUser.invalidEmail") |cxTranslate}} -->
					</div>
				</div>
				<div *ngIf="isSuccess==1" class="custom-align-center">
					<div class="mr-2">
						<i class="pi pi-check-circle confirmation-icon success-icon"></i>
						<!-- <img src="../../../../../assets/images_and_icons/check.png" style="width: 25px;height: 25px;"> -->
					</div>
					<div>{{"inviteUser.invitationSent" |cxTranslate}}</div>
				</div>
			</div>
			<div class="modal-footer justify-content-start">
				<button (click)="onCloseHandled()" type="button" class="btn cstm-btn-primary" data-dismiss="modal">
					{{"inviteUser.ok" |cxTranslate}}
				</button>
			</div>
		</div>
	</div>
</div>