import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, take, tap, switchMap } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { AuthToken, AuthRedirectService, UserIdService, AuthStorageService } from '@spartacus/core';
import { WindowRef } from '@spartacus/core';
import { selectDefaultLegalEntity } from '../../SGRE-shared/services/storage.state';
import { Store,select } from '@ngrx/store';
@Injectable({
  providedIn: 'root'
})

export class BaseService {

  _httpUrl: string;
  _tokenUrl: string;
  _serviceAuthToken: string;
  _token: string;
  _respHeaders: HttpHeaders;
  _http: HttpClient;
  _router: Router;
  selectedLegalEntitySession: any;

  constructor(
    protected http: HttpClient,
    @Inject(String) protected httpUrl: string,
    protected router: Router,
    protected storageService: StorageService,
    protected userIdService: UserIdService,
    protected authStorageService: AuthStorageService,
    protected authRedirectService: AuthRedirectService,
    private windowRef: WindowRef,
    protected store: Store
  ) {
    this._http = this.http;
    this._httpUrl = this.httpUrl;
  }

  /* PostLogin(paramVal: string, jsonObj: JSON): Observable<any> {
    this._loader.increaseHttprequestCount();
    return this._http.post(this._httpUrl + paramVal, JSON.stringify(jsonObj),
      { headers: this.getHeaders(), observe: 'response' }).pipe(
        map((response) => {
          // login successful if there's a jwt token in the response
          this._respHeaders = response.headers;
          const token = this._respHeaders.get('token');
          this._loader.decreaseHttprequestCount();
          if (token && response.body['invalidLogin'] && response.body['invalidLogin'].toLowerCase() === 'valid') {
            // store username and jwt token in local storage to keep user logged in between page refreshes 
            // sessionStorage.setItem('currentUserToken', JSON.stringify({ username: username, token: token })); 
            sessionStorage.setItem('currentUserToken', token);
            // this.initTimer();
            // return true to indicate successful login
            return response.body;
          } else {
            // return false to indicate failed login 
            return response.body;
          }
        }), catchError((error) => {
          this._loader.decreaseHttprequestCount(error);
          throw error;
        }));
  } */

  PostToken(paramVal: string, postObj: HttpParams): Observable<any> {
    return this._http.post<AuthToken & { expires_in?: number }>(paramVal, postObj).pipe(
      catchError((error) => {
        return throwError(() => error)
      })
    );
  }

  Post(paramVal: string, jsonObj: JSON): Observable<any> {
    return this._http.post(this._httpUrl + paramVal, JSON.stringify(jsonObj),
      { headers: this.getHeaders(), observe: 'response' })
      .pipe(
        catchError(error =>
          throwError(() => error)
        )
      )
  }

  PostDocument(paramVal: string, fileData: any): Observable<any> {
    return this._http.post(this._httpUrl + paramVal, fileData,
      { headers: this.getHeaders(), observe: 'response' })
      .pipe(
        catchError(error =>
          throwError(() => error)
        )
      );
  }

  /* POSTTEXT(paramVal: string, jsonObj: JSON): Observable<any> {
    this._loader.increaseHttprequestCount();
    if (jsonObj && jsonObj['sortBy'] && jsonObj['sortBy'].endsWith('String')) {
      jsonObj['sortBy'] = jsonObj['sortBy'].replace('String', '');
    }
    return this._http.post(this._httpUrl + paramVal,
      JSON.stringify(jsonObj), { headers: this.getHeaders(), responseType: 'text', observe: 'response' }).pipe(
        map((response) => {
          const headers = response.headers;
          const token = headers.get('token');
          if (token) {
            sessionStorage.setItem('currentUserToken', token);
            // this.initTimer();
          }
          this._loader.decreaseHttprequestCount();
          const err = headers.get('errormsg');
          if (err) {
            return response;
          } else {
            return response.body;
          }
        }), catchError((error) => {
          this._loader.decreaseHttprequestCount(error);
          throw error;
        }));
  } */


  GetIPAddress(): Observable<any> {
    return this._http.get("https://api.ipify.org/?format=json", { headers: this.getLoginHeaders(), observe: 'response' }).pipe(
      map((res) => {
        return res.body;
      }), catchError((error) => {
        throw error;
      }));
  }

  GetToken(methodName: string): Observable<any> {
    const name = this._httpUrl + methodName;
    return this._http.get(name, { headers: this.getLoginHeaders(), observe: 'response' }).pipe(
      map((res) => {
        return res.body;
      }),
      catchError((error) => {
        console.log('Error Came from Token-api');
        throw error;
      })
    );
  }

  Get(paramVal: string): Observable<any> {
    const name = this._httpUrl + paramVal;
    return this._http.get(name, { headers: this.getHeaders(), observe: 'response' }).pipe(
      map((res) => {
        return res.body;
      }), catchError(error =>
        throwError(() => error)
      )
    );
  }

  GetParamsHeaders(paramVal: string, params: any, headers: any = {}): Observable<any> {
    const name = this._httpUrl + paramVal;
    let httpParams = new HttpParams();
    let httpHeaders = this.getHeaders();

    Object.keys(params)?.forEach(key => {
      httpParams = httpParams.append(key, params[key]);
    });
    Object.keys(headers)?.forEach(key => {
      httpHeaders = httpHeaders.append(key, headers[key]);
    });

    this.store.pipe(select(selectDefaultLegalEntity)).pipe(
      take(1), // Take the first value emitted and complete
      tap((defaultLegalEntity) => {
        if (defaultLegalEntity?.uid) {
          httpHeaders = httpHeaders.append("legalEntity", defaultLegalEntity.uid);
        }
      })
    ).subscribe();

    return this._http.get(name, { params: httpParams, headers: httpHeaders, observe: 'response' })
      .pipe(
        map((res) => {
          return res.body;
        }), catchError(error =>
          throwError(() => error)
        )
      );
  }

  /** Below code are used for Testing purposes */
  // let legalEntityVal = this.checkAndPassLegalEntity(headers);
  // if (legalEntityVal?.length > 0) {
  //   httpHeaders = httpHeaders.append("legalEntity", legalEntityVal);
  // }
  // checkAndPassLegalEntity(headerObj: { [k: string]: any }): string {
  //   let str: string = null;
  //   let flag: boolean = true;

  //   if (headerObj && Object.keys(headerObj).length > 0) {
  //     for (const [key, value] of Object.entries(headerObj)) {
  //       if (key === 'legalEntityValue' && value?.length > 0) {
  //         str = value;
  //       }
  //       if (key === 'isLegalEntityHeader' && value === false) {
  //         flag = false;
  //       }
  //     }
  //   }
  //   if (flag && this.storageService?.defaultLegalEntity?.uid) {
  //     str = this.storageService.defaultLegalEntity?.uid;
  //   }

  //   return str;
  // }

  GetParamsHeadersUserProfile(paramVal: string, params: any, headers: any = {}, legalEntity?: any): Observable<any> {
    const name = this._httpUrl + paramVal;
    let httpParams = new HttpParams();
    Object.keys(params)?.forEach(key => {
      httpParams = httpParams.append(key, params[key]);
    });
    let httpHeaders = this.getHeaders();
    Object.keys(headers)?.forEach(key => {
      httpHeaders = httpHeaders.append(key, headers[key]);
    });
    if (legalEntity)
      httpHeaders = httpHeaders.append("legalEntity", legalEntity);
    return this._http.get(name, { params: httpParams, headers: httpHeaders, observe: 'response' }).pipe(
      map((res) => {
        return res.body;
      }), catchError(error =>
        throwError(() => error)
      )
    );
  }

  PutParamsHeadersUserProfile(paramVal: string, requestObject?: any, params?: any, legalEntity?: any, headers: any = {}): Observable<any> {
    const name = this._httpUrl + paramVal;
    let httpParams = new HttpParams();
    let httpHeaders = this.getHeaders();
    Object.keys(headers)?.forEach(key => {
      httpHeaders = httpHeaders.append(key, headers[key]);
    });
    if (legalEntity)
      httpHeaders = httpHeaders.append("legalEntity", legalEntity);
    return this._http.put(name, requestObject, { params: httpParams, headers: httpHeaders });
  }

  GetParamsHeadersMyProfile(paramVal: string, params: any, headers: any = {}): Observable<any> {
    const name = this._httpUrl + paramVal;
    let httpParams = new HttpParams();
    let httpHeaders = this.getHeaders();

    Object.keys(params)?.forEach(key => {
      httpParams = httpParams.append(key, params[key]);
    });
    Object.keys(headers)?.forEach(key => {
      httpHeaders = httpHeaders.append(key, headers[key]);
    });

    return this._http.get(name, { params: httpParams, headers: httpHeaders, observe: 'response' }).pipe(
      map((res) => {
        return res.body;
      }), catchError(error =>
        throwError(() => error)
      )
    );
  }

  GetWithParamsAndHeaders(urlVal: string, headers?: HttpHeaders, params?: HttpParams) {
    const name = this._httpUrl + urlVal;
    const header: HttpHeaders = this.getHeaders();
    const param: HttpParams = params;

    if (headers && Object.keys(headers).length > 0) {
      for (const key of Object.keys(headers)) {
        header.append(key, header[key]);
      }
    }

    return this._http.get(name, { headers: header, params: param, observe: 'response' }).pipe(
      map((res) => {
        return res.body;
      }), catchError(error =>
        throwError(() => error)
      )
    );
  }

  PostParamsHeaders(paramVal: string, params: any, headers: any = {}, requestObject: any = {}, allowLegalEntity = true): Observable<any> {
    const name = this._httpUrl + paramVal;
    let httpParams = new HttpParams();
    let httpHeaders = this.getHeaders();

    Object.keys(params)?.forEach(key => {
      httpParams = httpParams.append(key, params[key]);
    });
    Object.keys(headers)?.forEach(key => {
      httpHeaders = httpHeaders.append(key, headers[key]);
    });
    if (allowLegalEntity) {
      this.store.pipe(select(selectDefaultLegalEntity)).pipe(
        take(1), // Take the first emitted value and complete
        tap(defaultLegalEntity => {
          if (defaultLegalEntity?.uid) {
            httpHeaders = httpHeaders.append("legalEntity", defaultLegalEntity.uid);
          }
        })
      ).subscribe();
    }    
    return this._http.post(name, requestObject, { params: httpParams, headers: httpHeaders, observe: 'response' });
  }

  PostParamsMyProfileHeaders(paramVal: string, requestObject?: any, params?: any, headers: any = {}): Observable<any> {
    const name = this._httpUrl + paramVal;
    let httpParams = new HttpParams();
    let httpHeaders = this.getHeaders();

    Object.keys(headers)?.forEach(key => {
      httpHeaders = httpHeaders.append(key, headers[key]);
    });

    return this._http.post(name, requestObject, { params: httpParams, headers: httpHeaders });
  }

  PutParamsHeaders(paramVal: string, requestObject?: any, params?: any, headers: any = {}): Observable<any> {
    const name = this._httpUrl + paramVal;
    let httpParams = new HttpParams();
    let httpHeaders = this.getHeaders();
  
    Object.keys(headers)?.forEach(key => {
      httpHeaders = httpHeaders.append(key, headers[key]);
    });
    this.store.pipe(select(selectDefaultLegalEntity)).pipe(
      take(1), // Take the first emitted value and complete
      tap(defaultLegalEntity => {
        if (defaultLegalEntity?.uid) {
          httpHeaders = httpHeaders.append("legalEntity", defaultLegalEntity.uid);
        }
      })
    ).subscribe();
      // Return the HTTP PUT request as an Observable
    return this._http.put(name, requestObject, { params: httpParams, headers: httpHeaders })
  }

  PutorderParamsHeaders(paramVal: string, params?: any, headers: any = {}): Observable<any> {
    const name = this._httpUrl + paramVal;
    let httpParams = new HttpParams();
    let httpHeaders = this.getHeaders();

    Object.keys(headers)?.forEach(key => {
      httpHeaders = httpHeaders.append(key, headers[key]);
    });

    return this._http.put(name, { params: httpParams, headers: httpHeaders });
  }

  DeleteParamsHeaders(paramVal: string, requestObject?: any): Observable<any> {
    const name = this._httpUrl + paramVal;
    return this._http.delete(name, requestObject);
  }

  PostParamsOrderdetails(paramVal: string, headers: any = {}, comment: any): Observable<any> {
    const name = this._httpUrl + paramVal;
    let httpParams = new HttpParams();
    let httpHeaders = this.getHeaders();

    Object.keys(headers)?.forEach(key => {
      httpHeaders = httpHeaders.append(key, headers[key]);
    });

    return this._http.post(name, { headers: httpHeaders, comment: comment });
  }

  PostParamsRecreates(paramVal: string, headers: any = {}): Observable<any> {
    const name = this._httpUrl + paramVal;
    let httpParams = new HttpParams();
    let httpHeaders = this.getHeaders();

    Object.keys(headers)?.forEach(key => {
      httpHeaders = httpHeaders.append(key, headers[key]);
    });
    return this._http.post(name, { headers: httpHeaders });
  }

  /* GetImage(paramVal: string): Observable<any> {
    return this._http.get(this._httpUrl + paramVal 
      + ((this._httpUrl + paramVal).indexOf('?') >= 0 ? '&et-' : '?et-') + new Date().toString(),
      { headers: this.getHeaders(), responseType: 'text', observe: 'response' }).pipe(
        map((res: any) => {
          if (res && res.headers) {
            const token = res.headers.get('token');
            if (token) {
              sessionStorage.setItem('currentUserToken', token);
              // this.initTimer();
            }
          }
          return res.body;
        }));
  }

  // If we are only going to keep one of these get methods, please consider that this one provides type safety through generics
  // and follows the standard method naming conventions where as the other one does neither of those things.
  get<T>(paramVal: string): Observable<T> {
    return this._http.get<T>(this._httpUrl + paramVal +
      ((this.httpUrl + paramVal).indexOf('?') >= 0 ? '&et' : '?et=')
      + new Date().toString(), { headers: this.getHeaders(), observe: 'response' }).pipe(map((res: any) => {
        if (res && res.headers) {
          const token = res.headers.get('token');
          if (token) {
            sessionStorage.setItem('currentUserToken', token);
            // this.initTimer();
          }
        }
        return res.body;
      }));
  } */

  /* GetText(paramVal: string): Observable<any> {
    this._loader.increaseHttprequestCount();
    return this._http.get(this._httpUrl + paramVal + ((this._httpUrl + paramVal).indexOf('?') >= 0 ? '&et-' '?et-') +
      new Date().toString(),
      { headers: this.getHeaders(), responseType: 'text', observe: 'response' }).pipe(
        map((response) => {
          this._loader.decreaseHttprequestCount();
          const headers = response.headers;
          const token = headers.get('token');
          if (token) {
            sessionStorage.setItem('currentUserToken', token);
            // this.initTimer();
          }
          // tslint:disable-next-line:quotemark 
          const err = headers.get("Eligible");
          if (err) {
            return response;
          } else {
            return response.body;
          }
        }));
  } */

  /* PostWithProgress(paramVal: string, jsonObj: JSON): Observable<any> {
    this._loader.increaseHttprequestCount();
    const req = new HttpRequest('POST', this._httpUrl + 'idd/saveUpdateDocument',
      JSON.stringify(jsonObj), { headers: this.getHeaders(), reportProgress: true });
    return this._http.request(req).pipe(map((event) => {
      if (4 === event.type && event instanceof HttpResponse) {
        this._loader.decreaseHttprequestCount();
        if (event && event.headers) {
          const token = event.headers.get('token');
          if (token) {
            sessionStorage.setItem('currentUserToken', token);
            // this.initTimer();
          }
        }
      }
      return event;
    }), catchError((error) => {
      this._loader.decreaseHttprequestCount();
      throw error;
    }));
  } */

  /* PostWithProgressEx(paramVal: string, jsonObj: JSON): Observable<any> {
    this._loader.increaseHttprequestCount();
    const req = new HttpRequest('POST', this._httpUrl + paramVal,
      jsonObj, { headers: this.getUploadHeaders(), reportProgress: true });
    return this._http.request(req).pipe(map((event) => {
      if (4 === event.type && event instanceof HttpResponse) {
        this._loader.decreaseHttprequestCount();
        if (event && event.headers) {
          const token = event.headers.get('token');
          if (token) {
            sessionStorage.setItem('currentUserToken', token);
            // this.initTimer();
          }
        }
      }
      return event;
    }), catchError((error) => {
      this._loader.decreaseHttprequestCount();
      throw error;
    }));
  } */

  GetDownload(paramVal: string, fileName: string) {
    // this._loader.increaseHttprequestCount();
    this.http.get(this._httpUrl + paramVal, { headers: this.getDownloadHeaders(), responseType: 'blob', observe: 'response' })
      .subscribe({
        next: (res) => {
          // return res;
          this.startDownload(res, fileName);
        }, error: (err) => {
          // this._loader.decreaseHttprequestCount();
          alert('Unable to download this document.');
        }
      });
  }

  /* GetPreview(paramVal: string) {
    this._loader.increaseHttprequestCount();
    this._http.get(this._httpUrl + paramVal + ((this._httpUrl + paramVal).indexOf('?') >= 0 ? '&et=' : '?et=') +
      new Date().toString(), { headers: this.getDownloadHeaders(), responseType: 'blob', observe: 'response' })
      .subscribe(res => {
        this._loader.decreaseHttprequestCount();
        if (res && res.headers) {
          const token = res.headers.get('token');
          if (token) {
            sessionStorage.setItem('currentUserToken', token);
            // this.initTimer();
          }
        }
        this.startPreview(res);
      }, error => {
        this._loader.decreaseHttprequestCount();
        alert('Unable to download this document.');
      });
  } */

  /* PostDownload(jsonObj: any, paramVal: string) {
    this._loader.increaseHttprequestCount();
    this._http.post(this._httpUrl + paramVal, JSON.stringify(jsonObj),
      { headers: this.getHeaders(), responseType: 'blob', observe: 'response' }).subscribe(res => {
        this._loader.decreaseHttprequestCount();
        if (res && res.headers) {
          const token = res.headers.get('token');
          if (token) {
            sessionStorage.setItem('currentUserToken', token);
            // this.initTimer(); 
          }
        }
        this.startDownload(res);
      }, error => {
        this._loader.decreaseHttprequestCount();
        alert('Unable to download this document.');
      });
  } */

  /* PostPreview(jsonObj: any, paramVal: string) {
    this._http.post(this._httpUrl + paramVal, jsonObj,
      { headers: this.getDownloadHeaders(), responseType: 'blob', observe: 'response' }).subscribe(res => {
        if (res && res.headers) {
          const token = res.headers.get('token');
          if (token) {
            sessionStorage.setItem('currentUserToken', token);
            // this.initTimer();
          }
        }
        this.startPreview(res);
      }, error => {
        alert('Unable to preview this document.');
      })
  } */

  /* PostPrint(jsonObj: any, paramVal: string) {
    this._http.post(this._httpUrl + paramVal, jsonObj,
      { headers: this.getDownloadHeaders(), responseType: 'blob', observe: 'response' }).subscribe(res => {
        if (res && res.headers) {
          const token = res.headers.get('token');
          if (token) {
            sessionStorage.setItem('currentUserToken', token);
            // this.initTimer();
          }
        }
        this.startPrint(res);
      }, error => {
        alert('Unable to preview this document.');
      });
  } */

  /* GetPrint(paramVal: string) {
    this._http.get(this._httpUrl + paramVal + ((this._httpUrl + paramVal).indexOf('?') >= 0 ? '&et=' : '?et=') +
      new Date().toString(), { headers: this.getDownloadHeaders(), responseType: 'blob', observe: 'response' })
      .subscribe(res => {
        if (res && res.headers) {
          const token = res.headers.get('token');
          if (token) {
            sessionStorage.setItem('currentUserToken', token);
            // this.initTimer();
          }
        }
        this.startPrint(res);
      }, error => {
        alert('Unable to download this document.');
      });
  } */




  private getLoginHeaders() {
    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        Accept: '*/*',
        'Access-Control-Allow-Origin': '*'
      });
    return headers;
  }

  private getHeaders() {
    let tokenVal: string;
    this.authStorageService.getToken().subscribe((data: AuthToken) => tokenVal = data.access_token);
    const authVal = (tokenVal?.length) ? ('Bearer ' + tokenVal) : '';

    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Accept': '*/*',
      }
    );
    if (authVal?.length > 0) {
      headers.append('Authorization', authVal)
    }
    return headers;
  }

  private getTokenHeaders() {
    const headers = new HttpHeaders(
      {
        'Authorization': 'Basic ' + btoa('HZSelfService: selfservicekey'),
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: '*/*',
        'Access-Control-Allow-Origin': '*'
      });
    return headers;
  }

  /* private startPreview(res) {
    const headers = res.headers;
    const contentDispositionHeader = headers.get('Content-Disposition');
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(res.body, this.getFileName(contentDispositionHeader));
    } else {
      const href = window.URL.createObjectURL(res.body);
      const n = window.open(href, '_blank', '_blank', false);
      if (n === null) {
        window.alert('Please enable popup to preview in new window');
        return true;
      }
      return false;
    }
  } */

  /* private startPrint(res) {
    const headers = res.headers;
    const contentDispositionHeader = headers.get('Content-Disposition');
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(res.body, this.getFileName(contentDispositionHeader));
    } else {
      const blobUrl = URL.createObjectURL(res.body);
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = blobUrl;
      document.body.appendChild(iframe);
      iframe.contentWindow.print();
    }
  } */

  private getDownloadHeaders() {
    // this._token = (sessionStorage.getItem('currentUserToken')) ? sessionStorage.getItem('currentUserToken') : '';
    // responseType: 'blob'
    const authVal = (sessionStorage.getItem('jwtTokenVal')) ? ('Bearer' + sessionStorage.getItem('jwtTokenVal')) : null;
    const headers = new HttpHeaders(
      {
        // 'Authorization': this._serviceAuthToken,
        'iv-token': authVal,
        Accept: '*/*',
        // 'token': this._token
      });
    return headers;
  }

  private startDownload(res, fileName: string) {
    const headers = res.headers;
    const contentDispositionHeader = headers.get('Content-Disposition');
    // @ts-ignore
    if (this.windowRef.nativeWindow.navigator.msSaveOrOpenBlob) {
      // @ts-ignore
      this.windowRef.nativeWindow.navigator.msSaveOrOpenBlob(res.body, fileName);
    } else {
      let is_chrome = false;
      let is_safari = false;
      if (this.windowRef.nativeWindow && this.windowRef.nativeWindow.navigator && this.windowRef.nativeWindow.navigator.userAgent) {
        is_chrome = this.windowRef.nativeWindow.navigator.userAgent.indexOf('Chrome') > -1;
        is_safari = this.windowRef.nativeWindow.navigator.userAgent.indexOf('Safari') > -1;
        if (is_chrome && is_safari) {
          is_safari = false;
        }
      } else {
        is_chrome = true;
        is_safari = false;
      }

      if (is_safari) {
        // this.startPreview(res);
      } else {
        const url = (this.windowRef.nativeWindow as any).URL.createObjectURL(res.body);
        const a = this.windowRef.document.createElement('a');
        this.windowRef.document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = fileName;
        a.click();
        (this.windowRef.nativeWindow as any).URL.revokeObjectURL(url);
        a.remove();
      }
    }
  }

  private getUploadHeaders() {
    // this._token = (sessionStorage.getItem('currentUserToken')) ? sessionStorage.getItem('currentUserToken') : '';
    const headers = new HttpHeaders(
      {
        // 'Authorization': this._serviceAuthToken,
        'Accept': '*/*',
        'Access-Control-Allow-Origin': '*',
        // 'token': this._token
      });
    return headers;
  }

  private getFileName(contentDispositionHeader) {
    const result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
    return result.replace(/"/g, '');
  }

  private getFileHeaders() {
    const authVal = (sessionStorage.getItem('jwtTokenVal')) ? ('Bearer' + sessionStorage.getItem('jwtTokenVal')) : null;
    const headers = new HttpHeaders(
      {
        'iv-token': authVal,
        Accept: '*/*'
      });
    return headers;
  }

  // private getHeaders() {
  //   // Append JWT token to all requests
  //   this._token = (sessionStorage.getItem('currentUserToken')) ? sessionStorage.getItem('currentUserToken') : '';
  //   if (this._token) {
  //     const headers = new HttpHeaders(
  //       {
  //         'Authorization': this._serviceAuthToken,
  //         'Content-Type': 'application/json',
  //         'Accept': '*/*',
  //         'Access-Control-Allow-Origin': '*',
  //         'token': this._token, // 'fayazHardcodedToken'
  //       });
  //     return headers;
  //   }
  //   if (!this._token) {
  //     const headers = new HttpHeaders(
  //       {
  //         'Authorization': this._serviceAuthToken,
  //         'Content-Type': 'application/json',
  //         'Accept': '*/*',
  //         'Access-Control-Allow-Origin': '*'
  //       });
  //     return headers;
  //   }
  // }

  // Validating token error
  /* validateToken(response) {
    if (response.status === 500 && response.exception === 'io.jsonwebtoken.JwtException') {
      this.router.navigate(['/individual/individuallogin']);
    }
  }

  errorHandler(error) {
    this.loaderService.display(false);
  }

  initTimer() {
    this.timeoutService.startTimer();
  } */

}
