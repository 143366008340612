import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageSlotModule } from '@spartacus/storefront';
import { ConfigModule, I18nModule } from '@spartacus/core';
import { FormsModule } from '@angular/forms';
import { SGRESharedModule } from '../../SGRE-shared/SGRE-shared.module';
import { NgMagnizoomModule } from 'ng-magnizoom';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
  TranslateStore,
} from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { HttpBackend } from '@angular/common/http';

import { CustomRouteGuard } from '../../SGRE-shared/services/guard/msal.guard';

import { ProductImagesComponent } from './components/product-images/product-images.component';
import { ProductIntroductionComponent } from './components/product-introduction/product-introduction.component';
import { ProductVariantComponent } from './components/product-variant/product-variant.component';
import { ProductSumPriceComponent } from './components/product-sum-price/product-sum-price.component';
import { AddToCartComponent } from './components/add-to-cart/add-to-cart.component';
import { ProductDescriptionComponent } from './components/product-description/product-description.component';
import { CartBaseModule } from '@spartacus/cart/base';

export function HttpLoaderFactory(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, ['/assets/i18n/core/', '/assets/i18n/vendors/']);
}
@NgModule({
  declarations: [
    ProductImagesComponent,
    ProductIntroductionComponent,
    ProductVariantComponent,
    ProductSumPriceComponent,
    AddToCartComponent,
    ProductDescriptionComponent,

  ],
  imports: [
    CommonModule,
    PageSlotModule,
    FormsModule,
    SGRESharedModule,
    NgMagnizoomModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductImagesComponent: {
          component: () => import('./components/product-images/product-images.component').then(m => m.ProductImagesComponent),
          guards: [CustomRouteGuard]
        },
        ProductVariantSelectorComponent: {
          component: () => import('./components/product-variant/product-variant.component').then(m => m.ProductVariantComponent),
          guards: [CustomRouteGuard]
        },
        ProductSummaryComponentPrice: {
          component: () => import('./components/product-sum-price/product-sum-price.component').then(m => m.ProductSumPriceComponent),
          guards: [CustomRouteGuard]
        },
        ProductAddToCartComponent: {
          component: () => import('./components/add-to-cart/add-to-cart.component').then(m => m.AddToCartComponent),
          guards: [CustomRouteGuard]
        },
        ProductIntroComponent: {
          component: () => import('./components/product-introduction/product-introduction.component').then(m => m.ProductIntroductionComponent),
          guards: [CustomRouteGuard]
        },
        ProductSummaryComponentDescription: {
          component: () => import('./components/product-description/product-description.component').then(m => m.ProductDescriptionComponent),
          guards: [CustomRouteGuard]
        }
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    I18nModule,
    CartBaseModule
  ],
  providers: [
    TranslateService,
    { provide: TranslateStore }
  ]
})
export class ProductDetailsPageModule { }