import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { Subject, takeUntil } from 'rxjs';
import { selectDateFormat } from '../../../../SGRE-shared/services/storage.state';
import { Store,select } from '@ngrx/store';
@Component({
  selector: 'app-order-history-search-box',
  templateUrl: './order-history-search-box.component.html',
  styleUrl: './order-history-search-box.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderHistorySearchBoxComponent {
  public labels = {
    search: "Search",
    dateFrom: "Date From",
    dateTo: "Date To",
    placeholder: "Enter SGRE SO or Company PO"
  }
  public orderHistoryForm: FormGroup;
  public searchSuggestions: any[];
  public searchQuery;
  public fromDate: Date;
  public toDate: Date;
  public minDate: Date;
  public maxDate: Date;
  userdateFormat: any;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private globalService: GlobalService,
    private changeRef: ChangeDetectorRef,
    private store: Store
  ) {
    const currentDate = new Date();
    this.toDate = new Date(currentDate.toISOString().split('T')[0]);
    this.fromDate = new Date(this.subtractMonths(currentDate, 3).toISOString().split('T')[0]);
  }

  ngOnInit(): void {
    this.orderHistoryForm = new FormGroup({
      search: new FormControl(''),
      dateFrom: new FormControl(this.fromDate),
      dateTo: new FormControl(this.toDate),
    });
    this.minDate = this.fromDate;
    this.maxDate = this.toDate;
    this.filterData('');
  }

  get dateFrom() {
    return new Date(this.orderHistoryForm.get('dateFrom').value);
  }

  get dateTo() {
    const currentDate = new Date();
    const toDate = new Date(this.orderHistoryForm.get('dateTo').value);
    if (currentDate < toDate) {
      return currentDate;
    }
    return toDate;
  }

  updateDates() {
    this.minDate = this.dateFrom;
    this.maxDate = this.dateTo;
  }

  get dateFormat() {
    this.store.pipe(select(selectDateFormat)).subscribe((dateFormat) => {
      this.userdateFormat = dateFormat;
    });
    const dateType = this.userdateFormat;
    if (dateType === 'MM-dd-yyyy') {
      return 'mm-dd-yy'
    } else if (dateType === 'dd-MM-yyyy') {
      return 'dd-mm-yy'
    } else {
      return 'yy-mm-dd'
    }
  }

  subtractMonths(date: Date, months: number) {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() - months);
    return newDate;
  }

  filterSearch(event: AutoCompleteCompleteEvent) {
    let query = event.query + this.getDateQuery();
    this.globalService.loadingSubject.next(true);
    this.globalService.queryOrderSubject.next({ query: query, flag: true });
    this.globalService.suggestionsList$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (data: any) => {
          let companyPO = (data ? data.map(item => item.companyPo) : []).filter(str => str !== '');
          let sgreSO = (data ? data.map(item => item.sgreSo) : []).filter(str => str !== '');
          this.searchSuggestions = Array.from(new Set([...companyPO, ...sgreSO]));
          this.changeRef.markForCheck();
        }
      });
  }

  getDateQuery() {
    const fromDate = new Date(this.orderHistoryForm.value.dateFrom);
    fromDate.setMinutes(fromDate.getMinutes() - fromDate.getTimezoneOffset());
    const toDate = new Date(this.orderHistoryForm.value.dateTo);
    toDate.setMinutes(toDate.getMinutes() - toDate.getTimezoneOffset());
    const formattedDateFrom = fromDate.toISOString().split('T')[0];
    const formattedDateTo = toDate.toISOString().split('T')[0];
    let dateFrom = ":date-desc:date:%5B" + formattedDateFrom + "T00%3A00%3A00Z+TO+";
    let dateTo = formattedDateTo + "T23%3A59%3A59Z%5D";
    return formattedDateFrom && formattedDateTo ? dateFrom + dateTo : ''
  }

  filterData(data) {
    this.globalService.queryOrderSubject.next({ query: data + this.getDateQuery(), flag: false });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.globalService.clearMessagesOnDestroy();
  }

}
