import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageSlotModule } from '@spartacus/storefront';
import { ConfigModule, I18nModule } from '@spartacus/core';
import { PrimengModule } from '../primeng/primeng.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SGRESharedModule } from '../../SGRE-shared/SGRE-shared.module';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
  TranslateStore,
} from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { HttpBackend } from '@angular/common/http';
// import { NgOptimizedImage } from '@angular/common'

import { HeaderContainerComponent } from './Components/header-container/header-container.component';
import { SitelogoHeaderComponent } from './Components/sitelogo-header/sitelogo-header.component';
import { LanguageSelectorHeaderComponent } from './Components/language-selector-header/language-selector-header.component';
import { LegalEntitySelectorHeaderComponent } from './Components/legal-entity-selector-header/legal-entity-selector-header.component';
import { SignInBannerHeaderComponent } from './Components/sign-in-banner-header/sign-in-banner-header.component';
import { HeaderProductTabComponent } from './Components/header-product-tab/header-product-tab.component';
import { SearchBoxHeaderComponent } from './Components/search-box-header/search-box-header.component';
import { LegalEntitySelectorComponent } from './Components/legal-entity-selector/legal-entity-selector.component';
import { CartSelectorComponent } from './Components/cart-selector/cart-selector.component';

export function HttpLoaderFactory(_httpBackend: HttpBackend) {
  return new MultiTranslateHttpLoader(_httpBackend, ['/assets/i18n/core/', '/assets/i18n/vendors/']);
}


@NgModule({
  declarations: [
    HeaderContainerComponent,
    SitelogoHeaderComponent,
    LanguageSelectorHeaderComponent,
    LegalEntitySelectorHeaderComponent,
    SignInBannerHeaderComponent,
    HeaderProductTabComponent,
    SearchBoxHeaderComponent,
    LegalEntitySelectorComponent,
    CartSelectorComponent,
  ],
  imports: [
    CommonModule,
    // NgOptimizedImage,
    PageSlotModule,
    PrimengModule,
    FormsModule,
    ReactiveFormsModule,
    SGRESharedModule,
    ConfigModule.withConfig({
      cmsComponents: {
        HeaderContainerComponent: {
          component: HeaderContainerComponent,
        },
        SimpleBannerComponent: {
          component: SitelogoHeaderComponent,
        },
        SearchBoxComponent: {
          component: SearchBoxHeaderComponent,
        },
        MiniCartComponent: {
          component: CartSelectorComponent,
        },
        LegalEntityListComponent: {
          component: LegalEntitySelectorComponent,
        },
        AnonymousSignInBannerComponent: {
          component: SignInBannerHeaderComponent,
        },
        LanguageCurrencyComponent: {
          component: LanguageSelectorHeaderComponent,
        },
        CategoryNavigationComponent: {
          component: HeaderProductTabComponent,
        }
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    I18nModule
  ],
  providers: [
    TranslateService,
    { provide: TranslateStore }
  ]
})
export class HeaderModule { }
