import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';

@Component({
  selector: 'app-footer-social-media-facebook',
  templateUrl: './footer-social-media-facebook.component.html',
  styleUrl: './footer-social-media-facebook.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterSocialMediaFacebookComponent {
  public componentData: any;

  constructor(public component: CmsComponentData<any>) { }

  ngOnInit(): void {
    this.component.data$.subscribe((data) => {
      this.componentData = data;
    });
  }
}
