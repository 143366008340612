import * as i0 from '@angular/core';
import { NgModule } from '@angular/core';
import { CartBaseComponentsModule } from '@spartacus/cart/base/components';
import { CartBaseCoreModule } from '@spartacus/cart/base/core';
import { CartBaseOccModule } from '@spartacus/cart/base/occ';

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class CartBaseModule {
  static {
    this.ɵfac = function CartBaseModule_Factory(t) {
      return new (t || CartBaseModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CartBaseModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CartBaseCoreModule, CartBaseOccModule, CartBaseComponentsModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CartBaseModule, [{
    type: NgModule,
    args: [{
      imports: [CartBaseCoreModule, CartBaseOccModule, CartBaseComponentsModule]
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * Generated bundle index. Do not edit.
 */

export { CartBaseModule };
