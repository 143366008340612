import { Component, ChangeDetectionStrategy } from '@angular/core';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { OrderDetailsService } from '../../services/orderDetails.service';
//import * as i18nIsoCountries from 'i18n-iso-countries';

import { Observable } from 'rxjs';

@Component({
  selector: 'app-delivery-information',
  templateUrl: './delivery-information.component.html',
  styleUrl: './delivery-information.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryInformationComponent {

  deliveryInformation: Observable<any>;
  flag: boolean;

  constructor(
    private orderService: OrderDetailsService,
    private globalService: GlobalService,
  ) { }

  ngOnInit() {
    this.globalService.loadingSubject.next(true); 
    this.deliveryInformation = this.orderService.OrderListData$;
  }

  getAddress(addresses) {
    let obj = addresses?.filter(item => item.billingAddress === true);
    return obj[0];
  }
  get isError() {
    return this.orderService.orderErrorFlag;
  }
}
