<div class="row m-1" *ngIf="currentpage$ | async as page">
  <div class="col-lg-4 my-3">
    <ul class="nav nav-fill tab-container">
      <li [ngClass]="{'active':page===1,'inactive': page!==1, 'highlight': page===1}" class="mb-1">
        <i class="fa fa-download fa-3x bulk-order-icon-updated" aria-hidden="true"> </i>
        <a class="nav-link">
          <span class="tab-label" (click)="isSelectedBtn(1)" type="button">
            {{"bulkOrder.downloadFile"|cxTranslate}}
          </span>
        </a>
      </li>
      <li [ngClass]="{'active':page===2, 'inactive': page!==2, 'highlight': page===2}" class="mb-1">
        <i class="fa fa-upload fa-3x bulk-order-icon-updated" aria-hidden="true"> </i>
        <a class="nav-link">
          <span class="tab-label" (click)="isSelectedBtn(2)">
            {{"bulkOrder.uploadFile"|cxTranslate}}
          </span>
        </a>
      </li>
      <li [ngClass]="{'active':page===3, 'inactive': page!==3, 'highlight': page===3}" class="mb-1">
        <i class="fa fa-check-square fa-3x bulk-order-icon-updated" aria-hidden="true"> </i>
        <a class="nav-link default-cursor">
          <span class="tab-label">
            {{"bulkOrder.submit"|cxTranslate}}
          </span>
        </a>
      </li>
    </ul>
  </div>
</div>