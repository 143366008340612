import { ChangeDetectorRef, Component, OnInit, OnDestroy,ChangeDetectionStrategy } from '@angular/core';
import { MyProfileService } from '../../services/my-profile.service';
import { Observable, Subscription } from 'rxjs';
import { Column, ColumnType } from '../../../../SGRE-shared/models/tableConfiguration';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { Store,select } from '@ngrx/store';
import { selectUserRoles } from '../../../../SGRE-shared/services/storage.state';

@Component({
  selector: 'app-my-profile-table',
  templateUrl: './my-profile-table.component.html',
  styleUrl: './my-profile-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyProfileTableComponent implements OnInit, OnDestroy {

  isShowCheckBox: boolean = false;
  public customers: any[] = [];
  public selectedKey: any[] = [];
  public purchaseCheck: any
  public updatedCutomers: any[] = []
  public dataList: any[] = []
  public requestArray: any[] = []
  public updatedRequestArray: any[] = []
  public entityList: any[] = [];
  public columns: Column[];
  public columns2: Column[];
  public profileList: Observable<any>;
  cols: Column1[];
  cols2: Columns2[];
  schema: AlbumSchema[] = [];
  public role: string = '';
  public checked: boolean = false;
  selectedCity: any | undefined;
  receivedBoolean: boolean = false;
  comment: any = '';
  private commentSubscription: Subscription;
  public toasterSuccess: boolean = false;
  public toastMsg: string = ''
  public updatedList$: Observable<any[]>
  inviteUserData$: Observable<any>;
  public userLoggedIn: Observable<any>;
  public isInternal: boolean = false;
  userRoles: any;

  constructor(
    private myProfileService: MyProfileService,
    private changeRef: ChangeDetectorRef,
    private globalService: GlobalService,
    private cdRef: ChangeDetectorRef,
    private store: Store
  ) { this.globalService.loadingSubject.next(true); }

  ngOnInit() {
    this.myProfileService.fetchMyProfileList()
    this.myProfileService.myProfileData$.subscribe((data) => {
      this.isInternal = data?.isInternal
    });
    this.myProfileService.toggleRequest$.subscribe(value => {
      this.isShowCheckBox = value;
      if (!this.isShowCheckBox) {
        this.updatedCutomers = this.updatedCutomers.filter((obj) => { return !obj.newObj })
        this.myProfileService.updateArrayData([])
      }
      this.changeRef.markForCheck();
    });
    this.commentSubscription = this.myProfileService.currentCommentData.subscribe(data => {
      this.comment = data;
      if (data || (data === '' && this.requestArray.length)) {
        this.sendAccessRequest(data)
      }
    })
    this.getFromSessionStorage();
    this.getMyProfileData();
  }


  getMyProfileData() {
    this.myProfileService.profileData$.subscribe((data) => {
      this.updatedCutomers = data
      this.cdRef.markForCheck();
      if (this.role === 'admin' || this.role === 'Sales Rep' || this.role === 'SGRE Admin') {
        this.fetchAllLegalEntity();
      }
      if (this.updatedCutomers) {
        this.createTableConfiguration(this.updatedCutomers)
      }
    })
  }

  createTableConfiguration(testArrayData?: any) {
    let companyFilter = this.addArrayFilter(testArrayData, 'company')
    let legalEntityFilter = this.addArrayFilter(testArrayData, 'name')
    let vatIdFilter = this.addArrayFilter(testArrayData, 'vatId')
    let columnData = [
      {
        "name": "name", "label": "Legal Entity", "type": ColumnType.text,
        filter: true, filterOptions: (legalEntityFilter).map((item) => { return { name: item } })
      },
      {
        "name": "vatId", "label": "VAT id", "type": ColumnType.text,
        filter: true, filterOptions: (vatIdFilter).map((item) => { return { vatId: item } })
      },
      {
        "name": "Requestor", "label": "Requester", "type": ColumnType.tick
      },
      {
        "name": "Purchaser", "label": "Purchaser", "type": ColumnType.tick
      },
      {
        "name": "CustomerAdmin", "label": "Customer Admin", "type": ColumnType.tick
      }
    ];
    let columnDataUser = [
      {
        "name": "company", "label": "Company", "type": ColumnType.text,
        filter: true, filterOptions: (companyFilter).map((item) => { return { company: item } })

      },
      {
        "name": "name", "label": "Legal Entity", "type": ColumnType.text,
        filter: true, filterOptions: (legalEntityFilter).map((item) => { return { name: item } })
      },
      {
        "name": "vatId", "label": "VAT id", "type": ColumnType.text,
        filter: true, filterOptions: (vatIdFilter).map((item) => { return { vatId: item } })
      },

      {
        "name": "SalesRep", "label": "Sales Rep", "type": ColumnType.tick
      }
    ];
    this.columns = columnData.map(item => new Column(item));
    this.columns2 = columnDataUser.map(item => new Column(item));

  }

  addArrayFilter(testArrayData, filterKey) {
    const filteredArray = testArrayData?.filter((item) => {
      return item[filterKey] !== undefined
    }).map((c) => c[filterKey]);
    return Array.from(new Set(filteredArray));
  }

  sendAccessRequest(data) {
    this.myProfileService.updateRequestAccess(this.requestArray, data).subscribe(
      {
        next: (value: any) => {
          if (value.status === 200) {
            this.toastMsg = 'Your Access Request(s) have been sent!'
            this.toasterSuccess = true;
            this.changeRef.markForCheck();
            this.myProfileService.fetchMyProfileList()
            this.myProfileService.setSelected(false)
            this.myProfileService.updateArrayData([])
            this.requestArray = []
          }
          else {
            this.toastMsg = 'Submission of one or more of your Access Requests failed!';
          }
        },
        error: err => {
          this.toastMsg = 'Submission of one or more of your Access Requests failed!';
        }
      });
  }

  getFromSessionStorage() {
    this.store.pipe(select(selectUserRoles)).subscribe((userRoles) => {
      this.userRoles = userRoles;
    });
    let userRole: any = '';
    userRole = this.userRoles;
    if (userRole?.includes("Requester")) {
      this.role = 'Requester'
    }
    else if (userRole?.includes("Purchaser")) {
      this.role = 'Purchaser'
    }
    else if (userRole?.includes("Customer Admin")) {
      this.role = 'Customer Admin'
    }
    else if (userRole?.includes("SGRE Admin")) {
      this.role = 'SGRE Admin'
    }
    else if (userRole?.includes("Sales Rep")) {
      this.role = 'Sales Rep'
    }
  }

  checkStatus(item: any, isUserType, pendingType, isType) {
    if ((!pendingType) && (!(isType))) {
      return item[isUserType] = false;
    } else if ((!pendingType) && (isType)) {
      return item[isUserType] = { status: 'icon' }
    } else if (pendingType) {
      return item[isUserType] = { status: 'text' }
    }
    else return ''
  }

  public updateCheckBtnClick(data) {
    if (this.role === 'user' || this.role === 'Customer Admin' || this.role === 'Purchaser' || this.role === 'Requester') {
      if (data?.colName === 'Purchaser') {
        if (data.formArray[data.index][data.colName]) {
          let exists = this.requestArray.some((obj) => {
            return obj.legalEntityId === this.updatedCutomers[data.index].uid
          })
          if (!exists) {
            let requestObj = {
              "legalEntityId": this.updatedCutomers[data.index].uid,
              "requester": false,
              "customerAdmin": false,
              "approver": true,
              "salesRep": false
            }
            this.requestArray.push(requestObj)

          } else {
            this.requestArray.forEach((obj) => {
              if (obj.legalEntityId === this.updatedCutomers[data.index].uid) {
                obj.approver = true
              }
            })
          }
        }
        else {
          this.requestArray.forEach((obj) => {
            if (obj.legalEntityId === this.updatedCutomers[data.index].uid) {
              obj.approver = false
            }
          })
        }
      }
      else if (data?.colName === 'CustomerAdmin') {
        if (data.formArray[data.index][data.colName]) {
          let exists = this.requestArray.some((obj) => {
            return obj.legalEntityId === this.updatedCutomers[data.index].uid
          })
          if (!exists) {
            let requestObj = {
              "legalEntityId": this.updatedCutomers[data.index].uid,
              "requester": false,
              "customerAdmin": true,
              "approver": false,
              "salesRep": false
            }
            this.requestArray.push(requestObj)

          } else {
            this.requestArray.forEach((obj) => {
              if (obj.legalEntityId === this.updatedCutomers[data.index].uid) {
                obj.customerAdmin = true
              }
            })
          }
        }
        else {
          this.requestArray.forEach((obj) => {
            if (obj.legalEntityId === this.updatedCutomers[data.index].uid) {
              obj.customerAdmin = false
            }
          })

        }
      }
      else if (data?.colName === 'Requestor') {
        if (data.formArray[data.index][data.colName]) {
          let exists = this.requestArray.some((obj) => {
            return obj.legalEntityId === this.updatedCutomers[data.index].uid
          })
          if (!exists) {
            let requestObj = {
              "legalEntityId": this.updatedCutomers[data.index].uid,
              "requester": true,
              "customerAdmin": false,
              "approver": false,
              "salesRep": false
            }
            this.requestArray.push(requestObj)

          } else {
            this.requestArray.forEach((obj) => {
              if (obj.legalEntityId === this.updatedCutomers[data.index].uid) {
                obj.requester = true
              }
            })
          }
        }
        else {
          this.requestArray.forEach((obj) => {
            if (obj.legalEntityId === this.updatedCutomers[data.index].uid) {
              obj.requester = false
            }
          })

        }
      }
    }
    // Sales Rep
    else {
      let exists = this.requestArray.some((obj) => {
        return obj.legalEntityId === this.updatedCutomers[data.index].uid
      })
      if (!exists) {
        let requestObj = {
          "legalEntityId": this.updatedCutomers[data.index].uid,
          "requester": false,
          "customerAdmin": false,
          "approver": false,
          "salesRep": true
        }
        this.requestArray.push(requestObj)
      }
      else {
        this.requestArray.forEach((obj) => {
          if (obj.legalEntityId === this.updatedCutomers[data.index].uid) {
            obj.salesRep = !obj.salesRep
          }
        })
      }
    }

    if (this.requestArray.length) {
      this.requestArray = this.requestArray.filter(obj => Object.values(obj).some(value => value === true));
      this.myProfileService.updateArrayData(this.requestArray);
    }
  }

  fetchAllLegalEntity() {
    const requestObject: any = {};
    requestObject.currentPage = 0;
    requestObject.fields = 'FULL';
    requestObject.pageSize = 200;
    requestObject.userID = this.globalService.getUserId();

    this.myProfileService.getAllLegalEntity(requestObject).subscribe({
      next: (value: any) => {
        if (value) {
          this.entityList = value?.legalEntities;
          this.removeDuplicateEntity()
        }
      },
      // error: err => console.error('Observable emitted an error: ' + err)

    });
  }

  addNewEntryToTable() {
    if (this.selectedCity) {
      let updatedData = ({ ...this.selectedCity, SalesRep: false, "newObj": true })
      const exists = this.updatedCutomers.some(item => item.uid === updatedData.uid);
      if (!exists)
        this.updatedCutomers.push(updatedData);
      this.updatedCutomers = this.updatedCutomers.splice(0)
      this.entityList = this.entityList.filter((item) => {
        return item.uid !== this.selectedCity?.uid
      })
      this.selectedCity = '';
    }
  }

  removeDuplicateEntity() {
    this.entityList = this.entityList.filter((item2) => {
      return !this.updatedCutomers.some((item1) => {
        return item1.uid === item2.uid
      })
    })
  }

  ngOnDestroy() {
    this.globalService.loadingSubject.next(false);
    this.commentSubscription.unsubscribe();
    this.globalService.clearMessagesOnDestroy();
  }
}

export interface Element {
  username: string;
  name: string;
  Company: number;
  legal_entities: string;
}
interface AlbumSchema {
  field: string;
  header: string;
}
interface Column1 {
  field: string;
  header: string;
}
interface Columns2 {
  field: string;
  header: string;
}