import { ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { CmsComponentData } from '@spartacus/storefront';

@Component({
  selector: 'app-homepage-image-section9',
  templateUrl: './homepage-image-section9.component.html',
  styleUrl: './homepage-image-section9.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomepageImageSection9Component {
  public componentData: any;
  imageUrl: any;
  constructor(public component: CmsComponentData<any>) { }
  ngOnInit(){
  this.component.data$.subscribe((data) => {
    this.componentData = data;
    this.imageUrl = environment.siteUrls.getBackofficeUrl + data?.media?.url?.split('medias/')[1];
  });
}
}
