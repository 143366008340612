import { Component, Inject, HostListener, inject, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { CmsParagraphComponent, WindowRef } from '@spartacus/core';
import { CmsComponentData, ParagraphComponent } from '@spartacus/storefront';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { User } from '@spartacus/core';
import { UserProfileService } from '../../../SGRE-custom/user-profile/services/user-profile.service';
import { selectUserRoles } from '../../../SGRE-shared/services/storage.state';
import { Store,select } from '@ngrx/store';
@Component({
  selector: 'app-shared-paragraph',
  templateUrl: './shared-paragraph.component.html',
  styleUrl: './shared-paragraph.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedParagraphComponent extends ParagraphComponent {
  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    let anchor: HTMLAnchorElement | null = null;
    if (target.tagName === 'A') {
      anchor = target as HTMLAnchorElement;
    } else if (target.closest('A')) {
      anchor = target.closest('A') as HTMLAnchorElement;
    }
    const url = anchor?.getAttribute('data-parent');
    if (url) {
      this.windowRef.nativeWindow.open(`${this.windowRef.nativeWindow.location.origin}${url}`, '_blank');
    }
  }
  public data$: Observable<CmsParagraphComponent>;
  public role: any = '';
  public userRole: any = ''
  public componentData: any;
  public userRoles: any[];
  loginDisplay = new BehaviorSubject(false);
  user$: Observable<User | undefined>;
  private readonly userProfileService = inject(UserProfileService);
  unsubscribe$ = new Subject<void>();

  constructor(
    private _component: CmsComponentData<CmsParagraphComponent>,
    private _router: Router,
    private windowRef: WindowRef,
    private store: Store
  ) {
    super(_component, _router);
  }

  ngOnInit(): void {
    this.data$ = this._component.data$;
    this.checkAccess();

    this.userProfileService?.userProfileSubjectData
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        let paragraph: any = this.windowRef.document.getElementsByTagName('app-privacy-policy-paragraph');
        if (paragraph) {
          if (data.email !== '' && data.isInternal) {
            paragraph[1].style.display = 'none';
          } else if (data.email !== '' && !data.isInternal) {
            paragraph[0].style.display = 'none';
          }
        }
      });
  }

  checkAccess() {
    this.store.pipe(select(selectUserRoles)).subscribe((userRoles) => {
      this.userRoles = userRoles;
    });
    this.userRole = this.userRoles;
    if (this.userRole?.includes("Requester")) {
      this.role = 'Requester'
    }
    else if (this.userRole?.includes("Purchaser")) {
      this.role = 'Purchaser'
    }
    else if (this.userRole?.includes("Customer Admin")) {
      this.role = 'Customer Admin'
    }
    else if (this.userRole?.includes("SGRE Admin")) {
      this.role = 'SGRE Admin'
    }
    else if (this.userRole?.includes("Sales Rep")) {
      this.role = 'Sales Rep'
    }
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    document.removeEventListener('click', this.onClick);
  }
}