<div *ngIf="errorMessage" class="WarningBanner alert alert-dismissible alert-warning fade show ml-3" role="alert">
  <button (click)="errorMessage = ''" type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
  <p>
    <strong>{{errorMessage}}</strong>
  </p>
</div>
<div class="flex justify-content-center shipping-details-container">
  <!-- WAREHOUSE ACCORDION -->
  <p-accordion [activeIndex]="0" styleClass="cstm-accordion">
    <ng-container *ngIf="nonQuotationEntries?.length > 0">
      <p-accordionTab>
        <div *ngIf="showSimError" class="alert alert-danger alert-warning" role="alert">
          {{simErrorMessage}}
        </div>
        <ng-template pTemplate="header">
          <label for="" class="headerfirst">
            {{"checkOut.shippingFrom"|cxTranslate}}
          </label>
          &nbsp;
          <label for="" class="headerLast">
            {{"checkOut.Warehouse"|cxTranslate}}
          </label>
        </ng-template>
        <div class="d-flex dropdowns checkout-dropdowns-container">
          <div class="col-12 col-lg-3 pl-0 dropdown-container">
            <label for="Incoterms" class="checkout-label">
              <span>
                {{"checkOut.incoterms"|cxTranslate}}
              </span>
            </label>
            <p-dropdown styleClass="cstm-dropdown" placeholder="Select Incoterm" [options]="incoterms"
              optionLabel="label" dataKey="value" [(ngModel)]="selectedIncoterm" (onChange)="openOtherIncotermModal()"
              [disabled]="showSimError" />
          </div>
          <div class="col-12 col-lg-3 offset-lg-1 shipping-address-container">
            <label for="ShippingAddress" class="checkout-label">
              <span>
                {{"checkOut.shippingAddress"|cxTranslate}}
              </span>
            </label>
            <p-dropdown #shippingAddressDropdown styleClass="cstm-dropdown"
              [ngClass]="{'highlight-border': (!selectedAddressWarehouse && isSubmitClicked)}"
              placeholder="{{'checkOut.chooseShippingAddress'|cxTranslate}}" [options]="addresses"
              optionLabel="companyName" dataKey="id" [(ngModel)]="selectedAddressWarehouse" [disabled]="showSimError"
              (onChange)="onAddressChangeWarehouse($event.value)">
            </p-dropdown>
            <div *ngIf="selectedAddressWarehouse && selectedAddressWarehouse.companyName !== 'Other Address'"
              class="address-details mt-2">
              <div class="mb-1">{{selectedAddressWarehouse.line1}}</div>
              <div class="mb-1">{{selectedAddressWarehouse.line2}}</div>
              <div class="mb-1">{{selectedAddressWarehouse.postalCode}}, {{selectedAddressWarehouse.town}}</div>
              <div class="mb-1">{{selectedAddressWarehouse.region?.name}}</div>
              <div class="mb-1">{{selectedAddressWarehouse.country?.name}}</div>
            </div>
          </div>
          <div class="col-12 col-lg-3 offset-lg-1 shipping-method-container">
            <label for="ShippingMethod" class="shipping-method-label checkout-label">
              <span>{{"checkOut.shippingMethod"|cxTranslate}}</span>
            </label>
            <!-- Show loading spinner or message when fetching shipping methods -->
            <p-dropdown *ngIf="!isShippingMethodLoading" styleClass="cstm-dropdown"
              [ngClass]="{'highlight-border': (!(showSimError || selectedDeliveryMode) && isSubmitClicked)}"
              placeholder="{{'checkOut.chooseShippingMethod'|cxTranslate}}" [options]="deliveryModes" optionLabel="name"
              dataKey="name" [(ngModel)]="selectedDeliveryMode" (onChange)="onShippingMethodChange('warehouse')"
              [disabled]="(!selectedAddressWarehouse && disableShippingMethodWarehouse) || showSimError || selectedAddressWarehouse?.companyName === 'Other Address'">
            </p-dropdown>

            <!-- Show loading text or spinner when shipping methods are loading -->
            <div *ngIf="isShippingMethodLoading" class="loading-placeholder">
              Loading shipping methods...
            </div>

            <div class="shipping-note">
              <span class="text-small mt-1">
                {{"checkOut.noteShippingCostsVary"|cxTranslate}}
              </span>
            </div>
          </div>
        </div>
        <ng-container *ngIf="nonQuotationEntries?.length > 0">
          <app-table-template [disable]="userRole === 'Purchaser' && isReview" [columns]="columnsNonQuotation"
            [tableData]="nonQuotationEntries" [message]="message" [rowsPerPage]="10"
            (navigateClickEvent)="navigateToPDP($event)" (qtyUpdateClickEvent)="updateQty($event, 'warehouse')"
            (deleteBtnClickEvent)="deleteCart($event,'warehouse')">
          </app-table-template>
        </ng-container>
        <div class="col-12 col-lg-4 offset-lg-8 mt-3 summary-container">
          <label for="Summary">
            <div class="font-weight-bold">{{"checkOut.summaryOf"|cxTranslate}}</div>
          </label>
          <div id="Summary" class="w-100">
            <p>
              <span>{{"checkOut.subtotal"|cxTranslate}}</span>
              <span class="summary-number">
                {{subtotal|currencyPrice}} {{currency|currencyFormat}}
              </span>
              <br />
              <span>{{"checkOut.transportFee"|cxTranslate}}</span>
              <span class="summary-number">
                {{transportFee|currencyPrice}}
                {{currency|currencyFormat}}
              </span>
              <br />
              <span>{{"checkOut.shippingFee"|cxTranslate}}</span>
              <span class="summary-number">
                {{shippingFee|currencyPrice}}
                {{currency|currencyFormat}}
              </span>
              <br />
              <span>{{"checkOut.taxes"|cxTranslate}}</span>
              <span class="summary-number">
                {{taxes|currencyPrice}} {{currency|currencyFormat}}
              </span>
              <br />
              <span>{{"checkOut.grandTotal"|cxTranslate}}</span>
              <span class="summary-number">
                {{grandTotal|currencyPrice}} {{currency|currencyFormat}}
              </span>
              <br />
            </p>
          </div>
        </div>
        <button type="button" class="btn cstm-btn-light mt-2" (click)="removeAllPartsFromWarehouse()">
          {{"checkOut.removePartsFrom"|cxTranslate}}Warehouse
        </button>
      </p-accordionTab>
    </ng-container>
  </p-accordion>
  <!-- QUOTATION ACCORDION -->
  <p-accordion [activeIndex]="0" styleClass="cstm-accordion">
    <ng-container *ngIf="quotationEntries?.length > 0">
      <p-accordionTab>
        <ng-template pTemplate="header">
          <label for="" class="headerfirst">{{"checkOut.shippingFrom"|cxTranslate}}</label>
          &nbsp;
          <label for="" class="headerLast">{{"checkOut.quotation"|cxTranslate}}</label>
        </ng-template>
        <div class="d-flex dropdowns checkout-dropdowns-container">
          <div class="col-12 col-lg-3 pl-0 dropdown-container">
            <label for="Incoterms" class="checkout-label">
              <span>{{"checkOut.incoterms"|cxTranslate}}</span>
            </label>
            <p-dropdown styleClass="cstm-dropdown" placeholder="Select Incoterm" [options]="incoterms"
              optionLabel="label" [(ngModel)]="selectedIncotermQuotation" (onChange)="openSecondaryOtherIncotermModal()"
              [disabled]="disableIncoterm" />
          </div>
          <div class="col-12 col-lg-3 offset-lg-1 shipping-address-container">
            <label for="Shipping Address" class="checkout-label">
              <span>{{"checkOut.shippingAddress"|cxTranslate}}</span>
            </label>
            <p-dropdown styleClass="cstm-dropdown"
              [ngClass]="{'highlight-border': (!selectedAddressQuotation && isSubmitClicked)}"
              placeholder=" {{'checkOut.chooseShippingAddress'|cxTranslate}}" [options]="secondaryAddresses"
              optionLabel="companyName" dataKey="id" [(ngModel)]="selectedAddressQuotation"
              (onChange)="handleAddressChangeQuotation($event.value)"
              [disabled]="(disableSelectedAddressQuotation || showSimError)" />
            <div *ngIf="selectedAddressQuotation&&selectedAddressQuotation.companyName!=='Other Address'"
              class="address-details mt-2">
              <div class="mb-1">{{selectedAddressQuotation.line1}}</div>
              <div class="mb-1">{{selectedAddressQuotation.line2}}</div>
              <div class="mb-1">{{selectedAddressQuotation.postalCode}},{{selectedAddressQuotation.town}}</div>
              <div class="mb-1">{{selectedAddressQuotation.region?.name}}</div>
              <div class="mb-1">{{selectedAddressQuotation.country?.name}}</div>
            </div>
          </div>
          <div class="col-12 col-lg-3 offset-lg-1 shipping-method-container">
            <label for="ShippingMethod" class="shipping-method-label checkout-label">
              <span>{{"checkOut.shippingMethod"|cxTranslate}}</span>
            </label>
            <p-dropdown *ngIf="!isShippingMethodLoading" styleClass="cstm-dropdown"
              [ngClass]="{'highlight-border': (!(showSimError || selectedDeliveryModeQuotation) && isSubmitClicked)}"
              placeholder="{{'checkOut.chooseShippingMethod'|cxTranslate}}" [options]="deliveryModesQuotation"
              optionLabel="name" dataKey="name" [(ngModel)]="selectedDeliveryModeQuotation"
              (onChange)="onShippingMethodChange('quotation')"
              [disabled]="disableSelectedAddressQuotation || (!selectedAddressQuotation && disableShippingMethodQuotation) || showSimError || selectedAddressQuotation?.companyName === 'Other Address'">
            </p-dropdown>

            <!-- Show loading text or spinner when shipping methods are loading -->
            <div *ngIf="isShippingMethodLoading" class="loading-placeholder">
              Loading shipping methods...
            </div>
            <div class="shipping-note">
              <span class="text-small mt-1">
                {{"checkOut.noteShippingCostsVary"|cxTranslate}}
              </span>
            </div>
          </div>
        </div>
        <ng-container *ngIf="quotationEntries?.length > 0">
          <app-table-template [disable]="isReview" [columns]="columns" [tableData]="quotationEntries"
            [message]="message" (navigateClickEvent)="navigateToPDP($event)"
            (qtyUpdateClickEvent)="updateQty($event, 'quotation')"
            (deleteBtnClickEvent)="deleteCart($event,'quotation')">
          </app-table-template>
        </ng-container>
        <button type="button" class="btn cstm-btn-light mt-4" (click)="removeAllPartsFromQuotation()">
          {{"checkOut.removePartsFrom"|cxTranslate}}Quotations
        </button>
      </p-accordionTab>
    </ng-container>
  </p-accordion>
</div>

<!-- Modals -->
<div #incotermModal class="modal fade" tabindex="-1" id="incotermModal" aria-labelledby="incotermModal"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header d-flex justify-content-between align-items-center">
        <h4 class="m-0">
          {{"checkOut.changeIncoterm"|cxTranslate}}
        </h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancelQuotationEntries()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <span>
          {{"checkOut.youAboutToChangeTheIncoterm"|cxTranslate}}
          <br />
          {{
          "checkOut.turnTheEntireOrderIntoARequestForQuotation"|cxTranslate
          }}
          <br />
          {{"checkOut.areSure"|cxTranslate}}
        </span>
      </div>
      <div class="modal-footer d-flex justify-content-start">
        <button type="button" (click)="cancelQuotationEntries()" class="btn cstm-btn-light mb-2" data-dismiss="modal">
          {{"checkOut.cancel"|cxTranslate}}
        </button>
        <button (click)="moveToQuotationEntries()" type="button" class="btn cstm-btn-primary mb-2 ml-2"
          data-dismiss="modal">
          {{"checkOut.confirm"|cxTranslate}}
        </button>
      </div>
    </div>
  </div>
</div>
<div #cartModal class="modal fade" tabindex="-1" id="cartModal" aria-labelledby="cartModal" aria-hidden="true"
  (shown.bs.modal)="resetForm(addressForm)" (hidden.bs.modal)="resetForm(addressForm)">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header d-flex justify-content-between align-items-center">
        <h4 class="m-0">{{"checkOut.otherAddress"|cxTranslate}}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #addressForm="ngForm" (ngSubmit)="onSubmit(addressForm)">
          <span class="d-inline-block mb-3">
            {{"checkOut.fillThefollowingToUseACustomAddress"|cxTranslate}}
            <br />
            {{"checkOut.noteChoosingACustomAddress"|cxTranslate}}
          </span>
          <fieldset class="form-group">
            <label for="company-input">
              {{"checkOut.companyName"|cxTranslate}}
              <span class="validation-error-text">*</span>
            </label>
            <input #companyName="ngModel" [(ngModel)]="formData.companyName" id="company-input" type="text"
              placeholder="{{'checkOut.EnterNameOfCompany'|cxTranslate}}" required class="form-control"
              name="companyName" />
            <div *ngIf="companyName.invalid && companyName.touched" class="text-danger">
              {{"checkOut.companyNameIsRequired"|cxTranslate}}
            </div>
          </fieldset>
          <fieldset class="form-group">
            <label for="street-input">
              {{"checkOut.street"|cxTranslate}}
              <span class="validation-error-text">*</span>
            </label>
            <input #street="ngModel" [(ngModel)]="formData.street" id="street-input" type="text"
              placeholder="{{'checkOut.enterStreetName'|cxTranslate}}" required class="form-control" name="street" />
            <div *ngIf="street.invalid && street.touched" class="text-danger">
              {{"checkOut.streetRequired"|cxTranslate}}
            </div>
          </fieldset>
          <fieldset class="form-group">
            <label for="city-input">
              {{"checkOut.city"|cxTranslate}}
              <span class="validation-error-text">*</span>
            </label>
            <input #city="ngModel" [(ngModel)]="formData.city" id="city-input" type="text"
              placeholder="{{'checkOut.enterNameOfCity'|cxTranslate}}" required class="form-control" name="city" />
            <div *ngIf="city.invalid && city.touched" class="text-danger">
              {{"checkOut.streetRequired"|cxTranslate}}
            </div>
          </fieldset>
          <fieldset class="form-group">
            <label for="postal-code-input">
              {{"checkOut.postalCode"|cxTranslate}}
              <span class="validation-error-text">*</span>
            </label>
            <input #postalCode="ngModel" [(ngModel)]="formData.postalCode" id="postal-code-input" type="text"
              placeholder="{{'checkOut.enterPostalCode'|cxTranslate}}" required class="form-control"
              name="postalCode" />
            <div *ngIf="postalCode.invalid && postalCode.touched" class="text-danger">
              {{"checkOut.postalCodeRequired"|cxTranslate}}
            </div>
          </fieldset>
          <fieldset class="form-group">
            <label for="country-input">
              {{"checkOut.country"|cxTranslate}}
              <span class="validation-error-text">*</span>
            </label>
            <p-dropdown #country="ngModel" styleClass="cstm-dropdown" placeholder="Select Country" [options]="countries" [filter]="true"
              id="country-input" name="country" optionLabel="name" dataKey="isocode" [(ngModel)]="formData.country" (onChange)="onCountryChange($event)" />
            <div *ngIf="country.invalid && country.touched" class="text-danger">
              {{"checkOut.countryIsRequired"|cxTranslate}}
            </div>
          </fieldset>
          <fieldset class="form-group">
            <label for="region-input">
              {{"checkOut.region"|cxTranslate}}
              <span class="validation-error-text">*</span>
            </label>
            <p-dropdown #region="ngModel" styleClass="cstm-dropdown" placeholder="Select Region" [options]="regions" [filter]="true"
              id="region-input" name="region" optionLabel="name" dataKey="isocode" [(ngModel)]="formData.region" />
            <div *ngIf="region.invalid && region.touched" class="text-danger">
              {{"checkOut.regionRequired"|cxTranslate}}
            </div>
          </fieldset>
          <fieldset class="form-group">
            <label for="contact-person-input">
              {{"checkOut.contactPerson"|cxTranslate}}
              <span class="validation-error-text">*</span>
            </label>
            <input #contactPerson="ngModel" [(ngModel)]="formData.contactPerson" id="contact-person-input" type="text"
              placeholder="{{'checkOut.enterNameOfContact'|cxTranslate}}" required class="form-control"
              name="contactPerson" />
            <div *ngIf="contactPerson.invalid && contactPerson.touched" class="text-danger">
              {{"checkOut.contactPresonRequired"|cxTranslate}}
            </div>
          </fieldset>
          <fieldset class="form-group">
            <label for="phone-number-input">
              {{"checkOut.phoneNumber"|cxTranslate}}
              <span class="validation-error-text">*</span>
            </label>
            <input #phoneNumber="ngModel" [(ngModel)]="formData.phoneNumber" id="phone-number-input" type="text"
              placeholder="{{'checkOut.enterPhoneNumber'|cxTranslate}}" required class="form-control"
              name="phoneNumber" />
            <div *ngIf="phoneNumber.invalid && phoneNumber.touched" class="text-danger">
              {{"checkOut.phoneNumberRequired"|cxTranslate}}
            </div>
          </fieldset>
          <div class="custom-control custom-checkbox">
            <input id="save-address-checkbox" type="checkbox" name="checkbox-1" class="custom-control-input"
              value="true" [checked]="isChecked" />
            <label for="save-address-checkbox" class="custom-control-label checkout-checkbox-label">
              {{"checkOut.wantThisAddress"|cxTranslate}}
            </label>
          </div>
          <div class="modal-footer d-flex justify-content-start px-0 pb-0">
            <button type="button" class="btn cstm-btn-light m-0 mr-3" data-dismiss="modal">
              {{"checkOut.cancel"|cxTranslate}}
            </button>
            <button [disabled]="addressForm.invalid" type="submit" class="btn cstm-btn-primary m-0">
              {{"checkOut.confirm"|cxTranslate}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div #simulationModal class="modal fade" tabindex="-1" id="simulationModal" aria-labelledby="simulationModal"
  aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header d-flex justify-content-between align-items-center">
        <h4 class="m-0">
          {{"checkOut.changeSimulation"|cxTranslate}}
        </h4>
      </div>
      <div class="modal-body">
        <span>
          {{"checkOut.gobackSimulation"|cxTranslate}}
        </span>
      </div>
      <div class="modal-footer d-flex justify-content-start">
        <button (click)="navigateToProducts()" type="button" class="btn cstm-btn-primary mb-2 ml-2"
          data-dismiss="modal">
          {{"checkOut.confirm"|cxTranslate}}
        </button>
      </div>
    </div>
  </div>
</div>
<div #minimumOrderCartModal class="modal fade" tabindex="-1" id="minimumOrderCartModal"
  aria-labelledby="minimumOrderCartModal" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header d-flex justify-content-between align-items-center">
        <h4 class="m-0">
          {{"cartPage.orderAmountToLow" |cxTranslate}}
        </h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          {{"cartPage.yourCurrentTotal" |cxTranslate}} {{currentTotal | number:'1.0-0'}} but order less than
          {{minimumOrderAmount | number:'1.0-0'}} can't be placed.{{"cartPage.PleaseAddMore" |cxTranslate}}
        </p>
      </div>
      <div class="modal-footer d-flex justify-content-start">
        <button type="button" class="btn cstm-btn-light mb-2" data-dismiss="modal">
          {{"cartPage.close" |cxTranslate}}
        </button>
        <button (click)="navigateToProducts()" type="button" class="btn cstm-btn-primary mb-2 ml-2">
          {{"cartPage.continueShopping" |cxTranslate}}
        </button>
      </div>
    </div>
  </div>
</div>